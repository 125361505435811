import { useModal, useOne } from "@refinedev/core"
import { postItemRequest } from "api/item-requests"
import { Button } from "components/buttons"
import { BaseCard, OrderItemCard } from "components/cards"
import { AddEditItemModal } from "components/custom-modals"
import Pill from "components/Pill"
import { CustomerDetailIcon, InfoCircleIcon, InternalNoteIcon, LeadSourceIcon, PlusScheduleIcon, ServiceListIcon, TechniciansIcon, TipIcon } from "components/Svg"
import { DisplayDbLog, LabeledInfo } from "components/texts"
import { TextTooltip } from "components/tooltips"
import { baseApi } from "providers/customDataProvider"
import { useEffect, useLayoutEffect, useReducer, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { orderDetailReducer, orderDetailInitialState, OrderDetailKind } from "reducers/orderDetailReducer"
import { SERVICE_ORDER_CONFIG } from "resources-config/service-order/service-order-config"
import { toast } from "sonner"
import { getOrderStatus } from "utils/data-formatter"
import { formatFullDate } from "utils/date"
import { getAmountDue, getClosestDueDate, getFirstInvoice } from "utils/order"
import { scrollToTargetAdjusted } from "utils/scheduler"
import RecordPaymentModal from "../../custom-modals/RecordPaymentModal"
import InvoiceCard from "components/cards/InvoiceCard"
import { useForm } from "react-hook-form"
import { patchServiceOrder } from "api/service-orders"
import ServiceReportModal from "components/custom-modals/ServiceReportModal"
import { Modal } from "components/popups"
import { capitalize, upperCase } from "lodash"
import OrderDetailCardEditable from "components/cards/OrderDetailCardEditable"
import Switch from "components/forms/Switch"
import { FaWhatsapp } from "react-icons/fa"

export default function ShowServiceOrder() {
    const params = useParams()
    const { control, handleSubmit, watch } = useForm({ defaultValues: { showNotes: false } })
    const [searchParams, setSearchParams] = useSearchParams()
    const { data, refetch: refetchOrder } = useOne({
        resource: SERVICE_ORDER_CONFIG.name,
        id: params.id
    })
    const [viewedInvoice, setViewedInvoice] = useState<MiscObj>()
    const [state, dispatch] = useReducer(orderDetailReducer, orderDetailInitialState)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [activeReportUrl, setActiveReportUrl] = useState<string>()
    const addEditItemModal = useModal()
    const serviceReportPreviewModal = useModal()
    const recordPaymentModal = useModal()
    const serviceReportListModal = useModal()
    const isDirty = state.newItemQueue.length != 0 || state.isEditMode
    const showAllNotes = watch('showNotes')

    const handleLoadLeadSource = async (inputValue: string) => {
        const res = await baseApi.get(`/admin/lead-sources?name=${inputValue}&isActive=true`)
        return res.data.map((lead: any) => ({ value: +lead.id, label: lead.name }))
    }

    const cancel = async () => {
        if (state.order) {
            try {
                await baseApi.post(`/admin/service-orders/cancel/${state.order.id}`)
                refetchOrder()
                toast.success('Order Cancelled')
            }
            catch (err: any) {
                toast.error(err.message)
            }
        }
    }

    useLayoutEffect(() => {
        const target = searchParams.get('scroll')
        if (scrollToTargetAdjusted(target || '', 100)) setSearchParams({})
    }, [state.order])

    const saveOrder = handleSubmit(async (data: any) => {
        if (!state.order || !isDirty) return
        setIsSaving(true)
        try {
            if (state.newItemQueue.length > 0) {
                await postItemRequest({
                    items: state.newItemQueue,
                    order: parseInt(state.order.id)
                })
                dispatch({ type: OrderDetailKind.CLEAR_NEW_ITEM })
            }
            await patchServiceOrder(state.order.id, {
                ...data,
                leadSource: data?.leadSource && +data.leadSource,
                items: data.items && data.items.map((item: any) => ({ ...item, id: +item.id }))
            })
            await refetchOrder()
            dispatch({ type: OrderDetailKind.SET_IS_EDIT_MODE, payload: false })
            toast.success('Items successfully added.')
        }
        catch (err: any) {
            console.log(err)
            toast.error('Failed adding item.' + err.message)
        }
        finally {
            setIsSaving(false)
        }
    })

    const handleAddEditItem = async (data: any, serviceDetail: MiscObj, id?: string) => {
        if (!state.order) return
        try {
            await postItemRequest({
                items: [{ ...serviceDetail, ...data, }],
                order: +state.order.id
            })
            toast.success('Successfully added item')
            refetchOrder()
        } catch (err: any) {
            toast.error(err.message)
            console.log(err)
        }

        addEditItemModal.close()
    }

    const openAddEdit = (id?: string) => {
        addEditItemModal.show()
        if (id) {
            const selectedNewItem = state.newItemQueue.find(item => item.id == id) || {}
            dispatch({
                type: OrderDetailKind.SET_DEFAULT_EDIT_VALUE,
                payload: {
                    id: selectedNewItem.id,
                    service: selectedNewItem.service,
                    category: selectedNewItem.category,
                    frequency: selectedNewItem.frequency,
                    quantity: selectedNewItem.quantity,
                    pricePerUnit: selectedNewItem.pricePerUnit,
                    price: selectedNewItem.price,
                    notes: selectedNewItem.notes
                }
            })
        }
        else {
            dispatch({ type: OrderDetailKind.SET_DEFAULT_EDIT_VALUE, payload: null })
        }
    }

    useEffect(() => {
        const order = data?.data
        const firstInvoice = getFirstInvoice(order)
        dispatch({ type: OrderDetailKind.SET_ORDER, payload: order })
        dispatch({ type: OrderDetailKind.SET_TOTAL_PAYMENT, payload: order?.invoices.map((inv: any) => inv.amount).reduce((acc: number, current: number) => acc + current, 0) })
        dispatch({ type: OrderDetailKind.SET_FIRST_INVOICE, payload: firstInvoice })
        dispatch({ type: OrderDetailKind.SET_AMOUNT_DUE, payload: order && getAmountDue(order.invoices) })
        dispatch({ type: OrderDetailKind.SET_DUE_DATE, payload: order && getClosestDueDate(order.invoices) })
        dispatch({ type: OrderDetailKind.SET_STATUS, payload: order ? getOrderStatus(order, firstInvoice) : null })
        dispatch({ type: OrderDetailKind.SET_IS_PAY_ONSITE, payload: firstInvoice && firstInvoice.paymentMethod == 'onsite' })
        const invoiceFrames: NodeListOf<HTMLIFrameElement> = document.querySelectorAll('.invoice-iframe')
        invoiceFrames.forEach(ele => {
            ele.src = ele.src
        })
    }, [data])

    return <BaseCard>
        {state.order &&
            <form onSubmit={saveOrder} id="edit-service-order">
                <header className="flex items-center justify-between border-b pb-4">
                    <h1 className="text-th-2xl font-bold">ORDER #OID-{state.order.id}</h1>
                    <div className="flex items-center gap-2">
                        <Button shape="outline" confirm onClick={cancel} color="danger">
                            Cancel Order
                        </Button>
                    </div>
                </header>
                <section className="grid grid-cols-6 mt-4 gap-4">
                    <LabeledInfo label="Status" className="flex flex-wrap items-start gap-2">
                        {state.status && <Pill backgroundColor={state.status.backgroundColor} textColor={state.status.color} fontSize={10}>{state.status.text}</Pill>}
                        {state.firstInvoice && state.firstInvoice.isPaidOnSite &&
                            <Pill backgroundColor="#ffb6c1" textColor="#535158" fontSize={10}>Pay On Site</Pill>
                        }
                    </LabeledInfo>
                    <LabeledInfo label="Customer">
                        <Link target="_blank" to={`/users/${state.order.user.id}`} className="flex items-center gap-2">
                            <span className="text-primary text-th-lg font-medium whitespace-nowrap overflow-hidden text-ellipsis">{state.order.user.name}</span>
                            <TextTooltip text={state.order.user.name}>
                                <InfoCircleIcon width={16} height={16} />
                            </TextTooltip>
                        </Link>
                    </LabeledInfo>
                    <LabeledInfo label="Phone Number">
                        <div className="flex items-center gap-2">
                            <span className="text-primary text-th-lg whitespace-nowrap overflow-hidden text-ellipsis">{state.order?.user.phoneNumber}</span>
                            <TextTooltip text={'WhatsApp'}>
                                <Link target="_blank" to={`https://wa.me/${state.order?.user.phoneNumber}`}>
                                    <FaWhatsapp color="#1945A1" />
                                </Link>
                            </TextTooltip>
                        </div>
                    </LabeledInfo>
                    <LabeledInfo label="Total Tip" onClick={() => scrollToTargetAdjusted('tipsContainer')}>
                        <div className="flex items-center gap-2">
                            <span className="text-primary text-th-lg whitespace-nowrap overflow-hidden text-ellipsis">$ {state.order?.totalTips?.toFixed(2)}</span>
                        </div>
                    </LabeledInfo>
                    {/* <div className="col-span-3">
                        <LabeledInfo label="Address" className="flex items-center gap-2">
                            <span className="text-primary text-th-lg font-medium whitespace-nowrap overflow-hidden text-ellipsis">{formatAddressAny(state.order)}</span>
                            <TextTooltip text={formatAddressAny(state.order)}>
                                <InfoCircleIcon width={16} height={16} />
                            </TextTooltip>
                        </LabeledInfo>
                    </div> */}
                    <LabeledInfo label="Grand Total">
                        <span className="text-primary text-th-lg whitespace-nowrap overflow-hidden text-ellipsis">$ {state.totalPayment?.toFixed(2) ?? '0.00'}</span>
                    </LabeledInfo>
                    <LabeledInfo label="Amount due">
                        <span className="text-primary text-th-lg whitespace-nowrap overflow-hidden text-ellipsis">$ {state.amountDue.toFixed(2) ?? '0.00'}</span>
                    </LabeledInfo>
                </section>

                <section className="relative mt-8 space-y-6">
                    <div className="border-l-[3px] absolute h-full top-0 left-6" />

                    {/* CREATE ORDER */}
                    <OrderDetailCardEditable header="Create Order" Icon={<CustomerDetailIcon />}>
                        {state.order.creatingAdmin ?
                            <><span className="font-medium">Created by <b>Admin {state.order.creatingAdmin.name}</b> at </span> <b>{formatFullDate(state.order.createdAt)}</b></> :
                            <><span className="font-medium">Created by <b>Customer {capitalize(state.order.platform ?? 'Platform')}</b> at </span> <b>{formatFullDate(state.order.createdAt)}</b></>
                        }
                    </OrderDetailCardEditable>


                    {/* INTERNAL NOTES */}
                    <OrderDetailCardEditable header="Admin's Customer Notes" Icon={<InternalNoteIcon />} editable={{
                        defaultValue: {
                            value: state.order.user.internalNote,
                            label: state.order.user.internalNote
                        },
                        name: 'internalNote',
                        endpoint: `/admin/service-orders/${state.order.id}`,
                        refetch: async () => { await refetchOrder() }
                    }}>
                        <span className="font-medium">Last Changed by <b>Admin {state.order.user.internalNoteLastEditedBy?.name || '-'}</b> at </span> <b>{formatFullDate(state.order.user.internalNoteLastEditedAt)}</b>
                    </OrderDetailCardEditable>

                    {/* LEAD SOURCE */}
                    <OrderDetailCardEditable header="Lead Source" Icon={<LeadSourceIcon />} editable={{
                        defaultValue: {
                            value: state.order.leadSource?.id,
                            label: state.order.leadSource?.name
                        },
                        name: 'leadSource',
                        endpoint: `/admin/service-orders/${state.order.id}`,
                        refetch: async () => { await refetchOrder() },
                        handleLoadOptions: handleLoadLeadSource
                    }}>
                        <DisplayDbLog dbLog={state.order.leadSourceDbLog} />
                    </OrderDetailCardEditable>
                    {/* SERVICE REPORT */}
                    {(state.order.schedules.find((sched: any) => sched.report) != undefined) &&
                        <>
                            <div className="bg-white relative z-10">
                                <div className="border rounded-lg p-4 flex justify-between">
                                    <div className="flex items-center gap-3">
                                        <CustomerDetailIcon />
                                        <h3 className="text-th-lg text-light">Service Report</h3>
                                    </div>
                                    <button type="button" onClick={serviceReportListModal.show} className="text-primary">See Service Report</button>
                                </div>
                            </div>
                            <Modal width="80vw" heading={'Service Report Preview'} close={() => { serviceReportPreviewModal.close(); setActiveReportUrl('') }} visible={serviceReportPreviewModal.visible}>
                                <div className="h-[80vh]">
                                    {activeReportUrl ?
                                        <embed
                                            src={activeReportUrl}
                                            type="application/pdf"
                                            width="100%"
                                            height="8"
                                        /> : <></>
                                    }
                                </div>
                            </Modal>
                            <ServiceReportModal
                                schedules={state.order.schedules}
                                modal={serviceReportListModal}
                                user={state.order.user}
                                setActiveReportUrl={setActiveReportUrl}
                                openActiveReportModal={serviceReportPreviewModal.show}
                            />
                        </>
                    }

                    <div className="bg-white relative z-10 border rounded-lg p-4">
                        {/* HEADER */}
                        <div className="flex items-center justify-between border-b pb-4 mb-4">
                            <div className="flex items-start gap-4">
                                <div className="mt-1">
                                    <ServiceListIcon />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <h3 className="text-th-lg text-light">Service List</h3>
                                    <span>There are {state.order.items.length} items</span>
                                    <DisplayDbLog dbLog={state.order.serviceListDbLog} />
                                </div>
                            </div>
                            <div className="flex items-center gap-6">
                                <Switch control={control} name="showNotes" label="Show All Notes" />
                                {state.status && state.status.text != 'Cancelled' && state.status.text != 'Refunded' &&
                                    <Button shape="outline" onClick={() => openAddEdit()}>
                                        <PlusScheduleIcon width={24} height={24} color="#1954A1" />
                                        <span>Add Item</span>
                                    </Button>
                                }
                            </div>
                        </div>

                        {/* ITEMS */}
                        <div className="flex flex-col gap-4">
                            {state.order.items.filter((item: any) => !item.rejectedAt && item.approvedAt != null).sort((b: any, a: any) => a.frequency - b.frequency).map((item: MiscObj) =>
                                <OrderItemCard itemDbLog={state.order?.itemDbLogs?.find((dbLog: any) => dbLog.rowId == item.id)} showAllNotes={showAllNotes} key={item.id} order={state.order!} item={item} refetchOrder={refetchOrder} />
                            )}
                            {state.order.items.filter((item: any) => item.rejectedAt).sort((b: any, a: any) => a.frequency - b.frequency).map((item: MiscObj) =>
                                <OrderItemCard showAllNotes={showAllNotes} key={item.id} order={state.order!} item={item} refetchOrder={refetchOrder} />
                            )}
                        </div>
                    </div>

                    <div className="bg-white relative z-10 border rounded-lg p-4" id={'tipsContainer'}>
                        {/* HEADER TIPS */}
                        <div className="flex items-center justify-between border-b pb-4 mb-4">
                            <div className="flex items-start gap-4">
                                <div className="mt-1">
                                    <TipIcon />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <h3 className="text-th-lg text-light">Tip Recorded</h3>
                                    <DisplayDbLog dbLog={state.order.tipDbLog} />
                                </div>
                            </div>
                            <div className="flex items-center gap-6">
                                <span className="text-primary font-bold text-th-lg">$ {state.order?.totalTips?.toFixed(2)}</span>
                            </div>
                        </div>

                        {/* TIPS */}
                        <div className="flex flex-col gap-4">
                            {state.order?.tips.map((tip: any, index: number) =>
                                <div className={`flex items-center justify-between ${index == 0 ? '' : 'border-t border-dashed pt-2'}`}>
                                    <div className="flex flex-col gap-1">
                                        <span className="font-bold">{formatFullDate(new Date())} {tip.schedule ? `(JID ${tip.schedule.id})` : ''} </span>
                                        <span>Earned by: {tip.technician.name}{tip.role == 1 ? ' (Leader)' : ''}</span>
                                    </div>
                                    <span>$ {(tip.tip).toFixed(2)}</span>
                                </div>
                            )}
                        </div>
                    </div>

                    {state.order && state.order.invoices.sort((a: any, b: any) => a.id - b.id).map((invoice: any) => {
                        return <InvoiceCard
                            invoice={invoice}
                            order={state.order}
                            refetchOrder={() => refetchOrder()}
                            openRecord={() => { recordPaymentModal.show(); setViewedInvoice(invoice); }}
                        />
                    })}
                </section>
            </form>
        }
        {/* Floating DOM */}
        <RecordPaymentModal modal={recordPaymentModal} viewedInvoice={viewedInvoice} refetchOrder={() => { refetchOrder() }} />
        {state.order &&
            <AddEditItemModal order={state.order} modal={addEditItemModal} onFinish={handleAddEditItem} defaultValue={state.defaultEditValue} />
        }
    </BaseCard>
}