import { nanoid } from "nanoid"

export enum OrderDetailKind {
    SET_ORDER = 'SET_ORDER',
    SET_AMOUNT_DUE = 'SET_AMOUNT_DUE',
    SET_DUE_DATE = 'SET_DUE_DATE',
    SET_STATUS = 'SET_STATUS',
    SET_IS_PAY_ONSITE = 'SET_IS_PAY_ONSITE',
    SET_FIRST_INVOICE = 'SET_FIRST_INVOICE',
    TOGGLE_INVOICE = 'TOGGLE_INVOICE',

    ADD_NEW_ITEM = 'ADD_NEW_ITEM',
    REMOVE_NEW_ITEM = 'REMOVE_NEW_ITEM',
    PATCH_NEW_ITEM = 'PATCH_NEW_ITEM',
    CLEAR_NEW_ITEM = 'CLEAR_NEW_ITEM',

    SET_IS_EDIT_MODE = 'SET_IS_EDIT_MODE',
    SET_DEFAULT_EDIT_VALUE = 'SET_DEFAULT_EDIT_VALUE',
    SET_TOTAL_PAYMENT = 'SET_TOTAL_PAYMENT'

}


export interface OrderDetailState {
    order?: MiscObj
    status?: MiscObj | null
    amountDue: number
    dueDate: string | null
    isPayOnsite: boolean,
    firstInvoice?: MiscObj
    newItemQueue: MiscObj[]
    isEditMode: boolean
    defaultEditValue: MiscObj | null
    totalPayment: number
}

export const orderDetailReducer = (state: OrderDetailState, action: ReducerAction<OrderDetailKind>): OrderDetailState => {
    const { type, payload } = action
    switch (type) {
        case OrderDetailKind.SET_ORDER:
            if (!payload) return state
            return { ...state, order: { ...payload, invoices: payload.invoices.map((invoice: any) => ({ ...invoice, isShown: false })) } }
        case OrderDetailKind.SET_AMOUNT_DUE:
            return { ...state, amountDue: payload }
        case OrderDetailKind.SET_DUE_DATE:
            return { ...state, dueDate: payload }
        case OrderDetailKind.SET_STATUS:
            return { ...state, status: payload }
        case OrderDetailKind.SET_IS_PAY_ONSITE:
            return { ...state, isPayOnsite: payload }
        case OrderDetailKind.SET_FIRST_INVOICE:
            return { ...state, firstInvoice: payload }
        case OrderDetailKind.TOGGLE_INVOICE:
            if (!state.order || !payload) return state
            return { ...state, order: { ...state.order, invoices: state.order.invoices.map((invoice: any) => invoice.id == payload ? ({ ...invoice, isShown: !invoice.isShown }) : invoice) } }
        case OrderDetailKind.ADD_NEW_ITEM:
            return { ...state, newItemQueue: [...state.newItemQueue, { ...payload, id: nanoid() }] }
        case OrderDetailKind.REMOVE_NEW_ITEM:
            return { ...state, newItemQueue: state.newItemQueue.filter(item => item.id != payload) }
        case OrderDetailKind.PATCH_NEW_ITEM:
            return { ...state, newItemQueue: state.newItemQueue.map(item => item.id == payload.id ? { ...item, ...payload } : item) }
        case OrderDetailKind.CLEAR_NEW_ITEM:
            return { ...state, newItemQueue: [] }
        case OrderDetailKind.SET_IS_EDIT_MODE:
            return { ...state, isEditMode: payload }
        case OrderDetailKind.SET_DEFAULT_EDIT_VALUE:
            return { ...state, defaultEditValue: payload }
        case OrderDetailKind.SET_TOTAL_PAYMENT:
            return { ...state, totalPayment: payload }
        default:
            return state
    }
}


export const orderDetailInitialState: OrderDetailState = {
    amountDue: 0,
    dueDate: null,
    isPayOnsite: false,
    newItemQueue: [],
    isEditMode: false,
    defaultEditValue: {},
    totalPayment: 0
}