import PageTitle from "components/PageTitle"
import { BaseCard } from 'components/cards'
import { useTable } from "@refinedev/core"
import Calendar, { Schedule } from "components/Calendar"
import { useCallback, useEffect, useMemo, useState } from "react"
import Badge from "components/Badge"
import { addHours, addMonths, addWeeks, format, subMonths, subWeeks } from "date-fns"
import { ButtonsGroup } from "components/buttons"
import { ButtonItem } from "components/buttons/ButtonsGroup"
import { ChevronRightIcon, ClockIcon, ClockOutlineIcon, DollarCircleIcon, MoneyIcon, UsersIcon } from "components/Svg"
import { SERVICE_SCHEDULES_CONFIG } from "resources-config/service-schedule/service-schedule-config"
import { NavLink, useNavigate } from "react-router-dom"
import { stringify } from "query-string"
import { getFilterValue, usdFormatter } from "utils/data-formatter"
import { ScheduleHeader } from "components/headers"
import { TextTooltip } from "components/tooltips"
import { buildCalendarInteractionQuery, getInteractionFilters } from "utils/scheduler"

export default function ListServiceSchedule() {
    const navigate = useNavigate();
    const {
        tableQueryResult, filters, setFilters
    } = useTable({
        resource: SERVICE_SCHEDULES_CONFIG.name,
        queryOptions: { retry: false, },
        pagination: { mode: 'off' },
        filters: {
            initial: [
                { field: 'view', operator: 'eq', value: 'month' },
                { field: 'tzoffset', operator: 'eq', value: 8 },
                {
                    field: 'startdate', operator: 'eq',
                    value: format(addHours(new Date(), 8), 'yyyy-MM') + '-01'
                }
            ]
        }
    })

    const todaySchedUrl = useMemo(() => {
        const { scheduleId, orderId, moveSchedule, teams } = getInteractionFilters(filters)
        const query = stringify({
            'filters[0][field]': 'tzoffset',
            'filters[0][operator]': 'eq',
            'filters[0][value]': getFilterValue(filters, 'tzoffset'),
            'filters[1][field]': 'startdate',
            'filters[1][operator]': 'eq',
            'filters[1][value]': format(new Date(), 'yyyy-MM-dd'),
            ...buildCalendarInteractionQuery(scheduleId, orderId, moveSchedule, teams)
        })
        return `/daily-schedules?${query}`
    }, [filters])

    const changeView = useCallback((view: string) => {
        let currentStartDateObj = filters.find((filter: MiscObj) => (
            filter.field === 'startdate'
        ))?.value
        if (view === 'month') {
            const newStartDate = format(currentStartDateObj, 'yyyy-MM') + '-01'
            setFilters([
                { field: 'view', operator: 'eq', value: 'month' },
                { field: 'startdate', operator: 'eq', value: newStartDate }
            ])
        }
        else {
            setFilters([{ field: 'view', operator: 'eq', value: 'week' }])
        }
    }, [filters, setFilters])

    const [scheduleStats, setScheduleStats] = useState<MiscObj>({})
    const dailySchedules = useMemo(() => {
        if (scheduleStats && scheduleStats.dailyStats) {
            const dailyStats = scheduleStats.dailyStats
            const dates = Object.keys(dailyStats)
            return dates.map((date, idx: number) => (
                <Schedule key={idx} date={date}>
                    <DailyStats stats={dailyStats[date]} />
                    <NavLink
                        className={'absolute top-0 left-0 w-full h-full'}
                        to={`/daily-schedules?filters[0][field]=startdate&filters[0][operator]=eq&filters[0][value]=${date}`}
                    />
                </Schedule>
            ))
        }
        return <></>
    }, [scheduleStats])

    const changePage = useCallback((goNext: boolean) => {
        const view = getFilterValue(filters, 'view')
        const startdate = getFilterValue(filters, 'startdate')

        let currentStartDateObj = new Date(startdate + 'T00:00:00Z')
        if (view === 'month') {
            if (goNext) currentStartDateObj = addMonths(currentStartDateObj, 1)
            else currentStartDateObj = subMonths(currentStartDateObj, 1)
        }
        else {
            if (goNext) currentStartDateObj = addWeeks(currentStartDateObj, 1)
            else currentStartDateObj = subWeeks(currentStartDateObj, 1)
        }
        setFilters([{ field: 'startdate', operator: 'eq', value: format(currentStartDateObj, 'yyyy-MM-dd') }])
    }, [filters, setFilters])


    useEffect(() => {
        if (tableQueryResult.data) {
            setScheduleStats(tableQueryResult.data.data)
        }
    }, [tableQueryResult])

    return (<>
        <PageTitle />
        <ScheduleHeader
            view={getFilterValue(filters, 'view')}
            changePage={changePage}
            changeView={changeView}
            goToToday={() => navigate(todaySchedUrl)}
            startDate={getFilterValue(filters, 'startdate')}
            filters={filters}
            setFilters={setFilters}
        />
        <div className="flex items-center justify-center bg-white py-2 rounded-lg mt-4">
            {scheduleStats && scheduleStats.summary &&
                <div className="bg-primary-2-surface p-4 rounded-lg flex items-center gap-6">
                    <TextTooltip text="Total Revenue" position="top" className="flex items-center gap-2 font-medium">
                        <MoneyIcon />
                        {scheduleStats.summary.totalIncome.toFixed(2)}
                    </TextTooltip>
                    <TextTooltip text="Total Time" position="top" className="flex items-center gap-2 font-medium">
                        <ClockOutlineIcon />
                        {scheduleStats.summary.totalJobs ?? '0'}
                    </TextTooltip>
                    <TextTooltip text="Total Unique Customers" position="top" className="flex items-center gap-2 font-medium">
                        <UsersIcon width={20} height={20} color="#36A9E1" />
                        {scheduleStats.summary.userIds.length ?? '0'}
                    </TextTooltip>
                </div>
            }
        </div>
        <BaseCard className="mt-4">
            <Calendar startDate={getFilterValue(filters, 'startdate')}
                view={getFilterValue(filters, 'view')}
                startWeek="monday"
                handleClick={(date) => {
                    const { scheduleId, orderId, moveSchedule, teams } = getInteractionFilters(filters)
                    const query = stringify({
                        'filters[0][field]': 'tzoffset',
                        'filters[0][operator]': 'eq',
                        'filters[0][value]': 8,
                        'filters[1][field]': 'startdate',
                        'filters[1][operator]': 'eq',
                        'filters[1][value]': date,
                        ...buildCalendarInteractionQuery(scheduleId, orderId, moveSchedule, teams)
                    })
                    navigate(`/daily-schedules?${query}`)
                }}
            >
                {dailySchedules}
            </Calendar>
        </BaseCard>
    </>)
}

function DailyStats({ stats }: { stats: MiscObj }): any {
    const jobTypeIds = Object.keys(stats.jobs)
    const firstFourId = jobTypeIds.slice(0, 4)
    const summary = stats.summary
    return (
        <div className="daily-stats">
            <div className="jobs">
                {
                    firstFourId.map((jobTypeId, idx: number) => {
                        const job = stats.jobs[jobTypeId]
                        const color = job.colorHex ? job.colorHex : 'disable'
                        return (
                            <span className={`job`} key={idx}>
                                <Badge color={color} className="job-name">
                                    {job.name}
                                </Badge>
                                <Badge color={color} className="job-count">
                                    {job.total < 10 ? `0${job.total}` : job.total}
                                </Badge>
                            </span>
                        )
                    })
                }
                {
                    jobTypeIds.length <= 4 ? <></> :
                        <span className={`job`}>
                            <Badge color={'disable'} className="job-name">
                                {jobTypeIds.length - 4} more
                            </Badge>
                        </span>
                }
            </div>
            <div className="stats">
                <div className="stat">
                    <DollarCircleIcon color="#525252" className='icon' />
                    {usdFormatter.format(summary.totalIncome).replace(/$/g, '')}
                </div>
                <div className="stat">
                    <ClockIcon color="#525252" className='icon' />
                    {summary.totalJobs}
                </div>
                <div className="stat">
                    <UsersIcon color="#525252" className='icon' />
                    {summary.userIds.length}
                </div>
            </div>
        </div>
    )
}