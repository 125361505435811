import CreateServicePromo from "components/pages/service-promos/CreateServicePromo"
import EditServicePromo from "components/pages/service-promos/EditServicePromo"
import ListServicePromo from "components/pages/service-promos/ListServicePromo"
import ShowServicePromo from "components/pages/service-promos/ShowServicePromo"
import { PromoCodeIcon } from "components/Svg"
import { ResourceType } from "resources-config"

export const PROMO_CONFIG: ResourceType = {
    name: 'promos', label: 'Promo', meta: {
        icon: <PromoCodeIcon />, apiEndpoints: {
            getList: 'service-promos',
            create: 'service-promos',
            getOne: 'service-promos',
            update: 'service-promos'
        }
    },
    actions: {
        list: {
            path: 'promos', page: <ListServicePromo />,
            allowedRoles: ['super admin'],
        },
        create: {
            path: 'promos/create', page: <CreateServicePromo />,
            allowedRoles: ['super admin']
        },
        edit: {
            path: 'promos/edit/:id', page: <EditServicePromo />,
            allowedRoles: ['super admin']
        },
        show: {
            path: 'promos/:id', page: <ShowServicePromo />,
            allowedRoles: ['super admin']
        }
    }
}