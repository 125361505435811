import { useModalReturnType } from "@refinedev/core"
import { Button } from "components/buttons"
import { Modal } from "components/popups"
import { ReactNode, useState } from "react"
import { createPortal } from "react-dom"


type Props = {
    modal: useModalReturnType
    title: string
    accentColor?: 'primary' | 'danger' | 'gray'
    children: ReactNode
    onSuccess: () => Promise<void>

}

const ConfirmationModal = (props: Props) => {
    const { modal, title, accentColor = 'danger', children, onSuccess } = props
    const [isFetching, setIsFetching] = useState(false)

    const handleClick = async () => {
        setIsFetching(true)
        await onSuccess()
        setIsFetching(false)
        modal.close()
    }

    return createPortal(<Modal visible={modal.visible} close={modal.close} className="text-center space-y-5" width="30rem" heading={''} hideClose={true}>
        <div className="space-y-6">
            <h6 className="font-bold">{title}</h6>
            <div className="flex flex-col">
                {children}
            </div>
            <div className="flex flex-col gap-2">
                <Button disabled={isFetching} onClick={handleClick} shape="filled" color={accentColor}>
                    Yes
                </Button>
                <Button shape="outline" color={accentColor} onClick={modal.close}>
                    Cancel
                </Button>
            </div>
        </div>
    </Modal>, document.body)
}
export default ConfirmationModal