import { TextField, FormsGrid } from 'components/forms'
import { FieldValues, useFieldArray } from 'react-hook-form'
import { useCallback, useEffect } from "react"
import { CloseCircleIcon } from 'components/Svg'

export default function FormFieldPricings({ parentIdx, control, watchedFields }: {
    parentIdx: number
    watchedFields: FieldValues
    register: FormFieldType['register']
    control: FormFieldType['control']
}) {
    const { fields, append, remove } = useFieldArray({
        control,
        name: `frequencies.${parentIdx}.pricings`
    });

    const addPricing = useCallback(() => {
        append({ quantity: 1, price: 1 })
    }, [append])



    return (
        <div className="flex flex-col gap-4">
            {
                !fields || fields.length === 0 ?
                    <span className='block w-full text-center'>
                        No pricings yet.
                    </span>
                    :
                    fields.map((pricing: any, index: number) => {
                        const { pricePerUnit, lspPerUnit } = getPriceDataPerUnit(watchedFields, index, parentIdx)
                        return (
                            <div key={index} className='flex items-start gap-4 tablet-below:flex-col'>
                                <div className='flex items-end gap-3 w-[8rem] shrink-0 tablet-below:w-full'>
                                    <button type='button' className='w-5 h-5 mb-[0.6rem] shrink-0' onClick={() => remove(index)}>
                                        <CloseCircleIcon className='w-full' color='#868686' />
                                        <span className='sr-only'>Remove</span>
                                    </button>
                                    <TextField required label='Quantity' className='w-full' control={control}
                                        isNumeric={true}
                                        fieldName={`frequencies.${parentIdx}.pricings.${index}.quantity`}
                                    />
                                </div>
                                <TextField label='Selling Price Total' control={control}
                                    className='w-full' isNumeric={true} desc={`Price/unit will be: ${pricePerUnit.toFixed(2)}`}
                                    fieldName={`frequencies.${parentIdx}.pricings.${index}.price`}
                                />
                                <TextField label='CP' control={control}
                                    className='w-full' isNumeric={true} desc='Cost Price'
                                    fieldName={`frequencies.${parentIdx}.pricings.${index}.costPrice`}
                                />
                                <TextField label='LSP' control={control}
                                    className='w-full' isNumeric={true} desc={`Lowest SellingPrice/unit will be: ${lspPerUnit.toFixed(2)}`}
                                    fieldName={`frequencies.${parentIdx}.pricings.${index}.lowestSellingPrice`}
                                />
                                <TextField label='Commission (%)' control={control}
                                    className='w-full' isNumeric={true}
                                    fieldName={`frequencies.${parentIdx}.pricings.${index}.commission`}
                                />
                                <TextField label='PP' control={control}
                                    className='w-full' isNumeric={true} desc='Push point'
                                    fieldName={`frequencies.${parentIdx}.pricings.${index}.pushPoint`}
                                />
                            </div>
                        )

                    })
            }
            <button type='button' className='mx-auto mt-4 font-medium text-th-sm text-primary' onClick={addPricing}>
                + Add New Pricing
            </button>
        </div>
    )
}

function getPriceDataPerUnit(watchedFields: MiscObj, index: number, parentIdx: number) {
    const freqObj = watchedFields.frequencies && watchedFields.frequencies[parentIdx] ? watchedFields.frequencies[parentIdx] : {}
    const pricingObj = freqObj.pricings && freqObj.pricings[index] ? freqObj.pricings[index] : {}
    const frequency = freqObj.frequency || 0
    const price = pricingObj.price || 0
    const quantity = pricingObj.quantity || 0
    const lowestSellingPrice = pricingObj.lowestSellingPrice || 0

    const pricePerUnit = price / quantity / frequency
    const lspPerUnit = lowestSellingPrice / quantity / frequency
    return { pricePerUnit, lspPerUnit }
}