

export const formatFullAddress = (buildingNumber: string, street: string, postalCode: string) => {
    return `${buildingNumber} ${street} ${postalCode}`
}

export const formatBuildingAddress = (buildingNumber?: string, street?: string) => {
    return `${buildingNumber} ${street}`
}

export const formatCountry = (country?: string, postalCode?: string) => {
    return `${country} ${postalCode}`
}

export const formatAddressAny = (address: any, config = { showPostalCode: true }) => {
    try {
        if (!address.postalCode) return ""
        return `${address.buildingNumber} ${address.street}${address.buildingName ? `, ${address.buildingName} ` : ' '}${address.floorNumber ? `${address.floorNumber}-` : ''}${address.unitNumber ? `${address.unitNumber}` : ''}, Singapore, ${config.showPostalCode ? address.postalCode : ''}`
    }
    catch {
        return ''
    }
}