import { useModalReturnType } from "@refinedev/core"
import { getActiveServices } from "api/service"
import { Button } from "components/buttons"
import { ChooseItemEffects } from "components/effects"
import { AsyncMultiSelectField, AsyncSelectField, CounterField, FormsGrid, SelectField, TextField } from "components/forms"
import Pill from "components/Pill"
import { Modal } from "components/popups"
import { FieldInfo } from "components/texts"
import { baseApi } from "providers/customDataProvider"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import useSWR from "swr"
import { formatAddressAny } from "utils/address"
import { calculateDiscount } from "utils/discounts"

type Props = {
    modal: useModalReturnType
    onFinish: (data: any, serviceDetail: MiscObj, id?: string) => void
    defaultValue: MiscObj | null
    order: MiscObj
}

const AddEditItemModal
    = (props: Props) => {
        const { modal, onFinish, defaultValue = {}, order } = props
        const { control, handleSubmit, setValue, watch, reset } = useForm()
        const { data: services = [] } = useSWR('active-services', getActiveServices)
        const serviceId = watch(`service`)
        const categoryId = watch(`category`)
        const frequencyId = watch(`frequency`)
        const quantity = watch(`quantity`)
        const [initialPricePerUnit, setInitialPricePerUnit] = useState<number>(0)
        const pricePerUnit = watch(`pricePerUnit`)
        const [serviceDetail, setServiceDetail] = useState<MiscObj>()
        const [availableCategories, setAvailableCategories] = useState<any[]>([])
        const [availableFrequencies, setAvailableFrequencies] = useState<any[]>([])
        const finalPrice = watch(`price`)
        const watchField = watch()

        useEffect(() => {
            reset()
        }, [modal.visible])


        useEffect(() => {
            if (!defaultValue) return
            const defaultPair = Object.entries(defaultValue)
            if (defaultPair.length > 0) defaultPair.forEach(([key, value]) => setValue(key, value))
        }, [defaultValue])

        const getTechnicianOptions = async (search: string) => {
            const { data } = await baseApi.get(`/admin/technicians?search=${search}`)
            return data.list.map((tech: MiscObj) => ({
                value: +tech.id, label: `${tech.name}${tech.vrn ? ` - ${tech.vrn}` : ''}`
            }))
        }

        const selectedTechnicians = useMemo(() => {
            const leaderTechnicians = watchField.leaderTechnicians || []
            const assistantTechnicians = watchField.assistantTechnicians || []
            return [...leaderTechnicians, ...assistantTechnicians]
        }, [watchField])

        const promiseOptions = (inputValue: string) => new Promise<any[]>((resolve) => {
            resolve(getTechnicianOptions(inputValue))
        })

        const handleAddNewItem = handleSubmit((data) => serviceDetail &&
            onFinish({
                ...data,
                upsellTechnicians: [
                    ...(data?.leaderTechnicians || []).map((techId: any) => ({ id: +techId, role: 1 })),
                    ...(data?.assistantTechnicians || []).map((techId: any) => ({ id: +techId, role: null }))
                ]
            }, serviceDetail, defaultValue && defaultValue.id ? defaultValue.id : undefined)
        )

        return <Modal visible={modal.visible} close={modal.close} heading={defaultValue != null ? 'Edit Item' : 'Add Item'} width="32rem">
            <ChooseItemEffects
                categoryId={categoryId}
                serviceId={serviceId}
                setAvailableCategories={setAvailableCategories}
                setAvailableFrequencies={setAvailableFrequencies}
                setValue={setValue}
                name={``}
                availableFrequencies={availableFrequencies}
                quantity={quantity}
                frequencyId={frequencyId}
                pricePerUnit={pricePerUnit}
                setServiceDetail={setServiceDetail}
                setInitialPricePerUnit={setInitialPricePerUnit}
            >
                <form onSubmit={handleAddNewItem} className="space-y-4" id={'add-edit-item-form'}>
                    <SelectField
                        control={control}
                        options={services.map((service: any) => ({ value: +service.id, label: service.name }))}
                        fieldName={`service`}
                        className={`w-full ${modal.visible ? '' : 'hidden'}`}
                        defaultCfg={{ value: null, setValue: setValue }}
                        label='New Item'
                        placeholder='Select an item to add'
                        enableMenuPortalTarget={false}
                        required
                    />

                    <FormsGrid col={2}>
                        <SelectField
                            disabled={!availableCategories || availableCategories.length == 0 || !serviceId}
                            defaultCfg={{ value: null, setValue: setValue }}
                            control={control}
                            label={'Category'}
                            options={availableCategories.map(category => ({ value: +category.id, label: category.name }))}
                            fieldName={`category`} className={`w-full ${modal.visible ? '' : 'hidden'}`} required
                            placeholder="Select category"
                        />
                        <SelectField
                            disabled={!availableFrequencies || availableFrequencies.length == 0}
                            control={control}
                            options={availableFrequencies.map(freq => ({ value: +freq.id, label: `${freq.frequency} times` }))}
                            fieldName={`frequency`}
                            label={'Frequency'}
                            className={`w-full ${modal.visible ? '' : 'hidden'}`} required
                            defaultCfg={{ value: null, setValue: setValue }}
                            placeholder="Select frequency"
                        />
                        <CounterField
                            disabled={!availableFrequencies || availableFrequencies.length == 0}
                            control={control}
                            label={'Quantity'}
                            fieldName={`quantity`}
                            required
                            placeholder="Select quantity"
                        />
                        <div>
                            <TextField
                                required
                                disabled={!frequencyId}
                                fieldName={`pricePerUnit`}
                                label={'Price per unit'}
                                control={control}
                                isNumeric
                                className="w-full"
                                placeholder="Price per unit"
                                defaultValue={''}
                            />
                            <div className="flex items-center justify-between mt-2">
                                <span className="text-[0.625rem] text-light">Initial price/unit/freq : $ {initialPricePerUnit}</span>
                                {calculateDiscount(pricePerUnit, initialPricePerUnit) > 0 &&
                                    <Pill backgroundColor="#FF6666" textColor="white" fontSize={10}>{calculateDiscount(pricePerUnit, initialPricePerUnit)} %</Pill>
                                }
                            </div>
                        </div>
                    </FormsGrid>

                    <FieldInfo label="Amount" value={`$ ${finalPrice ?? 0}`} fieldClassName="w-full bg-gray-100" />

                    <SelectField
                        control={control}
                        options={order.user?.addresses?.map((addr: any) => ({ value: addr.id, label: formatAddressAny(addr) }))}
                        fieldName="addressId"
                        label={'Customer Address'}
                        placeholder="Select customer address"
                        className={`w-full ${modal.visible ? '' : 'hidden'}`}
                    />

                    <FormsGrid col={2}>
                        <>
                            <AsyncMultiSelectField
                                control={control}
                                fieldName="leaderTechnicians" loadOptions={promiseOptions}
                                label={'Upsell Technician Leaders'}
                                filterOption={({ value }) => !selectedTechnicians.includes(value)}
                            />
                            <AsyncMultiSelectField
                                control={control}
                                fieldName="assistantTechnicians" loadOptions={promiseOptions}
                                label={'Upsell Technician Assistants'}
                                filterOption={({ value }) => !selectedTechnicians.includes(value)}
                            />
                        </>
                    </FormsGrid>

                    <TextField
                        control={control}
                        label={'Admin Item Notes'}
                        fieldName={`notes`}
                        placeholder='Enter a note'
                    />
                    <div className="flex items-center justify-end gap-2">
                        <Button shape="outline" onClick={modal.close}>
                            Cancel
                        </Button>
                        <Button confirm type="button" form={'add-edit-item-form'}>
                            {defaultValue ? 'Save' : 'Add Item'}
                        </Button>
                    </div>
                </form>
            </ChooseItemEffects>
        </Modal>

    }

export default AddEditItemModal
