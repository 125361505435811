import { useCustom, useModal, useTable } from "@refinedev/core"
import { BaseCard } from "components/cards"
import DailyScheduler, { ScheduleBlocker, Subject, SubjectSchedule } from "components/DailyScheduler"
import PageTitle from "components/PageTitle"
import { addHours, format, subHours } from "date-fns"
import { useCallback, useEffect, useLayoutEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DAILY_SCHEDULES_CONFIG } from "resources-config/service-schedule/daily-schedule-config"
import { changeTz, getFilterValue, numOrderSuffix } from "utils/data-formatter"
import { stringify } from "query-string"
import { formatAddressAny, formatFullAddress } from 'utils/address';
import { ScheduleHeader } from "components/headers"
import { ScheduleFooter } from "components/footers"
import { AssignTechModal, AddScheduleModal, EditScheduleModal } from "components/custom-modals"
import { buildCalendarInteractionQuery, getInteractionFilters, getJobTypeColor, scrollToTargetAdjusted } from "utils/scheduler"
import Pill from "components/Pill"
import { getStatistics } from "api/scheduler"
import { flatten, get, padStart } from "lodash"
import { ScheduleTooltip } from "components/tooltips"

export default function ListDailySchedule() {
    const navigate = useNavigate();
    const editSchedModal = useModal();
    const teamSchedModal = useModal()
    const addScheduleModal = useModal()
    const [viewedTeam, setViewedTeam] = useState<{ team: MiscObj, date: string, viewTs: number } | null>(null)
    const [currentMonth, setCurrentMonth] = useState<number>(-1)
    const [monthlyStatistic, setMonthlyStatistic] = useState<MiscObj>()
    const [dailyStatistic, setDailyStatistic] = useState<MiscObj | null>(null)
    const [schedules, setschedules] = useState<MiscObj>({})
    const [viewedSchedId, setViewedSchedId] = useState<number | null>(null)
    const [tooltipObject, setTooltipObject] = useState<MiscObj>()
    const {
        tableQueryResult, filters, setFilters,
    } = useTable({
        resource: DAILY_SCHEDULES_CONFIG.name,
        queryOptions: { retry: false, },
        pagination: { mode: 'off' },
        filters: {
            initial: [
                { field: 'tzoffset', operator: 'eq', value: 8 },
                {
                    field: 'startdate', operator: 'eq',
                    value: format(addHours(new Date(), 8), 'yyyy-MM') + '-01'
                }
            ]
        }
    })

    const handleOpenAddSchedule = (date: Date, team: MiscObj) => {
        setViewedTeam({ team: team, date: date.toString(), viewTs: Date.now() })
        addScheduleModal.show()
    }

    const goToToday = useCallback(() => {
        setFilters([{ field: 'startdate', operator: 'eq', value: format(new Date(), 'yyyy-MM-dd') }])
    }, [setFilters])

    const changeView = useCallback((view: string) => {
        const tzoffset = getFilterValue(filters, 'tzoffset')
        const startdate = getFilterValue(filters, 'startdate')
        const { scheduleId, orderId, moveSchedule, teams } = getInteractionFilters(filters)
        const query = {
            'filters[0][field]': 'tzoffset',
            'filters[0][operator]': 'eq',
            'filters[0][value]': tzoffset,
            'filters[1][field]': 'view',
            'filters[1][operator]': 'eq',
            'filters[1][value]': view,
            'filters[2][field]': 'startdate',
            'filters[2][operator]': 'eq',
            'filters[2][value]': (
                view === 'month' ? format(new Date(startdate), 'yyyy-MM') + '-01' : startdate
            ),
            ...buildCalendarInteractionQuery(scheduleId, orderId, moveSchedule, teams)
        }
        navigate(`/service-schedules?${stringify(query)}`)
    }, [filters, navigate])

    useLayoutEffect(() => {
        const scheduleId = getFilterValue(filters, 'schedule')
        const teams = getFilterValue(filters, 'teams')
        if (scrollToTargetAdjusted(`JID${scheduleId}`, 400, 'scrollContainer')) setFilters(filters => filters.filter((filter: any) => filter.field != 'schedule'))
    }, [filters, schedules])

    const changePage = useCallback((goNext: boolean) => {
        const startdate = getFilterValue(filters, 'startdate')
        let currentStartDateObj = new Date(startdate + 'T00:00:00Z')
        if (goNext) currentStartDateObj = addHours(startdate, 24)
        else currentStartDateObj = subHours(startdate, 24)
        setFilters([{ field: 'startdate', operator: 'eq', value: format(currentStartDateObj, 'yyyy-MM-dd') }])
    }, [filters, setFilters])

    const viewTeam = useCallback((team: MiscObj, date: string) => {
        setViewedTeam({ team, date, viewTs: Date.now() })
        teamSchedModal.show()
    }, [teamSchedModal, setViewedTeam])

    const viewSchedule = useCallback((date: string, scheduleId: number) => {
        setViewedSchedId(scheduleId)
        editSchedModal.show()
    }, [setViewedSchedId, editSchedModal])

    useEffect(() => {
        if (tableQueryResult.data) {
            setschedules(tableQueryResult.data.data)
        }
    }, [tableQueryResult])

    useEffect(() => {
        const startDate = getFilterValue(filters, 'startdate')
        refetchAssignments()
        const month = new Date(startDate).getMonth() + 1
        if (month === currentMonth) {
            if (monthlyStatistic && monthlyStatistic.dailyStats[startDate]) {
                setDailyStatistic(monthlyStatistic.dailyStats[startDate])
            } else setDailyStatistic(null)
            return
        }
        setCurrentMonth(month)
        getStatistics(`2024-${padStart(month.toString(), 2, '0')}-01`).then((statistic) => {
            setMonthlyStatistic(statistic)
            if (statistic && statistic.dailyStats[startDate]) {
                setDailyStatistic(statistic.dailyStats[startDate])
            } else setDailyStatistic(null)
        }).catch(err => console.log(err))
    }, [filters])

    const { data: assignments, refetch: refetchAssignments } = useCustom({
        url: `service-schedules/team-assignments`,
        method: "get",
        config: {
            query: {
                date: getFilterValue(filters, 'startdate')
            }
        }
    })

    return <section className="w-full overflow-hidden max-h-[calc(100vh_-_8rem)]">
        <PageTitle title='Daily Schedules' />
        <ScheduleHeader className="top-0" setFilters={setFilters} filters={filters} startDate={getFilterValue(filters, 'startdate')} view="day" changePage={changePage} changeView={changeView} goToToday={goToToday} />
        <BaseCard className="mt-4 overflow-auto max-h-screen" id="scrollContainer">
            <DailyScheduler setTooltipObject={setTooltipObject} highlightedTeams={getFilterValue(filters, 'teams')} handleOpenAddSchedule={handleOpenAddSchedule} date={getFilterValue(filters, 'startdate')} startHour={parseInt(process.env.REACT_APP_START_ORDER || '9')} endHour={parseInt(process.env.REACT_APP_END_ORDER || '18')} multiplier={30}>
                {
                    schedules && schedules.teams ? schedules.teams.map((team: MiscObj, idx: number) => {
                        // @ts-ignore
                        const assignment = assignments && assignments.find((assign: any) => assign.technicianTeam.id == team.id)
                        const technicians = (assignment && assignment.assignedTechnicians) || []
                        return <Subject name={team.name} color={team.colorHex ? team.colorHex : '#001F3F'} key={idx}
                            handleClick={(date) => { viewTeam(team, date) }} team={team} technicians={technicians}
                        >
                            {
                                team.serviceSchedules
                                    .filter((sched: MiscObj) => (sched.order && sched.order.invoices.length))
                                    .map((sched: MiscObj, idx: number) => {
                                        const startDate = changeTz(sched.startDate, 'Asia/Singapore')
                                        const endDate = changeTz(sched.estimatedCompletionDate, 'Asia/Singapore')
                                        const startDateStr = `${format(startDate, 'yyyy-MM-dd')}T${format(startDate, 'HH:mm:ss')}Z`
                                        const endDateStr = `${format(endDate, 'yyyy-MM-dd')}T${format(endDate, 'HH:mm:ss')}Z`
                                        const schedTask = sched.tasks[0]
                                        const addressObj = schedTask?.item?.addresses?.find((addr: any) => addr.period == schedTask.itemPeriod) || sched.order
                                        const address = formatAddressAny(addressObj)
                                        const jobTypes: string[] = Array.from(new Set(sched.tasks.map((task: any) => JSON.stringify(({ name: task.item.service.jobType.name, colorHex: task.item.service.jobType.colorHex })))))
                                        const customerItemNotes = sched.tasks.filter((task: any) => task.item.notes)
                                        const addressNoteCount = addressObj.notes ? 1 : 0
                                        const adminItemNotes = flatten(sched.tasks.map((task: any) => task.item.adminItemNotes))
                                        const totalNotes = adminItemNotes.length + addressNoteCount + adminItemNotes.length
                                        return (
                                            <SubjectSchedule key={idx} startDateTime={startDateStr} endDateTime={endDateStr}
                                                handleClick={(date: string) => { viewSchedule(date, +sched.id) }}
                                                isPriority={sched.isPriority}
                                                schedule={sched}
                                                jobTypes={jobTypes}
                                            >
                                                <div className="relative h-full p-3 text-start text-th-xs cursor-pointer space-y-0.5">
                                                    <div className="gap-2 flex-ct-y">
                                                        <div className="flex items-center justify-between w-full line-clamp-1">
                                                            <span className="font-bold">OID{sched.order.id} - JID{sched.id}</span>
                                                        </div>
                                                    </div>
                                                    <div className="w-fit line-clamp-1">{format(startDate, 'HH:mm')} - {format(endDate, 'HH:mm')}</div>
                                                    <div className="font-bold">{sched.user.name}</div>
                                                    <div className="font-bold">{sched.user.phoneNumber}</div>
                                                    <div className="break-all line-clamp-3">{address}</div>
                                                    <div className="flex items-center gap-2 flex-wrap">
                                                        {jobTypes.map(type => <Pill padding="2px 10px" textColor={JSON.parse(type).colorHex} backgroundColor={JSON.parse(type).colorHex + '33'}>{JSON.parse(type).name}</Pill>)}
                                                    </div>
                                                    {totalNotes > 0 &&
                                                        <div className={`absolute w-full px-3 py-1 rounded-b-lg line-clamp-2 text-th-xs text-light bottom-0 left-0 flex justify-start`}>
                                                            <Pill padding="2px 10px" backgroundColor="white" textColor="#CB3A31" borderColor="#CB3A31" borderWidth="1px">
                                                                {totalNotes} Notes
                                                            </Pill>
                                                        </div>
                                                    }

                                                    <div className="flex flex-col gap-3 pt-4">
                                                        {addressObj.notes &&
                                                            <div className="space-y-1.5">
                                                                <Pill backgroundColor="transparent" borderWidth="1px" padding="2px 10px" borderColor="#EECEB0" textColor="#5B4407">
                                                                    <span className="break-all line-clamp-1">
                                                                        Address Notes
                                                                    </span>
                                                                </Pill>
                                                                <p>{addressObj.notes}</p>
                                                            </div>
                                                        }

                                                        {adminItemNotes.length > 0 &&
                                                            <div className="space-y-1.5">
                                                                <Pill backgroundColor="transparent" borderWidth="1px" padding="2px 10px" borderColor="#1954A1" textColor="#1954A1">
                                                                    <div className="line-clamp-1">
                                                                        Admin's Item Notes
                                                                    </div>
                                                                </Pill>
                                                                {adminItemNotes.map((itemNote: any) =>
                                                                    <p className="break-all line-clamp-1">
                                                                        {itemNote.notes}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        }

                                                        {customerItemNotes.length > 0 &&
                                                            <div className="space-y-1.5">
                                                                <Pill backgroundColor="transparent" borderWidth="1px" padding="2px 10px" borderColor="#EECEB0" textColor="#5B4407">
                                                                    <span className="line-clamp-1">
                                                                        Customer's Item Notes
                                                                    </span>
                                                                </Pill>

                                                                {sched.tasks.map((task: any) => task.item?.notes &&
                                                                    <p className="break-all line-clamp-1">
                                                                        {task.item.notes}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        }


                                                        <div className="space-y-1">
                                                            <Pill backgroundColor="transparent" borderWidth="1px" padding="2px 10px" borderColor="#1954A1" textColor="#1954A1">
                                                                <span className="line-clamp-1">
                                                                    Job Task
                                                                </span>
                                                            </Pill>
                                                            <div className="flex flex-col">
                                                                {sched.tasks.map((task: any) =>
                                                                    <span className="font-bold break-all line-clamp-3">
                                                                        {task.item.quantity} {task.item.service.serviceUnit.name}(s) - {numOrderSuffix(task.itemPeriod + 1)} {task.item.service.name} ({task.item.serviceCategory.name})
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SubjectSchedule>
                                        )
                                    })
                            }

                            {
                                assignment && assignment.scheduleBlockers.map((blocker: any) =>
                                    <ScheduleBlocker startDate={blocker.startDate} endDate={blocker.endDate} />
                                )
                            }
                        </Subject>
                    }) : <></>
                }
            </DailyScheduler>
        </BaseCard>
        {tooltipObject &&
            <ScheduleTooltip
                startDate={tooltipObject.schedule.startDateTime}
                endDate={tooltipObject.schedule.endDateTime}
                schedule={tooltipObject.schedule}
                jobTypes={tooltipObject.schedule.jobTypes}
                isOverlapping={tooltipObject.isOverlapping}
            />
        }

        <ScheduleFooter dailyStatistic={dailyStatistic} technicianAssignments={assignments} schedules={schedules} className="fixed bottom-0 w-[calc(100vw_-_19.5rem)]" />
        <AssignTechModal modal={teamSchedModal} viewedTeam={viewedTeam} refresh={refetchAssignments} />
        <AddScheduleModal initialOrderId={getFilterValue(filters, 'order')} initialScheduleId={getFilterValue(filters, 'moveSchedule')} {...addScheduleModal} refetchDailySched={tableQueryResult.refetch} viewedTeam={viewedTeam} />
        <EditScheduleModal
            setFilters={setFilters}
            refetchDailySched={tableQueryResult.refetch} modal={{ ...editSchedModal, close: () => {editSchedModal.close(); setViewedSchedId(null)} }} scheduleId={viewedSchedId}
            dateTime={format(getFilterValue(filters, 'startdate'), 'eee, dd MMMM yyyy')}
        />
    </section>
}