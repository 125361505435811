import { useCallback } from 'react'
import { useModal, useModalReturnType, useTable } from "@refinedev/core"

import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import Pagination from 'components/Pagination'
import PageTitle from "components/PageTitle"
import { BaseCard } from 'components/cards'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import { SERVICE_ORDER_CONFIG } from 'resources-config/service-order/service-order-config'
import Badge from 'components/Badge'
import useTableQuery from 'utils/useTableQuery'
import { Checkbox, SelectField, TextField } from 'components/forms'
import { Button } from 'components/buttons'
import { FilterIcon } from 'components/Svg'
import { useFieldArray } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Modal } from 'components/popups'
import { changeTz, getOrderStatus, usdFormatter } from 'utils/data-formatter'
import { formatFullDate } from 'utils/date'

export default function ListServiceOrder() {
    const filterModal = useModal()

    const {
        tableQueryResult, pageSize, current, setCurrent, sorters, setSorters,
        filters, setFilters
    } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 20 },
        resource: SERVICE_ORDER_CONFIG.name,
    })
    const { control, doFilter } = useTableQuery(filters, setFilters)

    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    return (<>
        <PageTitle />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft>
                    <TextField
                        control={control} fieldName='search' placeholder="Search by ID or user's name"
                        className='w-full' handleEnter={doFilter}
                    />
                </TabletWidgetLeft>
                <TabletWidgetRight>
                    <Button shape='outline' onClick={filterModal.show}>
                        <FilterIcon />Filter
                    </Button>
                    <Button href='/service-orders/create'>+ New order</Button>
                </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    <Th sorting={{ column: 'id', sorters: sorters, setSorters: setSorters }}>ID</Th>
                    <Th sorting={{ column: 'user', sorters: sorters, setSorters: setSorters }}>User</Th>
                    <Th>Total</Th><Th>Payment Date</Th>
                    <Th>Job Type</Th>
                    <Th>Status</Th>
                    <Th>Status Date</Th>
                    <Th>All Items Scheduled</Th>
                    <Th sorting={{ column: 'creation', sorters: sorters, setSorters: setSorters }}>Created At</Th>
                    <Th>Action</Th>
                </THead>
                {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((order, index) => {
                    const formattedOrder = formatOrderList(order)
                    return (
                        <TBody key={index}>
                            <Td>{formattedOrder.id}</Td>
                            <Td>{formattedOrder.user}</Td>
                            <Td>{formattedOrder.total}</Td>
                            <Td className='mobile-below:min-w-[12rem]'>
                                {formattedOrder.paymentDate}
                            </Td>
                            <Td>
                                {order.items[0] && order.items[0].service.jobType &&
                                    <Badge color={order.items[0].service.jobType.colorHex}>{order.items[0].service.jobType.name}</Badge>
                                }
                            </Td>
                            <Td>
                                {
                                    formattedOrder.status ?
                                        <Badge fullOpacityBackground textColor={formattedOrder.status.color} color={formattedOrder.status.backgroundColor} className='inline-block mr-4 text-th-xs'>
                                            {formattedOrder.status.text}
                                        </Badge> : <></>
                                }
                            </Td>
                            <Td>
                                {
                                    formattedOrder.status ?
                                        formattedOrder.status.date : <></>
                                }
                            </Td>
                            <Td>
                                {formattedOrder.isAllItemScheduled ? 'Yes' : 'No'}
                            </Td>
                            <Td className='mobile-below:min-w-[12rem]'>
                                {formattedOrder.createdAt}
                            </Td>
                            <TAction id={`service-order-${order.id}`}>
                                <TActionItem access={{ resource: SERVICE_ORDER_CONFIG.name, action: 'show' }}>
                                    <Link className={'text-sm'} to={`/service-orders/${order.id}`}>View</Link>
                                </TActionItem>
                            </TAction>
                        </TBody>
                    )
                })}
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                    clickHandler={changePage} range={5}
                />
            </div>
        </BaseCard>
        <FilterModal modal={filterModal} control={control} doFilter={doFilter} />
    </>)
}

const FilterModal = (
    { modal, control, doFilter }: {
        modal: useModalReturnType, control: FormHookType['control'], doFilter: any
    }
) => {
    const fieldArr = useFieldArray({ control: control, name: 'status' })
    return (
        <Modal heading={'Filter'} close={modal.close} visible={modal.visible} width='34rem'>
            <div className='mb-2 font-medium'>Status</div>
            <div className='flex-wrap gap-3 mb-6 flex-ct-y'>
                <Checkbox fieldArr={fieldArr}
                    cbVal={'ongoing'} fieldName='status' control={control}
                    label={'Ongoing'}
                />
                <Checkbox fieldArr={fieldArr}
                    cbVal={'cancelled'} fieldName='status' control={control}
                    label={'Cancelled'}
                />
                <Checkbox fieldArr={fieldArr}
                    cbVal={'completed'} fieldName='status' control={control}
                    label={'Completed'}
                />
                <Checkbox fieldArr={fieldArr}
                    cbVal={'expired'} fieldName='status' control={control}
                    label={'Expired'}
                />
            </div>
            <SelectField className='mb-6' control={control} label={'Payment'} fieldName='payment' options={[
                { value: 'all', label: 'All' },
                { value: 'paid', label: 'Paid' },
                { value: 'unpaid', label: 'Unpaid' }
            ]} />
            <div className='justify-end flex-ct-y'>
                <Button type='button' onClick={() => { doFilter(); modal.close() }} className='text-th-sm'>
                    Filter
                </Button>
            </div>
        </Modal>
    )
}

function formatOrderList(order: MiscObj) {
    const formatStr = 'dd-MM-yyyy HH:mm'
    const formattedOrder = {
        id: order.id,
        user: (order.user && order.user.name) || '',
        total: usdFormatter.format(order.grandTotal),
        paymentDate: (
            order.invoices[0] && order.invoices[0].paidAt ? changeTz(order.invoices[0].paidAt, 'Asia/Singapore', formatStr) : '--'
        ),
        createdAt: formatFullDate(order.createdAt, { showDay: false }),
        status: getOrderStatus(order, order.invoices[0]),
        isAllItemScheduled: true
    }
    order.items.forEach((item: MiscObj) => {
        if (item.scheduleTasks.length !== item.frequency) {
            formattedOrder.isAllItemScheduled = false
        }
    });
    return formattedOrder

}

