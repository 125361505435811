import { CanAccess, useMenu } from "@refinedev/core";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import airpleIcon from '../../images/airple-icon.png'
import { ChevronDownIcon } from "components/Svg";

export const Menu = ({ menuShown, setMenuShown }: { menuShown: boolean, setMenuShown: Dispatch<SetStateAction<boolean>> }) => {
    const { menuItems } = useMenu();
    const [openedItem, setOpenedItem] = useState<number>(-1)

    return (
        <div className={`menu${menuShown ? ' shown' : ''} space-y-4 relative transition-all duration-700`}>
            <div className="gap-4 flex-ct-y border-b py-4">
                <img src={airpleIcon} alt='' className="object-cover w-8 h-8" />
                {menuShown &&
                    <div className={`flex flex-col font-medium text-th-sm whitespace-nowrap`}>
                        Admin Panel
                    </div>
                }

            </div>
            <nav>
                <ul>
                    {menuItems.filter(item => !item.meta?.hide).map((item, idx) => (
                        <MenuItem
                            key={idx} idx={idx} item={item}
                            openedItem={openedItem}
                            setOpenedItem={setOpenedItem}
                            menuShown={menuShown}
                            setMenuShown={setMenuShown}
                            showBottomDivider={item.meta?.showBottomDivider}
                            topLabelDivider={item.meta?.topLabelDivider}
                        />
                    ))}
                </ul>
            </nav>
        </div>
    );
};


const MenuItem = ({ item, idx, openedItem, setOpenedItem, menuShown, setMenuShown, showBottomDivider, topLabelDivider }: MenuItemProps) => {
    const childrenRef = useRef<HTMLUListElement | null>(null)

    useEffect(() => {
        if (childrenRef.current) {
            if (idx === openedItem) {
                childrenRef.current.style.maxHeight = childrenRef.current?.scrollHeight + 'px'
            }
            else {
                childrenRef.current.style.maxHeight = '0'
            }
        }
    }, [openedItem, idx])

    if (item.children.length) {
        return (
            <li className={`item`}>
                <button className={`link justify-between ${openedItem === idx ? 'opened' : ''}`}
                    type="button"
                    onClick={() => { setOpenedItem(state => state === idx ? -1 : idx); setMenuShown(true) }}
                >
                    <span className="gap-3 flex-ct-y whitespace-nowrap">
                        {item.meta && item.meta.icon ? item.meta.icon : ''}
                        {menuShown &&
                            <>{item.label}</>
                        }
                    </span>
                    {menuShown &&
                        <ChevronDownIcon className='chevron-down' />
                    }
                </button>
                {
                    menuShown == true &&
                    <ul className="children" onClick={() => setOpenedItem(-1)} ref={childrenRef}>
                        <MenuLink topLabelDivider={topLabelDivider} showBottomDivider={showBottomDivider} menuShown={menuShown} link={item} customLabel={item.meta.labelAsChild} />
                        {
                            item.children.map((childItem: any, idx: number) => (
                                <MenuLink topLabelDivider={topLabelDivider} showBottomDivider={showBottomDivider} menuShown={menuShown} key={idx} link={childItem} />
                            ))
                        }
                    </ul>
                }
            </li >
        )
    }
    else {
        return <MenuLink topLabelDivider={topLabelDivider} showBottomDivider={showBottomDivider} menuShown={menuShown} link={item} showIcon={true} />
    }
}

const MenuLink = ({ link, customLabel, showIcon, menuShown, showBottomDivider, topLabelDivider }: MenuLinkProps) => {
    return (
        <>
            {topLabelDivider ?
                <div className={`ml-6 text-light text-th-xs ${topLabelDivider == 'Main' ? 'pb-2' : 'pb-2 pt-4'}`}>
                    {topLabelDivider}
                </div> : <></>
            }
            <CanAccess resource={link.name} action={'list'}>
                <li className={`item ${showBottomDivider ? 'border-b pb-4 mb-4' : ''}`}>
                    <NavLink className='link' to={link.route}>
                        <div>
                            {showIcon && link.meta && link.meta.icon ? link.meta.icon : ''}
                        </div>
                        {menuShown &&
                            <span className="whitespace-nowrap"> {customLabel || link.label}</span>
                        }
                    </NavLink>
                </li>
            </CanAccess>
        </>
    )
}

type MenuItemProps = {
    item: any,
    idx: number,
    openedItem: number | undefined,
    setOpenedItem: ChStateNum
    menuShown: boolean
    setMenuShown: Dispatch<SetStateAction<boolean>>
    showBottomDivider?: boolean
    topLabelDivider?: string
}
type MenuLinkProps = {
    link: any,
    customLabel?: string,
    showBottomDivider?: boolean
    menuShown: boolean
    showIcon?: boolean
    topLabelDivider?: string
}