'use client'
import { useModal } from "@refinedev/core"
import { PopUp } from "components/popups"
import { LoadIcon } from "components/Svg"
import { AutoLink } from "components/texts"
import { createPortal } from "react-dom"

export interface ButtonType {
    shape?: 'filled' | 'outline' | 'raw'
    color?: 'primary' | 'gray' | 'danger' // Button color
    tag?: keyof JSX.IntrinsicElements, // Button tag
    isLoading?: boolean, // If true, the button will be disabled
    children?: any,
    className?: string, // Button classes
    [attrName: string]: any // HTML attributes  
    confirm?: boolean
    onClick?: () => void
}
export default function Button({ color, shape, tag, className, isLoading, children, confirm = false, onClick, ...rest }: ButtonType) {
    const confirmModal = useModal()
    const Tag = tag || 'button'
    let classes = (shape != 'raw' ? 'btn' : '') + (className ? ` ${className}` : '')
    classes += (shape ? ` ${shape}` : ' filled')
    classes += (color ? ` ${color}` : ' primary')
    classes += (isLoading ? ` loading` : '')
    classes += (rest.disabled ? ' disabled' : '')
    if (isLoading) {
        rest.disabled = true
    }
    if (rest.href || rest.tag === 'a') {
        return (
            <AutoLink className={classes} href={rest.href} {...rest}>
                {
                    isLoading ?
                        <LoadIcon className='loading-icon' /> :
                        children
                }
            </AutoLink>
        )
    }
    rest.type = rest.type ? rest.type : 'button'
    return (
        <>
            <Tag className={classes} onClick={confirm ? confirmModal.show : onClick} {...rest}>
                <span className="btn-content">
                    {
                        isLoading ?
                            <LoadIcon className='loading-icon' /> :
                            children
                    }
                </span>
            </Tag>
            {confirm &&
                createPortal(<PopUp visible={confirmModal.visible} width="27rem">
                    <div className="hide-scrollbar">
                        <div className="w-full flex flex-col items-center gap-8 ">
                            <div className="flex flex-col items-center gap-6 text-center  tablet-below:px-4">
                                <p className=" text-th-base font-medium leading-7">Are you sure you want to do this ?</p>
                                <p className="text-th-xs text-light mx-10">You cannot change your decision after confirming.</p>
                            </div>
                            <div className='flex flex-col w-full gap-4'>
                                <button type="submit" form={rest.form} onClick={() => { onClick && onClick(); confirmModal.close() }} className='btn filled w-full flex justify-center py-2.5'><p className="text-th-xs">Yes, confirm</p></button>
                                <button type="button" onClick={confirmModal.close} className='btn outline w-full flex justify-center py-2.5'><p className="text-th-xs">No, cancel</p></button>
                            </div>
                        </div>
                    </div>
                </PopUp>, document.body)}
        </>
    )
}
