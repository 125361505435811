import { useState, useCallback, useEffect } from 'react'
import { useModal, useTable, CanAccess, BaseKey } from "@refinedev/core"
import { useForm } from "@refinedev/react-hook-form"

import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import Pagination from 'components/Pagination'
import PageTitle from "components/PageTitle"
import { BaseCard } from 'components/cards'
import { Button } from 'components/buttons'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import { PROMO_CONFIG } from 'resources-config/promo-config'
import { NavLink } from 'react-router-dom'
import { TextField } from 'components/forms'
import useTableQuery from 'utils/useTableQuery'
import Badge from 'components/Badge'
import { toast } from 'sonner'
import { postToggleActivatePromo, postToggleVisibilityPromo } from 'api/promo'
import { TextTooltip } from 'components/tooltips'
import { capitalize } from 'lodash'

export default function ListServicePromo() {
    const { tableQueryResult, pageSize, current, setCurrent, sorters, setSorters, filters, setFilters } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 20 },
        resource: PROMO_CONFIG.name,
    })
    const { control, doFilter } = useTableQuery(filters, setFilters)
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const handleDeactivate = async (id: BaseKey | undefined) => {
        if (!id) return
        try {
            if (await postToggleActivatePromo(id.toString())) {
                toast.success('Success updating promo.')
                tableQueryResult.refetch()
            }
        } catch (err) {
            toast.error('Failed updating promo.')
            console.log(err)
        }
    }

    const handleToggleVisibility = async (id: BaseKey | undefined) => {
        if (!id) return
        try {
            if (await postToggleVisibilityPromo(id.toString())) {
                toast.success('Success updating promo.')
                tableQueryResult.refetch()
            }
        } catch (err) {
            toast.error('Failed updating promo.')
            console.log(err)
        }
    }

    return (<>
        <PageTitle />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft>
                    <TextField
                        control={control} fieldName='search' placeholder="Search by ID or user's name"
                        className='w-full' handleEnter={doFilter}
                    />
                </TabletWidgetLeft>
                <TabletWidgetRight>
                    <CanAccess resource={PROMO_CONFIG.name} action='create'>
                        <Button tag='a' href="/promos/create" >Create Promo</Button>
                    </CanAccess>
                </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    <Th sorting={{ column: 'id', setSorters: setSorters, sorters: sorters }}>ID</Th>
                    <Th sorting={{ column: 'code', setSorters: setSorters, sorters: sorters }}>Promo Code</Th>
                    <Th>Amount</Th>
                    <Th>Max Discount Amount</Th>
                    <Th>Title</Th>
                    <Th>Plaform</Th>
                    <Th>Used Count</Th>
                    <Th>User Visiblity</Th>
                    <Th>Status</Th>
                    <Th>Actions</Th>
                </THead>
                {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((servicePromo, index) => (
                    <TBody key={index}>
                        <Td>{servicePromo.id}</Td>
                        <Td>{servicePromo.code}</Td>
                        <Td>{servicePromo.amount}{servicePromo.type == '2' ? ' %' : ' SGD'}</Td>
                        <Td>{servicePromo.maxAmount ? `$${servicePromo.maxAmount.toFixed(2)}` : '--'}</Td>
                        <Td>{servicePromo.title}</Td>
                        <Td>{servicePromo.platforms ? servicePromo.platforms.map((plat: string) => capitalize(plat)).join(', ') : 'All'}</Td>
                        <Td>{servicePromo.usedCount != undefined ? `${servicePromo.usedCount} times` : '--'}</Td>
                        <Td>
                            <TextTooltip text={servicePromo.isVisible ? 'Can be seen by every users if requirements are met.' : 'Can only be applied by entering code'}>
                                <Badge color={servicePromo.isVisible ? '#1945A1' : '#333333'} className='text-th-xs'>
                                    {(servicePromo.isVisible ? 'Visible' : 'Hidden')}
                                </Badge>
                            </TextTooltip>

                        </Td>
                        <Td>
                            <Badge color={(servicePromo.isActive ? 'success' : 'danger')} className='text-th-xs'>
                                {(servicePromo.isActive ? 'Active' : 'Inactive')}
                            </Badge>
                        </Td>
                        <TAction id={`edit-service-promo-${servicePromo.id}`}>
                            <TActionItem access={{ resource: PROMO_CONFIG.name, action: 'show' }}>
                                <NavLink to={`/promos/${servicePromo.id}`}>
                                    View
                                </NavLink>
                            </TActionItem>
                            <TActionItem access={{ resource: PROMO_CONFIG.name, action: 'edit' }}>
                                <Button shape="raw" onClick={() => handleDeactivate(servicePromo.id)}>
                                    {servicePromo.isActive ? 'Deactivate' : 'Activate'}
                                </Button>
                            </TActionItem>
                            <TActionItem access={{ resource: PROMO_CONFIG.name, action: 'edit' }}>
                                <button onClick={() => handleToggleVisibility(servicePromo.id)} className='text-start'>
                                    {servicePromo.isVisible ? 'Hide' : 'Show'}
                                </button>
                            </TActionItem>
                        </TAction>
                    </TBody>
                ))}
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                    clickHandler={changePage} range={5}
                />
            </div>
        </BaseCard>
    </>)
}

