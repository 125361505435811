import PageTitle from "components/PageTitle";
import FormFieldService from "./FormFieldService";
import { useForm, } from "@refinedev/react-hook-form"
import { SERVICES_CONFIG } from "resources-config/services-config";
import { CreateEditCard } from "components/cards";
import { Button } from "components/buttons";
import { formatToFormData, getErrorNotif, getSuccessNotif } from "utils/data-formatter";
import { useEffect, useState } from "react";
import { handleMutation } from "utils/handle-mutation";

export default function CreateService() {
    const [btnDisabled, setBtnDisabled] = useState(false)
    const {
        refineCore: { onFinish, queryResult, mutationResult }, register, handleSubmit, control, watch,
        setError, clearErrors, formState: { errors },
    } = useForm<MiscObj>({
        refineCoreProps: {
            resource: SERVICES_CONFIG.name,
            action: 'create',

            successNotification(data) {
                let actionUrl = undefined
                if (data && data.data) {
                    actionUrl = `/services/edit/${data.data.id}`
                }
                return getSuccessNotif('create', 'service', actionUrl)
            },
            errorNotification(data) {
                return getErrorNotif('create', 'service', data)
            },
        },
    });
    const onFinishHandler = (data: any) => {
        if (data.frequencies) {
            data.frequencies = data.frequencies.map((freq: MiscObj) => {
                let catArr = freq.category || ''
                catArr = catArr.split('__')
                const catId = catArr[0]
                return {
                    ...freq,
                    category: catId || '',
                    startDuration: freq.startDuration || 0,
                    durationMultiples: freq.durationMultiples || 0,
                    pricings: freq.pricings.map((pricing: any) => ({ ...pricing, price: +pricing.price || 0 })),
                    shownOnSearch: freq.shownOnSearch == 0 ? false : true,
                    isRecomended: freq.isRecomended == 0 ? false : true,
                }
            })
        }
        const formData = formatToFormData(data, ['icon', 'thumbnail', 'gallery'], ['frequencies'])
        onFinish(formData)
    }
    useEffect(() => {
        handleMutation(
            mutationResult, setError, clearErrors, setBtnDisabled
        )
    }, [mutationResult, setError, clearErrors, setBtnDisabled])
    return (<>
        <PageTitle title="Create Service" />
        <CreateEditCard
            cardHeading="Create Service"
            actions={<>
                <Button confirm type='button' form='service-form' isLoading={btnDisabled}>Save</Button>
            </>}
        >
            <FormFieldService
                watch={watch}
                formId={'service-form'}
                register={register}
                queryResult={queryResult}
                control={control}
                onFinish={onFinish}
                handleSubmit={handleSubmit}
                onFinishHandler={onFinishHandler}
                errors={errors}
                setError={setError}
            />
        </CreateEditCard>
    </>)
}