import { ChatIcon, CloseIcon, CopyIcon } from "components/Svg"
import PopUp, { PopUpProps } from "../popups/PopUp"
import Accordion from "components/Accordion"
import { FieldInfo } from "components/texts"
import { useEffect, useState } from "react"
import { getServiceOrderDetail } from "api/service-orders"
import { Button } from "components/buttons"
import { FormsGrid } from "components/forms"
import { formatFullDate } from "utils/date"
import { changeTz } from "utils/data-formatter"
import { acceptPriorityBooking, rejectPriorityBooking } from "api/priority"
import { toast } from "sonner"
import { mutate } from "swr"
import { acceptRescheduleRequest, rejectRescheduleRequest } from "api/reschedule-requests"
import { acceptItemRequest, rejectItemRequest } from "api/item-requests"
import Pill from "components/Pill"
import { getItemRequestConflictedSchedule, getPriorityBookingConflictedJob, getRescheduleNextSchedule } from "api/scheduler"
import { buildDailyScheduleQuery } from "utils/scheduler"
import { Link } from "react-router-dom"
import { formatAddressAny } from "utils/address"

export type RequestObject = {
    scheduleId: string,
    orderId: string,
    topic: string,
    displayDate?: Date
    showAction?: boolean
    submitId: string
    reason?: string
    requestBy?: string
}

type Props = PopUpProps & {
    close: () => void
    viewedObject?: RequestObject
}

const RequestModal = (props: Props) => {
    const { close, viewedObject, ...rest } = props
    const [order, setOrder] = useState<MiscObj>()
    const [schedule, setSchedule] = useState<MiscObj>()
    const [serviceItems, setServiceItems] = useState<MiscObj[]>()
    const [conflictedJobs, setConflictedJobs] = useState<MiscObj[]>()
    const [adminInAction, setAdminInAction] = useState<string>()
    let title = viewedObject ? viewedObject.topic == 'priority' ? 'Priority Booking Request' : viewedObject.topic == 'reschedule' ? 'Reschedule Request' : viewedObject.topic == 'item-request' ? 'Additional Item request' : 'Technician Late' : ''
    const newItemRequest = serviceItems && viewedObject && serviceItems.filter(item => (item.id == viewedObject.submitId))[0]

    const handleAccept = async () => {
        try {
            if (!viewedObject || !viewedObject.submitId) {
                toast.error('Please re-select schedule')
                return
            }
            if (viewedObject.topic == 'priority' && await acceptPriorityBooking(viewedObject.submitId))
                toast.success('Priority booking successfully accepted')

            if (viewedObject.topic == 'reschedule' && await acceptRescheduleRequest(viewedObject.submitId))
                toast.success('Reschedule request successfully accepted')

            if (viewedObject.topic == 'item-request' && await acceptItemRequest(viewedObject.submitId))
                toast.success('Additional item request successfully accepted')

        }
        catch (err: any) {
            console.log(err)
            toast.error(`Something wrong happened. ${err.message}`)
        } finally {
            close()
            mutate(_ => true)
        }
    }

    const handleReject = async () => {
        try {
            if (!viewedObject || !viewedObject.submitId) {
                toast.error('Please re-select schedule')
                return
            }
            if (viewedObject.topic == 'priority' && await rejectPriorityBooking(viewedObject.submitId)) {
                toast.success('Priority booking successfully rejected')
                close()
            }
            if (viewedObject.topic == 'reschedule' && await rejectRescheduleRequest(viewedObject.submitId)) {
                toast.success('Reschedule request successfully rejected')
                close()
            }

            if (viewedObject.topic == 'item-request' && await rejectItemRequest(viewedObject.submitId)) {
                toast.success('Additional item request successfully rejected')
                close()
            }

        }
        catch (err) {
            console.log(err)
            toast.error('Something wrong happened')
        } finally {
            close()
            mutate(_ => true)
        }
    }

    useEffect(() => {
        if (!viewedObject || !viewedObject.orderId || !rest.visible) {
            setConflictedJobs([])
            setOrder(undefined)
            setSchedule(undefined)
            setAdminInAction(undefined)
            return
        }
        // Fetch conflicted Jobs
        if (viewedObject.topic == 'priority') {
            getPriorityBookingConflictedJob(viewedObject.scheduleId).then(jobs => setConflictedJobs(jobs))
        }
        if (viewedObject.topic == 'reschedule') {
            getRescheduleNextSchedule(viewedObject.scheduleId).then(jobs => setConflictedJobs([jobs]))
        }
        if (viewedObject.topic == 'item-request') {
            getItemRequestConflictedSchedule(viewedObject.submitId).then(jobs => setConflictedJobs(jobs))
        }
        getServiceOrderDetail(viewedObject.orderId)
            .then(async order => {
                setOrder(order)
                const schedule = order.schedules.find((sched: any) => sched.id == viewedObject.scheduleId)
                const serviceItems = schedule ? order.items.filter((item: any) => item.scheduleTasks.find((st: any) => schedule && st.schedule.id == schedule.id)) : undefined
                setSchedule(schedule)
                setAdminInAction(schedule.rejectingAdmin?.name)
                setServiceItems(serviceItems)
            })
            .catch(err => console.log(err))
    }, [rest.visible])

    return <PopUp {...rest} width="75vw">
        {viewedObject ?
            <section className="flex items-center justify-between fixed py-8 left-0 top-0 w-full bg-white z-20 px-6 rounded-lg">
                <div className="">
                    <h2 className="font-medium text-th-lg">{title}</h2>
                </div>
                <div className="flex items-center gap-2">
                    {viewedObject && viewedObject.displayDate ? formatFullDate(viewedObject.displayDate) : ''}
                    <button type="button" onClick={close}><CloseIcon color="black" width={24} height={24} /></button>
                </div>
            </section> : <></>
        }


        {(order && viewedObject && schedule) ?
            <section className="grid grid-cols-2 my-20 gap-x-6 relative">
                <div className="space-y-4">
                    <FormsGrid col={2}>
                        <FieldInfo href={`/service-orders/${order.id}`} label="Order ID" value={order.id} />
                        <div className="flex items-end gap-2">
                            <FieldInfo href={`/daily-schedules?${buildDailyScheduleQuery(schedule.startDate, schedule.id)}`} label="Job ID" className="grow" value={viewedObject.scheduleId} />
                            <Button href={`/daily-schedules?${buildDailyScheduleQuery(schedule.startDate, undefined, order.id, schedule.id)}`} target="_blank" shape="outline">
                                <CopyIcon />
                            </Button>
                        </div>
                        <FieldInfo label="Service Date" value={formatFullDate(schedule.startDate, { showTime: false })} />
                        <FieldInfo label="Service Time" value={changeTz(schedule.startDate, 'Asia/Singapore', 'HH:mm')} />
                    </FormsGrid>
                    {adminInAction &&
                        <FieldInfo label={'Admin in Action'} value={adminInAction} />

                    }
                    {viewedObject.requestBy &&
                        <FieldInfo label={viewedObject.topic == 'tech-late' ? 'Assigned Technicians' : 'Request By'} value={viewedObject.requestBy} />
                    }
                    {viewedObject.reason &&
                        <FieldInfo label="Reason" value={viewedObject.reason} />
                    }
                    <Accordion heading={'Customer Data'}>
                        <div className="space-y-4">
                            <div className="flex items-end gap-2">
                                <FieldInfo className="grow" label="Contact Number" value={order.user.phoneNumber} />
                                <Button target="_blank" href={`https://wa.me/${order.user.phoneNumber}`} shape="outline">
                                    <ChatIcon />
                                </Button>
                            </div>
                            <FieldInfo label="Customer Name" value={order.name} />
                            <FieldInfo label="Customer Email" value={order.email} />
                            <FieldInfo label="Address Details" value={formatAddressAny(order)} />
                            <FormsGrid col={2}>
                                <FieldInfo label="Building Name" value={order.buildingName ?? '-'} />
                                <FieldInfo label="Unit Type" value={order.unitType} />
                            </FormsGrid>
                            <FieldInfo label="Customer's Remarks" value={order.notes} />
                        </div>
                    </Accordion>
                    {viewedObject.topic == 'item-request' && newItemRequest &&
                        <Accordion leading={<Pill textColor="#0B5CAD" backgroundColor="#CBE2F9">{newItemRequest.jobDuration} mins</Pill>} heading={'New Item Request'} defaultActive>
                            <div className={`flex flex-col space-y-4 border-b-2 pb-6`}>
                                <FieldInfo label={`New Item`} value={`${newItemRequest.service.name}${newItemRequest.frequency > 1 ? ' (Contract)' : ''}`} />
                                <FieldInfo label="Category" value={newItemRequest.serviceCategory ? newItemRequest.serviceCategory.name : ''} />
                                <FormsGrid col={2}>
                                    <FieldInfo label="Quantity" value={newItemRequest.quantity} />
                                    <FieldInfo label={`Requested Price`} value={`$ ${newItemRequest.price}`} />
                                </FormsGrid>
                                <FormsGrid col={3}>
                                    <FieldInfo label="LSP" className="bg-gray-100" value={`$ ${newItemRequest.lowestSellingPrice}`} />
                                    <FieldInfo label="Initial Price" className="bg-gray-100" value={`$ ${newItemRequest.initialPrice}`} />
                                    <FieldInfo label="Push Point" className="bg-gray-100" value={`$ ${newItemRequest.pushPoint}`} />
                                </FormsGrid>
                            </div>
                        </Accordion>
                    }
                    {serviceItems && serviceItems.length > 0 &&
                        <Accordion heading={'Service Items'} defaultActive>
                            <div className="space-y-6">
                                {serviceItems && serviceItems.filter(item => (item.rejectedAt == null && item.approvedAt)).map((item: any, index: number) => {
                                    return <div className={`flex flex-col space-y-4 border-b-2 pb-6`}>
                                        <FieldInfo label={`Item ${index + 1}`} value={`${item.service.name}${item.frequency > 1 ? ' (Contract)' : ''}`} />
                                        <FieldInfo label="Category" value={item.serviceCategory ? item.serviceCategory.name : ''} />
                                        <FormsGrid col={2}>
                                            <FieldInfo label="Quantity" value={item.quantity} />
                                            <FieldInfo label={`Price`} value={`$ ${item.price}`} />
                                        </FormsGrid>
                                    </div>
                                })}
                            </div>
                        </Accordion>
                    }

                </div>
                <div className="self-start sticky top-24 space-y-0">
                    {(conflictedJobs && conflictedJobs.length > 0) ? conflictedJobs.map((job, index) => {
                        const scheduleHref = `/daily-schedules?${buildDailyScheduleQuery(job.startDate, job.id)}`
                        return <Accordion
                            defaultActive
                            className="bg-[#FFF4F2]"
                            leading={<Pill backgroundColor="white" textColor="black">Total : $ {parseFloat(job.totalPrice).toFixed(2)}</Pill>}
                            heading={<Link target="_blank" to={scheduleHref} className="underline">Conflicted Job {index + 1}</Link>}
                            headerBackground="#CB3A31" headerColor="#FFFFFF" noPadding
                        >
                            <div className="space-y-4 p-5">
                                <div className="flex items-end gap-2">
                                    <FieldInfo className="grow" label="Contact Number" value={job.user.phoneNumber} />
                                    <Button target="_blank" href={`https://wa.me/${job.user.phoneNumber}`} shape="outline">
                                        <ChatIcon />
                                    </Button>
                                </div>
                                <FieldInfo label="Customer Name" value={job.user.name} />
                                <FieldInfo label="Customer Email" value={job.user.email} />
                                <FieldInfo label="Address Details" value={formatAddressAny(job.order)} />
                                <FormsGrid col={2}>
                                    <FieldInfo label="Building Name" value={job.order.buildingName ?? '-'} />
                                    <FieldInfo label="Unit Type" value={job.order.unitType} />
                                </FormsGrid>
                                <FieldInfo label="Customer's Remarks" value={job.order.notes} />
                            </div>
                            <div className="bg-danger p-3 px-5 text-white mt-4">
                                <h3 className="font-medium">Service Items</h3>
                            </div>
                            <div className="px-5 pb-5">
                                {job.scheduleItems.map((schedItem: any, index: number) =>
                                    <div key={schedItem.id} className={`flex flex-col space-y-4 border-b-2 py-6`}>
                                        <FieldInfo label={`Item ${index + 1}`} value={`${schedItem.item.service.name}${schedItem.item.frequency > 1 ? ' (Contract)' : ''}`} />
                                        <FieldInfo label="Category" value={schedItem.item.serviceCategory ? schedItem.item.serviceCategory.name : ''} />
                                        <FormsGrid col={2}>
                                            <FieldInfo label="Quantity" value={schedItem.item.quantity} />
                                            <FieldInfo label={`Price`} value={`$ ${schedItem.item.price}`} />
                                        </FormsGrid>
                                    </div>
                                )}
                            </div>
                        </Accordion>
                    }

                    ) : <p className="font-medium text-th-lg text-center">There is no conflicted jobs</p>}
                </div>

            </section> : <></>
        }
        <section className="flex items-center justify-between fixed py-4 left-0 bottom-0 w-full bg-white z-20 px-6 rounded-lg">
            <div className="flex items-center gap-2">
                <span className="font-medium">Total</span>
                <FieldInfo value={`$${order && order.grandTotal}`} />
            </div>
            {viewedObject && viewedObject.showAction &&
                <div className="flex items-center gap-2">
                    <Button confirm type='button' shape="outline" onClick={handleReject}>
                        Reject
                    </Button>
                    <Button confirm type='button' shape="filled" onClick={handleAccept}>
                        Accept
                    </Button>
                </div>
            }
        </section>
    </PopUp>
}

export default RequestModal