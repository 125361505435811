import { AdminIcon } from "components/Svg"
import CreateAdmin from "components/pages/admins/CreateAdmin"
import ListAdmin from "components/pages/admins/ListAdmin"
import ShowAdmin from "components/pages/admins/ShowAdmin"
import { ResourceType } from "resources-config"

export const ADMINS_CONFIG: ResourceType = {
    name: 'admins', meta: { icon: <AdminIcon />, topLabelDivider: 'Other' },
    actions: {
        list: {
            path: 'admins', page: <ListAdmin />,
            allowedRoles: ['super admin']
        },
        create: {
            path: '/admins/create',
            allowedRoles: ['super admin'], page: <CreateAdmin />
        },
        show: {
            path: 'admins/:id', page: <ShowAdmin />,
            allowedRoles: ['super admin']
        },
        edit: {
            allowedRoles: ['super admin']
        },

    }
}

