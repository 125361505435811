import { Modal } from "components/popups"
import { useModalReturnType } from '@refinedev/core';
import { AsyncSelectField, FormsGrid, SelectField, TextAreaField, TextField } from "components/forms";
import { useForm } from "react-hook-form";
import { Button } from "components/buttons";
import { useEffect, useState } from "react";
import { postInvoiceHistory } from "api/invoice";
import { format, formatISO } from "date-fns";
import { toast } from "sonner";
import { baseApi } from "providers/customDataProvider";
import UploadField from "components/forms/UploadField";
import { formatToFormData } from "utils/data-formatter";


type Props = {
    modal: useModalReturnType
    viewedInvoice?: MiscObj
    refetchOrder: () => void
    isQuotationHistory?: boolean
    quotationId?: string
    allowCashByTechnician?: boolean
}

const PAYMENT_METHODS_OPTIONS = [
    {
        label: 'Cash',
        value: 'Cash',
    },
    {
        label: 'Bank Transfer',
        value: 'Bank Transfer'
    },
    {
        label: 'Credits',
        value: 'Credits'
    },
    {
        label: 'Cash By Technician',
        value: 'onsite'
    },
]

const PAYMENT_TYPE_OPTIONS = [
    {
        label: 'Payment',
        value: 1,
    },
    {
        label: 'Refund',
        value: 2
    },
    {
        label: 'Compensation',
        value: 3
    }
]

const RecordPaymentModal = (props: Props) => {
    const { modal, viewedInvoice, refetchOrder, quotationId, allowCashByTechnician = true } = props
    const { control, handleSubmit, reset, formState: { errors }, watch } = useForm<any>({
        defaultValues: {
            paidAt: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
            amount: 0
        }
    })
    const [isFetching, setIsFetching] = useState(false)
    const paymentMethod = watch('paymentMethod')

    useEffect(() => {
        if (!modal.visible)
            reset()
    }, [modal.visible])

    const submitRecordPayment = handleSubmit(async (data: any) => {
        if (!viewedInvoice) return
        setIsFetching(true)
        try {
            const body = {
                ...data,
                amount: data.amount !== undefined ? +data.amount : undefined,
                technicianId: data.technicianId ? +data.technicianId : undefined,
                type: +data.type,
                paidAt: data.paidAt ? formatISO(new Date(data.paidAt)).toString() : undefined
            };
            const formData = formatToFormData(body, ['proofs']);

            if (quotationId) {
                await baseApi.post(`/admin/quotations/${quotationId}/invoices/${viewedInvoice.id}/invoice-histories`, formData)
            } else {
                await postInvoiceHistory(viewedInvoice.id, formData)
            }

            toast.success('Record payment success')
            modal.close()
            refetchOrder()
        }
        catch (err: any) {
            console.log(err.message)
            const [_, ...message] = err.message[0].split(' ')
            toast.error(message.join(' '))
        }
        finally {
            setIsFetching(false)
        }
    })

    const getTechnicianOptions = async (search: string) => {
        const { data } = await baseApi.get(`/admin/technicians?search=${search}`)
        return data.list.map((tech: MiscObj) => ({
            value: +tech.id, label: `${tech.name}${tech.vrn ? ` - ${tech.vrn}` : ''}`
        }))
    }

    return <Modal visible={modal.visible} close={modal.close} heading={'Record a payment for this invoice'}>
        <form onSubmit={submitRecordPayment} id="record-payment-form">
            <p className="text-light">Record a payment you&apos;ve already received, such as cash or bank payment.</p>
            <div className="flex items-center justify-between mt-6">
                <span className="">Amount Due</span>
                <span className="text-accent text-primary font-bold text-th-lg">$ {viewedInvoice?.amountDue.toFixed(2)}</span>
            </div>
            <FormsGrid col={1} className="mt-8">
                <TextField type='datetime-local' control={control} fieldName="paidAt" className="z-20 relative grow" label={'Date'} required />
                <SelectField enableMenuPortalTarget={false} label={'Record type'} required control={control} fieldName="type" placeholder="Select a payment record type..." options={PAYMENT_TYPE_OPTIONS} />
                <SelectField enableMenuPortalTarget={false} label={'Method'} required control={control} fieldName="paymentMethod" placeholder="Select a payment method..." options={PAYMENT_METHODS_OPTIONS.filter(opt => allowCashByTechnician ? true : opt.value != 'onsite')} />
                {paymentMethod == 'onsite' &&
                    <AsyncSelectField control={control} label={'Technician'} fieldName="technicianId" placeholder="Select a technician" loadOptions={getTechnicianOptions} />
                }
                <TextField type='number' fieldName="amount" onWheel={(e: any) => e.target.blur()} control={control} required placeholder="$ 0.00" label={'Amount'} />
                <UploadField control={control} fieldName="proofs" multiple label={'Upload Proof Image'} />
                <TextAreaField control={control} fieldName="notes" label={'Memo / notes'} />
                <div className="flex items-center justify-end gap-2">
                    <Button disabled={isFetching} shape="outline" onClick={modal.close}>
                        Cancel
                    </Button>
                    <Button disabled={isFetching} confirm type="button" form={'record-payment-form'}>
                        Submit
                    </Button>
                </div>
            </FormsGrid>
        </form>
    </Modal>
}

export default RecordPaymentModal