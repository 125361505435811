import { Button } from "components/buttons"
import { CreateEditCard } from "components/cards"
import FormFieldPromo from "./FormFieldPromo"
import { postCreatePromo } from "api/promo"
import { toast } from "sonner"
import { FieldArray, useFieldArray, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { Checkbox } from "components/forms"


const CreateServicePromo = () => {
    const { control, watch, handleSubmit, setValue, register } = useForm()
    const criteriaFieldArr = useFieldArray({ control: control, name: 'criteria' })
    const navigate = useNavigate()

    const createPromo = handleSubmit(async (data) => {
        try {
            const formatData = {
                ...data,
                type: parseInt(data.type),
                quota: parseInt(data.quota),
                forHaventBookedUser: data.criteria && data.criteria.find((c: string) => c == 'forHaventBookedUser') != undefined,
                forHaventBookedUserInMonths: data.criteria && data.criteria.find((c: string) => c == 'forHaventBookedUserInMonths') != undefined,
                excludedServices: data.excludedServices && data.excludedServices.map((exc: any) => ({ id: parseInt(exc.split('-')[0]), isContract: exc.split('-')[1] == 'contract', categoryId: parseInt(exc.split('-')[2]) })) || [],
                excludedUsers: data.excludedUsers || [],
                includedUsers: data.includedUsers || [],
                genders: data.genders || [],
                quotaApplied: data.criteria && data.criteria.find((c: string) => c == 'quotaApplied') != undefined,
                propertyTypes: data.propertyTypes || [],
                birthdayMonths: data.birthdayMonths || [],
                isVisible: (data.criteria && data.criteria.find((c: string) => c == 'isVisible')) ? true : false,
                maxAmount: data.maxAmount ?? undefined
            }
            await postCreatePromo(formatData)
            toast.success('Promo created successfully !')
            navigate('/promos')
        } catch (err: any) {
            console.log(err)
            toast.error('Failed to create promo. ' + err.message)
        }
    })

    return <form id={'create-promo-form'} onSubmit={createPromo}>
        <CreateEditCard cardHeading="New Promo Code" actions={<ActionItems control={control} criteriaFieldArr={criteriaFieldArr} />}>
            <FormFieldPromo
                formId="create-promo-form"
                control={control}
                watch={watch}
                onSubmit={createPromo}
                criteriaFieldArr={criteriaFieldArr}
                setValue={setValue}
            />
        </CreateEditCard>
    </form>


}

type ActionItemsProps = {
    control: FormHookType['control']
    criteriaFieldArr: FieldArray
}

const ActionItems = (props: ActionItemsProps) => {
    const { control, criteriaFieldArr } = props
    return <div className="flex items-center gap-8">
        <Checkbox shape="switch" fieldArr={criteriaFieldArr} label="Show Promo Code" fieldName={'criteria'} control={control} cbVal={'isVisible'} />
        <Button confirm form='create-promo-form'>Save and Publish</Button>
    </div>
}

export default CreateServicePromo
