import { useModalReturnType } from "@refinedev/core"
import { useForm } from "@refinedev/react-hook-form"
import { Button } from "components/buttons"
import { SelectField, TextAreaField, TextField } from "components/forms"
import { Modal } from "components/popups"
import { baseApi } from "providers/customDataProvider"
import { useState, useEffect } from "react"
import { QUOTATION_ITEMS_CONFIG } from "resources-config/quotation/quotation-items-config"
import { handleModalMutation } from "utils/handle-mutation"



const EditQuotationItemsModal = ({ modal, itemId }: { modal: useModalReturnType, itemId: string }) => {
    const [btnDisabled, disableBtn] = useState(false)
    const { control, refineCore: { queryResult, mutationResult, onFinish, }, watch, handleSubmit, setError, clearErrors, reset, setValue } = useForm({
        refineCoreProps: {
            resource: QUOTATION_ITEMS_CONFIG.name,
            action: 'edit', id: itemId,
            onMutationSuccess: () => { modal.close(); reset(); setDefaultValueSet(false); queryResult?.remove() },
        }
    })
    const [units, setUnits] = useState([])
    const [defaultValueSet, setDefaultValueSet] = useState(false)
    useEffect(() => {
        if (queryResult?.fetchStatus == 'fetching') {
            setDefaultValueSet(false)
        }

        if (!defaultValueSet && modal.visible && queryResult && queryResult.data && queryResult.data.data && units.length > 0) {
            if(queryResult.data.data.itemUnit)
            setValue('itemUnit', queryResult.data.data.itemUnit.id)
            setDefaultValueSet(true)
        }
    }, [queryResult, defaultValueSet])


    useEffect(() => {
        baseApi.get('/admin/service-units?page=1&pageSize=99').then(res => {
            setUnits(res.data.list)
        })
    }, [])

    useEffect(() => {
        handleModalMutation(
            mutationResult, setError, clearErrors, modal.close,
            disableBtn
        )
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close])

    return <Modal visible={modal.visible} close={() => { modal.close(); setDefaultValueSet(false); queryResult?.remove() }} width="30rem" heading={'Edit Quotation Item'}>
        <form onSubmit={handleSubmit((data) => onFinish({ ...data, pricePerUnit: +data.pricePerUnit }))} className="flex flex-col gap-4">
            <TextField required label={'Name'} control={control} fieldName="name" />
            <TextAreaField label={'Description'} control={control} fieldName="description" />
            <TextField required label={'Price Per Unit ($)'} rows="6" control={control} fieldName="pricePerUnit" isNumeric />
            {units.length > 0 &&
                <SelectField
                    label={'Unit Measurement'}
                    control={control}
                    fieldName={'itemUnit'}
                    options={units.map((unit: any) => ({ value: unit.id, label: unit.name }))}
                />
            }
            <Button disabled={btnDisabled} type="submit">
                Submit
            </Button>
        </form>
    </Modal>
}

export default EditQuotationItemsModal