import { BaseRecord, CustomResponse } from "@refinedev/core"
import Pill from "components/Pill"
import { ClockOutlineIcon, ExclamationIcon, FlagIcon, MoneyIcon, PinCheckIcon, TimeRunIcon, TruckIcon, UsersIcon } from "components/Svg"
import { TextTooltip } from "components/tooltips"
import { useMemo } from "react"


type Props = {
    technicianAssignments?: CustomResponse<BaseRecord> | any
    dailyStatistic: MiscObj | null
    schedules: any
    className?: string
}

const ScheduleFooter = (props: Props) => {
    const { dailyStatistic, className = 'sticky bottom-0 mt-4' } = props

    const { totalIncome = 0, totalJobs, totalUsers } = useMemo(() => {
        if (!dailyStatistic) return {}
        const { totalIncome, totalJobs, userIds = [] } = dailyStatistic.summary
        return { totalIncome, totalJobs, totalUsers: userIds.length }
    }, [dailyStatistic])

    return <div className={`bg-white ${className} py-4 px-6 rounded-lg flex items-center justify-between z-50`}>
        <div className="bg-primary-2-surface p-4 rounded-lg flex items-center gap-6">
            <TextTooltip text="Total Revenue" position="top" className="flex items-center gap-2 font-medium">
                <MoneyIcon />
                {totalIncome.toFixed(2) ?? '0'}
            </TextTooltip>
            <TextTooltip text="Total Time" position="top" className="flex items-center gap-2 font-medium">
                <ClockOutlineIcon />
                {totalJobs ?? '0'}
            </TextTooltip>
            <TextTooltip text="Total Unique Customers" position="top" className="flex items-center gap-2 font-medium">
                <UsersIcon width={20} height={20} color="#36A9E1" />
                {totalUsers ?? '0'}
            </TextTooltip>
        </div>
        <div className="flex flex-wrap items-center font-medium gap-2">
            <Pill backgroundColor="#404040" textColor="#FFFFFF">
                Block Schedule
            </Pill>
            <Pill backgroundColor="#FDC521" textColor="#404040">
                Priority Booking
            </Pill>
            <Pill backgroundColor="#ffb6c1" textColor="#404040">
                Pay On-Site
            </Pill>
            <Pill backgroundColor="#CD7B2E" textColor="#FFFFFF">
                <div className="flex items-center gap-2 whitespace-nowrap">
                    <TruckIcon color="white" width={16} height={16} />
                    On The Way
                </div>
            </Pill>
            <Pill backgroundColor="#3267E3" textColor="#FFFFFF">
                <div className="flex items-center gap-2 whitespace-nowrap">
                    <PinCheckIcon color="white" width={16} height={16} />
                    Tech Arrived
                </div>
            </Pill>
            <Pill backgroundColor="#36A9E1" textColor="#FFFFFF">
                <div className="flex items-center gap-2 whitespace-nowrap">
                    <TimeRunIcon color="white" width={16} height={16} />
                    Job Started
                </div>
            </Pill>
            <Pill backgroundColor="#00A19A" textColor="#FFFFFF">
                <div className="flex items-center gap-2 whitespace-nowrap">
                    <FlagIcon color="white" width={16} height={16} />
                    Job Completed
                </div>
            </Pill>
            <Pill backgroundColor="#CB3A31" textColor="#FFFFFF">
                <div className="flex items-center gap-2 whitespace-nowrap">
                    <ExclamationIcon color="white" width={16} height={16} />
                    Schedule Conflict
                </div>
            </Pill>
        </div>
    </div>
}

export default ScheduleFooter