import Pill from "components/Pill"
import { format, subHours } from "date-fns"
import { flatten } from "lodash"
import { createPortal } from "react-dom"
import { SchedClashedIcon, SchedStatusIcon } from "ScheduleStatus"
import { formatAddressAny } from "utils/address"
import { changeTz, numOrderSuffix } from "utils/data-formatter"

type Props = {
    startDate: string
    endDate: string
    schedule: MiscObj
    jobTypes: string[]
    isOverlapping: boolean
}

const ScheduleTooltip = (props: Props) => {
    const { schedule: { schedule }, startDate, endDate, jobTypes, isOverlapping } = props
    const rootElement = document.querySelector('#root')
    const customerItemNotes = schedule.tasks.filter((task: any) => task.item.notes)
    const schedTask = schedule.tasks[0]
    const addressObj = schedTask?.item?.addresses?.find((addr: any) => addr.period == schedTask.itemPeriod) || schedule.order
    const adminItemNotes = flatten(schedule.tasks.map((task: any) => task.item.adminItemNotes))
    return rootElement && createPortal(
        <div className="gap-1.5 fixed left-4 bottom-4 w-[250px] z-50">
            <div className={`bg-black rounded-lg text-white shadow-lg text-start flex flex-col relative z-50`}>
                <div className="top-2 right-2 absolute">
                    {isOverlapping ?
                        <SchedClashedIcon size={'1.5rem'} shadow={false} /> :
                        <SchedStatusIcon schedule={schedule} size="1.5rem" />
                    }
                </div>
                <div className="relative h-full p-3 text-start text-th-xs cursor-pointer space-y-0.5">
                    <div className="gap-2 flex-ct-y">
                        <div className="flex items-center justify-between w-full ">
                            <span className="font-bold text-th-lg">OID{schedule.order.id} - JID{schedule.id}</span>
                        </div>
                    </div>

                    <div className="w-fit ">{changeTz(subHours(startDate, 8), 'Asia/Singapore', 'HH:mm')} - {changeTz(subHours(endDate, 8), 'Asia/Singapore', 'HH:mm')}</div>
                    <div className="font-bold">{schedule.user.name}</div>
                    <div className="font-bold">{schedule.user.phoneNumber}</div>
                    <div className="break-all">{formatAddressAny(addressObj)}</div>
                    <div className="flex items-center gap-2 flex-wrap">
                        {jobTypes.map(type => <Pill padding="2px 10px" textColor={'white'} borderWidth="0" backgroundColor={JSON.parse(type).colorHex}>{JSON.parse(type).name}</Pill>)}
                    </div>
                    <div className="flex flex-col gap-6 pt-4">
                        {addressObj.notes &&
                            <div className="space-y-2">
                                <Pill backgroundColor="#404040" borderWidth="1px" padding="2px 10px" borderColor="#757575" textColor="#F5f5F5">
                                    <span className="break-all ">
                                        Address Notes
                                    </span>
                                </Pill>
                                <p>{addressObj.notes}</p>
                            </div>
                        }

                        {adminItemNotes.length > 0 &&
                            <div className="space-y-2">
                                <Pill backgroundColor="#404040" borderWidth="1px" padding="2px 10px" borderColor="#757575" textColor="#F5F5F5">
                                    <div className="">
                                        Admin's Item Notes
                                    </div>
                                </Pill>
                                {adminItemNotes.map((itemNote: any) =>
                                    <p className="break-all ">
                                        {itemNote.notes}
                                    </p>
                                )}
                            </div>
                        }

                        {customerItemNotes.length > 0 &&
                            <div className="space-y-2">
                                <Pill backgroundColor="#404040" borderWidth="1px" padding="2px 10px" borderColor="#757575" textColor="#F5f5F5">
                                    <span className="">
                                        Customer's Item Notes
                                    </span>
                                </Pill>

                                {schedule.tasks.map((task: any) => task.item?.notes &&
                                    <p className="break-all ">
                                        {task.item.notes}
                                    </p>
                                )}
                            </div>
                        }


                        <div className="space-y-1">
                            <Pill backgroundColor="#404040" borderWidth="1px" padding="2px 10px" borderColor="#757575" textColor="#F5F5F5">
                                <span className="">
                                    Job Task
                                </span>
                            </Pill>
                            <ul className="space-y-2">
                                {schedule.tasks.map((task: any) =>
                                    <li className="font-bold">
                                        {task.item.quantity} {task.item.service.serviceUnit.name}(s) - {numOrderSuffix(task.itemPeriod + 1)} {task.item.service.name} ({task.item.serviceCategory.name})
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>, rootElement
    )
}

export default ScheduleTooltip