import { format } from "date-fns"
import { changeTz } from "./data-formatter"
import { trim } from "lodash"


export const formatFullDate = (date: Date | string, config?: { showTime?: boolean, showDay?: boolean, showYear?: boolean, showDate?: boolean }) => {
    const _config = { showTime: true, showDay: true, showYear: true, showDate: true, ...config }
    let pattern = _config.showTime ? 'eeee, dd MMM yyyy ∙ HH:mm' : 'eeee, dd MMM yyyy'
    pattern = _config.showDay ? pattern : pattern.replace('eeee,', '')
    pattern = _config.showYear ? pattern : pattern.replace('yyyy', '')
    pattern = _config.showDate ? pattern : pattern.replace('dd MMM', '')
    pattern = !_config.showDate && !_config.showDay && !_config.showYear ? pattern.replace('∙', '') : pattern
    return changeTz(date, 'Asia/Singapore', trim(pattern))
}

export const getAvailableMonths = () => [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'Desember', value: 12 },
]

export const getMonthByNumber = (month: number) => {
    const date = `${month}-1-1970`
    return format(date, 'MMMM')
}