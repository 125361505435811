import { ReactNode } from "react"


type Props = {
    children?: ReactNode
    position?: 'top' | 'bottom' | 'left' | 'right'
    className?: string
    tooltipClassName?: string
    Icon: ReactNode
}

const NodeTooltip = (props: Props) => {
    const { Icon, children, position = 'bottom', className = '', tooltipClassName = 'whitespace-nowrap' } = props
    const positionClass = position == 'bottom' ? 'top-full' : position == 'top' ? 'bottom-full' : position == 'left' ? 'right-[300%] top-full break-all' : 'left-full top-full'
    return <div className="group relative cursor-pointer">
        <div className={`group-hover:block hidden absolute right-1/2 translate-x-1/2 translate-y-0 bg-white shadow-xl rounded-lg py-1.5 px-4 z-20  ${tooltipClassName} ${positionClass} `}>
            {children}
        </div>
        <div className={className}>
            {Icon}
        </div>
    </div>
}

export default NodeTooltip