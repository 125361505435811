import { useState, useCallback, useEffect } from 'react'
import { useModal, useTable, CanAccess, BaseKey } from "@refinedev/core"
import { useForm } from "@refinedev/react-hook-form"

import { ADMINS_CONFIG } from 'resources-config/admins-config'

import { SelectField, TextField } from 'components/forms'
import { Modal } from 'components/popups'
import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import Pagination from 'components/Pagination'
import PageTitle from "components/PageTitle"
import { baseApi } from 'providers/customDataProvider'
import { BaseCard } from 'components/cards'
import { Button } from 'components/buttons'
import Badge from 'components/Badge'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import { FilterIcon } from 'components/Svg'

import { capitalizeStr, getErrorNotif, getSuccessNotif } from 'utils/data-formatter'
import useTableQuery from 'utils/useTableQuery'
import { handleMutation } from 'utils/handle-mutation'
import { FilterModal } from 'components/custom-modals'
import { getAllInvoices } from 'api/invoice'
import { filter, padStart } from 'lodash'
import useSWR from 'swr'
import { Link, NavLink } from 'react-router-dom'

export default function ListAdmin() {
    const createAdminModal = useModal();
    const filterModal = useModal();

    const {
        tableQueryResult, pageSize, current, setCurrent, setFilters, filters,
        sorters, setSorters
    } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 20 },
        resource: ADMINS_CONFIG.name,
    })
    const { control, doFilter } = useTableQuery(filters, setFilters)
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const toggleActiveAdmin = useCallback(async (id: BaseKey, isActive: boolean) => {
        try {
            await baseApi.post('admin/admins/toggle-active/' + id, { isActive: isActive })
            tableQueryResult.refetch()
        } catch (error) {
            console.log(error)
        }
    }, [tableQueryResult])

    return (<>
        <PageTitle />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft></TabletWidgetLeft>
                <TabletWidgetRight>
                    <Button shape='outline' onClick={filterModal.show}>
                        <FilterIcon />Filter
                    </Button>
                    <CanAccess resource={ADMINS_CONFIG.name} action='create'>
                        <Button tag='a' href="/admins/create">Add admin</Button>
                        <CreateAdminModal modal={createAdminModal} />
                    </CanAccess>
                </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    <Th sorting={{ column: 'id', sorters: sorters, setSorters: setSorters }}>ID</Th>
                    <Th sorting={{ column: 'name', sorters: sorters, setSorters: setSorters }}>Name</Th>
                    <Th>Email</Th><Th>Role</Th><Th>Status</Th>
                    <Th>Actions</Th>
                </THead>
                {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((admin, index) => (
                    <TBody key={index}>
                        <Td>{padStart(admin.id?.toString(), 4, '0')}</Td>
                        <Td>{admin.name}</Td>
                        <Td>{admin.email}</Td>
                        <Td className='capitalize'>{admin.role}</Td>
                        <Td>
                            <Badge color={(admin.isActive ? 'success' : '#505050')} className='text-th-xs'>
                                {(admin.isActive ? 'Active' : 'Deactivated')}
                            </Badge>
                        </Td>
                        <TAction id={`edit-admin-${admin.id}`}>
                            <TActionItem access={{ resource: ADMINS_CONFIG.name, action: 'edit' }}>
                                <button type='button' onClick={() => { toggleActiveAdmin(admin.id || 0, !admin.isActive) }}>
                                    {admin.isActive ? 'Deactivate' : 'Activate'}
                                </button>
                            </TActionItem>
                            <TActionItem access={{ resource: ADMINS_CONFIG.name, action: 'show' }}>
                                <Link className={'text-sm'} to={`/admins/${admin.id}`}>View</Link>
                            </TActionItem>
                        </TAction>
                    </TBody>
                ))}
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                    clickHandler={changePage} range={5}
                />
            </div>
            <FilterModal doFilter={doFilter} resourceName={ADMINS_CONFIG.name} visible={filterModal.visible} close={filterModal.close}>
                <TextField control={control} fieldName='testing' />
            </FilterModal>
        </BaseCard>
    </>)
}

const CreateAdminModal = ({ modal }: { modal: any }) => {
    const [btnDisabled, disableBtn] = useState(false)

    const {
        refineCore: { onFinish, queryResult, mutationResult }, handleSubmit,
        setError, clearErrors, control,
    } = useForm<any>({
        criteriaMode: 'all',
        refineCoreProps: {
            resource: ADMINS_CONFIG.name,
            successNotification() {
                return getSuccessNotif('create', 'admin')
            },
            errorNotification(data) {
                return getErrorNotif('create', 'admin', data)
            }
        },
    });
    useEffect(() => {
        handleMutation(mutationResult, setError, clearErrors, disableBtn)
    }, [mutationResult, setError, clearErrors, disableBtn])

    return (
        <Modal heading={'Create Admin'} visible={modal.visible} close={modal.close} width='34rem'>
            <form onSubmit={handleSubmit(onFinish)} className="flex flex-col gap-4 text-th-sm">
                <TextField label={'Name'} control={control} fieldName='name' />
                <TextField label={'Email'} control={control} fieldName='email' />
                <TextField label={'Password'} control={control}
                    fieldName='password' type='password'
                />
                <RoleField control={control} queryResult={queryResult} />
                <div className='justify-end flex-ct-y text-th-xs'>
                    <Button type='submit' isLoading={btnDisabled}>Save</Button>
                </div>
            </form>
        </Modal>
    )
}

const RoleField = (
    { control, queryResult }:
        {
            control: FormFieldType['control'],
            queryResult: FormFieldType['queryResult'],
        },

) => {
    const [adminRoles, setAdminRoles] = useState<{ value: string, label: string }[]>([])
    const [defaultValue, setDefaultValue] = useState<SelectSingleVal | undefined | null>(undefined)

    useEffect(() => {
        const getAdminRoles = async () => {
            const { data } = await baseApi.get('admin/admins/roles')
            setAdminRoles(data.map((role: string) => ({
                value: role, label: capitalizeStr(role)
            })))
        }
        getAdminRoles()
    }, [])

    useEffect(() => {
        if (defaultValue === undefined) {
            const defaultVal = queryResult.data ? (queryResult.data.role || null) : null
            if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === true) {
                setDefaultValue(
                    !defaultVal ? null :
                        { value: defaultVal, label: capitalizeStr(defaultVal) }
                )
            }
            else if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === false) {
                setDefaultValue(null)
            }
        }
    }, [queryResult, defaultValue])

    if (adminRoles.length === 0) {
        return <></>
    }
    return (
        !adminRoles.length || defaultValue === undefined ? <></> :
            <SelectField
                control={control} fieldName='role'
                label='Role' required
                options={adminRoles}
            />
    )
}

// const EditZoneModal = (
//     {modal, zone}:
//     {modal: any, zone: {[key: string]: string}}
// ) => {
//     const {
//         refineCore: { onFinish }, register, handleSubmit,
//     } = useForm<FieldType>({
//         defaultValues: {name: zone.name},
//         refineCoreProps: {
//             resource: ZONES_RESOURCE.name,
//             action:  !zone.id ? 'create' : 'edit', id: zone.id || 0
//         },
//     });

//     return (
//         <Modal heading={'Edit Zone'} visible={modal.visible} close={modal.close}>
//             <form onSubmit={handleSubmit(onFinish)} className="flex flex-col">
//                 <TextField label={'Name'} {...register("name", { required: true })}/>
//                 <button type='submit'>Submit</button>
//             </form>
//         </Modal>
//     )
// }

