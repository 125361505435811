import { useModalReturnType, useCustom, CrudFilters } from "@refinedev/core"
import { Button } from "components/buttons"
import { SelectField, Checkbox } from "components/forms"
import Pill from "components/Pill"
import { Modal } from "components/popups"
import { format } from "date-fns"
import { nanoid } from "nanoid"
import { baseApi } from "providers/customDataProvider"
import { useState, useEffect } from "react"
import { useForm, useFieldArray } from "react-hook-form"
import { Link } from "react-router-dom"
import { toast } from "sonner"
import { formatAddressAny } from "utils/address"
import { numOrderSuffix } from "utils/data-formatter"

type Props = {
    modal: useModalReturnType,
    dateTime: string,
    scheduleId: number | null,
    refetchDailySched: () => void
    setFilters: ((filters: CrudFilters) => void) &
    ((setter: (prevFilters: CrudFilters) => CrudFilters) => void)
}

function EditScheduleModal({ modal, dateTime, scheduleId, refetchDailySched, setFilters }: Props) {
    const { control, setValue, getValues, watch, reset } = useForm()
    const fieldArr = useFieldArray({ control: control, name: 'items' })
    const [selectedAddress, setSelectedAddress] = useState<MiscObj>()
    const [schedule, setSchedule] = useState<MiscObj | null>(null)
    const [addressOptions, setAddressOptions] = useState<{ value: any, label: string, isDisabled?: boolean }[]>([])

    const { data, isFetching, isFetched, refetch } = useCustom<MiscObj>({
        url: `service-schedules/get-order-schedule/${scheduleId || 0}`,
        method: "get",
    })

    const scheduleData: MiscObj | undefined = data
    const address = watch('address')
    const period = watch('period')
    const selectedJob = scheduleData?.order?.schedules.find((sched: MiscObj) => sched.period == period)

    useEffect(() => {
        if (!address) return
        setSelectedAddress(addressOptions.find((addr: any) => addr.value == address))
    }, [address])

    useEffect(() => {

        let jobAddress = schedule?.jobAddress
        if (!jobAddress) {
            setAddressOptions((state) => [...state.map(state => ({ ...state, isDisabled: false }))])
            return
        }
        jobAddress = addressOptions.find((addrOpt: any) => addrOpt.label == formatAddressAny(jobAddress))

        if (jobAddress) {
            setSelectedAddress(jobAddress)
            setValue('address', jobAddress.value)
            setAddressOptions((state) => [...state.map(state => ({ ...state, isDisabled: state.value != jobAddress.value }))])
        }

    }, [scheduleId])

    const complete = async () => {
        if (!schedule) return
        try {
            const res = await baseApi.post(`/admin/service-schedules/${schedule.scheduleId}/complete`)
            if (res.data.message == 'Success') {
                toast.success('Successfully completed the schedule')
                refetchDailySched()
            } else {
            }
        }
        catch (err: any) {
            toast.error(err.message)
            console.log(err)
        } finally {
            modal.close()
            refetch()
        }
    }

    const handleCopySchedule = (orderId: string, scheduleId: string) => {
        setFilters((state) =>
            // @ts-ignore
            [...state.filter((filter) => filter.field != 'order' && filter.field != 'moveSchedule'),
            {
                field: 'order',
                operator: 'eq',
                value: orderId
            },
            {
                field: 'moveSchedule',
                operator: 'eq',
                value: scheduleId
            },
            ])
        toast.success('Schedule copied, you can select a date by clicking plus button')
        modal.close()
    }

    const reschedule = async () => {
        try {
            const body = getValues()
            body.order = schedule ? schedule.orderId : null
            body.schedule = schedule ? schedule.scheduleId : null
            body.startDate = schedule ? schedule.startDate : null
            body.technicianTeam = schedule ? schedule.technicianTeamId : null
            body.user = schedule ? schedule.userId : null
            body.items = body.items ? body.items.map((item: string) => +item.split('_')[0]) : []
            await baseApi.post('/admin/service-schedules/reschedule', body)
            refetchDailySched()
            modal.close()
        } catch (error: any) {
            toast.error(error.message)
            console.log(error)
        }
    }
    useEffect(() => {
        if (scheduleId) {
            reset()
            refetch()
        }
    }, [scheduleId, refetch])

    useEffect(() => {
        if (isFetching) {
            setSchedule(null)
            fieldArr.replace([])
        }
        else if (isFetched && data) {
            const schedule: MiscObj = data
            const order = schedule.order
            let formattedSchedule: MiscObj = {}
            let allScheds: MiscObj[] = []
            let selectedItems: string[] = []
            formattedSchedule.scheduleId = +schedule.id
            formattedSchedule.startDate = schedule.startDate
            formattedSchedule.isCompleted = schedule.completionDate != null
            formattedSchedule.orderId = +order.id
            formattedSchedule.technicianTeamId = +schedule.technicianTeam.id
            formattedSchedule.userId = +schedule.user.id
            formattedSchedule.items = []
            formattedSchedule.periods = []
            formattedSchedule.currentPeriod = +schedule.period
            order.items.forEach((item: MiscObj) => {
                const { scheduleTasks, ...itemdata } = item
                const itemStartedScheds = scheduleTasks.filter((task: MiscObj) => {
                    if (+task.schedule.id === scheduleId) {
                        selectedItems.push(`${item.id}_${task.itemPeriod}`)
                    }
                    if (!allScheds.find((sched: MiscObj) => sched.period === task.schedule.period)) {
                        allScheds.push(task.schedule)
                    }
                    return (
                        task.schedule.teamStartDate !== null
                    )
                })
                formattedSchedule.items.push({
                    ...itemdata,
                    selectable: item.frequency !== itemStartedScheds.length
                })
            })
            formattedSchedule.items.sort((a: MiscObj, b: MiscObj) => {
                return b.frequency - a.frequency
            })
            const maximumPeriods = order.items.map((item: any) => item.frequency).reduce((acc: number, current: number) => acc + current, 0)
            for (let period = 0; period < maximumPeriods; period++) {
                const periodSchedule = allScheds.find((sched: MiscObj) => sched.period === period)
                formattedSchedule.periods.push({
                    period: period,
                    teamStartDate: periodSchedule && periodSchedule.teamStartDate ? periodSchedule.teamStartDate : null,
                    startDate: periodSchedule && periodSchedule.startDate ? periodSchedule.startDate : null
                })
            }
            if (order) {
                const formattedOrderAvailableAddresses = Array.from(new Set(order.availableAddresses.map((addr: any) => formatAddressAny(addr))))
                setAddressOptions(formattedOrderAvailableAddresses.map((addr: any) => {
                    const addrId = nanoid()
                    const option = ({ value: addrId, label: addr, isDisabled: true })
                    if (formatAddressAny(addr) == formatAddressAny(schedule.jobAddress)) {
                        setSelectedAddress(option)
                        setValue('address', addrId)
                        option.isDisabled = false
                    }

                    return option
                }))
            }
            setSchedule(formattedSchedule)
            fieldArr.replace(selectedItems)
        }
    }, [data, isFetching, isFetched, scheduleId, fieldArr.replace])


    useEffect(() => {
        if (!scheduleData) return

        const selectedItems = scheduleData.order.items
            .filter((item: any) => item.scheduleTasks
                .find((task: any) => task.schedule?.period == period))
            .map((item: any) => item)
        const schedule = scheduleData.order.schedules.find((sched: any) => sched.period == period)
        const replaceValue: string[] = []
        selectedItems.forEach((item: any) => {
            item.scheduleTasks.forEach((task: any) => {
                if (task.schedule.id == selectedJob?.id) replaceValue.push(`${item.id}_${task.itemPeriod}`)
            })
        })
        fieldArr.replace(replaceValue)
        const selectedAddress = addressOptions.find((option: any) => option.label == formatAddressAny(schedule?.jobAddress))
        if (!selectedAddress) {
            setAddressOptions(options => options.map((opt => ({ ...opt, isDisabled: false }))))

        } else {
            setAddressOptions(options => options.map((opt => ({ ...opt, isDisabled: selectedAddress.value != opt.value }))))
            setSelectedAddress(selectedAddress)
            setValue('address', selectedAddress?.value)
        }

    }, [period])

    useEffect(() => {
        if (!modal.visible) {
            reset()
            setSelectedAddress(undefined)
            setAddressOptions([])
            setSchedule(null)
        }
    }, [modal.visible])

    return (
        <Modal visible={modal.visible} heading={'Edit Schedule'} close={modal.close} width="34rem">
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                    <div>Manage Schedule for <b>{dateTime}</b></div>
                    {schedule &&
                        <div className="flex items-center gap-2">
                            <Link to={`/service-orders/${schedule.orderId}`} className="text-primary font-medium">View Order Details</Link>
                            <span>•</span>
                            <button type="button" onClick={() => handleCopySchedule(schedule.orderId, schedule.scheduleId)} className="text-primary font-medium">Move Schedule</button>
                        </div>
                    }
                </div>
                {
                    !schedule ? <></> :
                        <>
                            <SelectField
                                control={control}
                                fieldName="address"
                                label={'Customer Address'}
                                placeholder="Select the address"
                                options={addressOptions}
                            />
                            <SelectField control={control} fieldName="period" label={'Job'}
                                defaultCfg={{ setValue: setValue, value: { value: schedule.currentPeriod, label: '' } }}
                                options={schedule.periods.map((period: MiscObj) => ({
                                    value: period.period,
                                    label: `${numOrderSuffix(period.period + 1)} job ${period.startDate ? format(new Date(period.startDate), '(eee, dd MMMM yyyy)') : '(Unscheduled)'}`,
                                    isDisabled: period.teamStartDate ? true : false
                                }))}
                            />
                            {
                                selectedAddress && scheduleData && scheduleData.order.items.map((item: MiscObj, index: number) => {
                                    const maxPeriod = item.scheduleTasks.length
                                    return <>
                                        {Array(item.frequency).fill(0).map((_, index) => {
                                            const scheduleTask = item.scheduleTasks[index]
                                            const itemAddress = formatAddressAny(item.addresses.find((addr: any) => addr.period == index) || scheduleData?.order)
                                            if (itemAddress != selectedAddress.label) return null
                                            if (!selectedJob && index != maxPeriod) return null
                                            if (selectedJob && !scheduleTask && index != maxPeriod) return null
                                            if (scheduleTask && selectedJob && scheduleTask.schedule.id != selectedJob.id) return null
                                            return <div className="justify-between flex-ct-y" key={index}>
                                                <Checkbox
                                                    className="max-w-[300px]"
                                                    key={index} fieldArr={fieldArr} control={control} fieldName="items"
                                                    cbVal={`${+item.id}_${index}`} label={`${numOrderSuffix(index + 1)} ${item.service?.name}${item.serviceCategory ? ` (${item.serviceCategory.name})` : ''}`}
                                                    disabled={scheduleTask?.schedule?.completionDate}
                                                />
                                                <div className="flex items-center gap-1">
                                                    <span className="font-medium text-th-xs text-disable">
                                                        {item.quantity || 0} unit(s)
                                                    </span>
                                                    {scheduleTask?.schedule?.completionDate ?
                                                        <Pill padding="2px 8px" className="min-w-[80px] justify-center" fontSize={'10px'} backgroundColor="#00A19A" textColor="white">
                                                            Done
                                                        </Pill> :
                                                        scheduleTask ?
                                                            <Pill padding="2px 8px" className="min-w-[80px] justify-center" fontSize={'10px'} backgroundColor="#1954A1" textColor="white">
                                                                On Schedule
                                                            </Pill> :
                                                            <Pill padding="2px 8px" className="min-w-[80px] justify-center" fontSize={'10px'} backgroundColor="#BB032A" textColor="white">
                                                                Not Done
                                                            </Pill>
                                                    }
                                                </div>
                                            </div>
                                        })}
                                    </>
                                })
                            }
                        </>

                }
                <div className="flex flex-col justify-stretch gap-2 items-stretch">
                    <Button confirm className="mx-auto w-full" onClick={reschedule}>Save</Button>
                    <Button confirm shape="outline" className="mx-auto w-full" onClick={complete}>Complete</Button>
                </div>
            </div>
        </Modal>
    )
}

export default EditScheduleModal