import changeTz from "./change-tz";

export function generateTimeIntervals(startDate: string, endDate: string) {
    const intervals: string[] = [];
    let currentTime = new Date(startDate);

    while (currentTime < new Date(endDate)) {
        const timeStr = changeTz(currentTime, 'Asia/Singapore', 'HH:mm');
        intervals.push(timeStr);

        // Increment time by 30 minutes
        currentTime.setMinutes(currentTime.getMinutes() + 30);
    }

    return intervals;
}
