import { UserCircleIcon } from "components/Svg"
import { BaseFormProps } from "./types"
import { Button } from "components/buttons"
import { useController } from "react-hook-form"
import { ChangeEvent, useState } from "react"
import { remove } from "lodash"

type Props = BaseFormProps & {
    defaultSrc?: { src: string, id: string }
}

type PreviewType = {
    id?: string,
    url?: string,
    name: string
    isDefault: boolean
}

const AvatarUploadField = (props: Props) => {
    const { defaultSrc, control, fieldName } = props
    const [previewUri, setPreviewUri] = useState<PreviewType>()

    const { field: { onChange, value }, } = useController({
        control: control,
        name: fieldName
    })

    const removeController = useController({
        control: control,
        name: `remove-${fieldName}`
    })

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (!files) return

        if (files[0])
            setPreviewUri({
                url: URL.createObjectURL(files[0]),
                name: files[0].name,
                isDefault: false
            })
        onChange(e.target.files)
    }

    return <div className="flex items-center justify-between">
        <div className="flex items-center min-w-[300px] gap-6">
            <div className="rounded-full w-36 h-36 bg-primary-3-surface relative overflow-hidden transition duration-300 hover:opacity-90">
                <input onChange={handleChange} type="file" id={fieldName} accept="image/*" name={fieldName} className="w-full h-full absolute top-0 left-0 z-10 opacity-0 cursor-pointer" />
                {defaultSrc || previewUri ?
                    <img src={(previewUri?.url || defaultSrc?.src)?.replace('https:/a', 'https://a')} alt={'Default Avatar'} className="w-full h-full object-cover" /> :
                    <UserCircleIcon color="#1945A1" className="object-contain" />
                }
            </div>
            <div className="flex flex-col whitespace-nowrap">
                <b>Profile Picture</b>
                <span className="text-light">WebP, JPG or PNG</span>
            </div>
        </div>

        <div className="flex items-center gap-5">
            {/* {
                (defaultSrc || value) &&
                <Button shape="raw" onClick={() => {setPreviewUri(undefined); onChange(null); removeController.field.onChange(true)}} className="text-danger font-bold">
                    Remove
                </Button>
            } */}

            <label htmlFor={fieldName} className="btn outline primary border cursor-pointer">
                <span className="btn-content">
                    Upload a New Picture
                </span>
            </label>
        </div>

    </div>
}

export default AvatarUploadField