import { useForm } from "@refinedev/react-hook-form"
import { Button } from "components/buttons"
import { BaseCard } from "components/cards"
import { AvatarUploadField, FormsGrid, SelectField, TextField } from "components/forms"
import { LoadIcon } from "components/Svg"
import { baseApi } from "providers/customDataProvider"
import { useEffect, useState } from "react"
import { FaChevronLeft } from "react-icons/fa"
import { useNavigate, useParams } from "react-router-dom"
import { ADMINS_CONFIG } from "resources-config/admins-config"
import { toast } from "sonner"
import useSWR from "swr"
import { capitalizeStr, formatToFormData, getErrorNotif, getSuccessNotif } from "utils/data-formatter"
import { setLocalStorage } from "utils/storageHelper"


const ShowAdmin = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [adminRoles, setAdminRoles] = useState([])
    const { refineCore: { onFinish, queryResult, mutationResult }, handleSubmit, control, setError, setValue } = useForm({
        refineCoreProps: {
            resource: ADMINS_CONFIG.name,
            action: 'edit', id: id,
            successNotification(data) {
                let actionUrl = undefined
                if (data && data.data) {
                    actionUrl = `/admins/${data.data.id}`
                    localStorage.setItem('user', JSON.stringify(data.data))
                }

                return getSuccessNotif('update', 'admin', actionUrl)
            },
            errorNotification(data) {
                return getErrorNotif('update', 'admin', data)
            },
        },

    })
    const { data, isLoading } = useSWR(`/admin/${id}`, async () => {
        try {
            const res = await baseApi.get(`admin/admins/${id}`)
            return res.data
        } catch (err: any) {
            toast.error('Failed when getting admin details. ' + err.message)
            return null
        }
    })

    const onSubmit = handleSubmit(async (data) => {
        setIsSubmitting(true)
        try {
            const { password, confirmPassword } = data
            if (password && password != confirmPassword) {
                setError('confirmPassword', { message: "Password confimation doesn't match with the new password" })
                return
            }

            const formData = formatToFormData(data, ['profilePicture'])
            await onFinish(formData)
        } catch (err: any) {
            console.log(err)
            toast.error(err.msg)
        } finally {
            setIsSubmitting(false)
        }

    })

    useEffect(() => {
        setValue('role', queryResult?.data?.data.role)
    }, [queryResult])

    useEffect(() => {
        const getAdminRoles = async () => {
            const { data } = await baseApi.get('admin/admins/roles')
            setAdminRoles(data.map((role: string) => ({
                value: role, label: capitalizeStr(role)
            })))
        }
        getAdminRoles()
    }, [])

    return <form onSubmit={onSubmit} >
        <BaseCard>
            <Button onClick={() => navigate(-1)} shape="outline" color="gray">
                <FaChevronLeft />
                <span>Back</span>
            </Button>
            {!data || isLoading ?
                <div className="w-full grid place-items-center mt-8">
                    <LoadIcon className="loading-icon" width={36} />
                </div>
                :
                <>

                    <div className="flex items-center justify-between mt-6">
                        <h1 className="font-bold text-th-xl">Profile Settings</h1>
                        <Button isLoading={isLoading} shape="filled" type="submit">Save Changes</Button>
                    </div>
                    <section>
                        <div className="mt-8">
                            <AvatarUploadField defaultSrc={data.profilePicture && { src: data.profilePicture, id: data.id }} control={control} fieldName="profilePicture" />
                        </div>
                    </section>
                </>
            }
            <section className="mt-6 border-t py-4">
                <h3 className="font-bold">Profile</h3>
                <span className="text-light">Modify current admin profile data</span>
                <FormsGrid col={2} className="mt-4">
                    <TextField control={control} placeholder="Enter admin's name" label={'Admin Name'} required fieldName="name" />
                    <SelectField options={adminRoles} required defaultCfg={{ setValue, value: queryResult?.data?.data.role }} control={control} label={'Role'} placeholder="Select admin's role" fieldName="role" />
                    <TextField control={control} placeholder="Enter admin's email" label={'Email'} required fieldName="email" />
                </FormsGrid>
            </section>
            <section className="mt-6">
                <h3 className="font-bold">Password</h3>
                <span className="text-light">Modify current password</span>
                <FormsGrid col={2} className="mt-4">
                    <TextField type="password" control={control} placeholder="Enter admin's new password" label={'New Password'} fieldName="password" />
                    <TextField type="password" control={control} placeholder="Re-enter admin's new password" label={'Confirm New Password'} fieldName="confirmPassword" />
                </FormsGrid>
            </section>
        </BaseCard>
    </form>
}

export default ShowAdmin