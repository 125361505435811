import { Refine, CanAccess, useModal, OpenNotificationParams } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import routerBindings, { UnsavedChangesNotifier } from "@refinedev/react-router-v6";
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import authProvider from "providers/authProvider";
import customDataProvider from "providers/customDataProvider";
import accessControlProvider from "providers/accessControlProvider ";

import { RESOURCE_CONFIG } from "resources-config";

import "./App.css";
import Layout from "components/layout/Layout";
import Dashboard from "components/pages/Dashboard";
import Login from "components/pages/auth/Login";
import Forbidden from "components/pages/Forbidden";
import { PopUp } from "components/popups";
import { Button } from "components/buttons";
import { useCallback, useEffect, useState } from "react";
import { CheckMarkCircleIcon, CloseCircleIcon, EyeIcon } from "components/Svg";
import { toast, Toaster } from "sonner";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as firebaseConfig from 'firebaseConfig';
import { getMessaging, onMessage } from "@firebase/messaging";
import useFcmToken from "hooks/useFcmToken";
import ShowServiceOrder from "components/pages/service-orders/ShowServiceOrder";
import ForgotPassword from "components/pages/auth/ForgotPassword";
import ResetPassword from "components/pages/auth/ResetPassword";

const baseUrl = (process.env.REACT_APP_API_BASE_URL || '') + '/admin'

function App() {
    const alertPopUp = useModal()
    const [alertParams, setAlertParams] = useState<OpenNotificationParams | null>(null)
    const { notificationPermissionStatus } = useFcmToken();

    useEffect(() => {
        if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
            if (notificationPermissionStatus === 'granted') {
                const messaging = getMessaging(firebaseConfig.app);
                const unsubscribe = onMessage(messaging, (payload) => {
                    if (payload.notification)
                        toast.info(`${payload.notification?.title}.\n${payload.notification.body}`)
                });
                return () => {
                    unsubscribe();
                };
            }
        }
    }, [notificationPermissionStatus]);

    return (
        <BrowserRouter>
            <RefineKbarProvider>
                <Refine
                    routerProvider={routerBindings}
                    authProvider={authProvider}
                    dataProvider={customDataProvider(baseUrl)}
                    accessControlProvider={accessControlProvider}
                    resources={RESOURCE_CONFIG.getResources()}
                    notificationProvider={{
                        open: (params) => {
                            setAlertParams(params)
                            alertPopUp.show()
                        },
                        close: () => {
                            alertPopUp.close()
                        }
                    }}
                    options={{
                        syncWithLocation: true,
                        warnWhenUnsavedChanges: true,
                        projectId: "GDZyen-AfkubG-qal5Nh",
                        reactQuery: {
                            clientConfig: {
                                defaultOptions: {
                                    queries: {
                                        staleTime: 0,
                                    }
                                }
                            }
                        }
                    }}
                >
                    <Toaster richColors />
                    <Routes>
                        <Route element={<Layout><Outlet /></Layout>}>
                            <Route index element={<Dashboard />} />
                            {RESOURCE_CONFIG.getRoutes().map((route, idx) => (
                                <Route key={idx} path={route.path} element={
                                    <CanAccess resource={route.name}
                                        action={route.action} fallback={<Forbidden />}
                                    >
                                        {route.page}
                                    </CanAccess>
                                } />
                            ))}
                        </Route>
                        <Route path="login" element={<Login />} />
                        <Route path="forgot-password" element={<ForgotPassword />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                    </Routes>
                    <RefineKbar />
                    <UnsavedChangesNotifier />
                    <PopUp visible={alertPopUp.visible}>
                        <AlertPopUpContent params={alertParams} close={alertPopUp.close} />
                    </PopUp>
                </Refine>
            </RefineKbarProvider>
        </BrowserRouter>
    );
}

const AlertPopUpContent = ({ params, close }: {
    params: OpenNotificationParams | null,
    close: () => void
}) => {
    let navigate = useNavigate();
    const handleActionUrl = useCallback((actionUrl: string) => {
        close()
        navigate(actionUrl)
    }, [close, navigate])
    if (params) {
        const keyArr = params.key ? params.key.split('|') : []
        let icon = (
            params.type === 'error' ?
                <CloseCircleIcon className='w-8 mb-1' color="#BB032A" /> :
                <CheckMarkCircleIcon className='w-8 mb-1' color="#24663B" />
        )
        let heading = (
            params.type === 'error' ?
                <span className="mb-2 font-medium text-th-medium">Cannot Proceed</span> :
                <span className="mb-2 font-medium text-th-medium">Success</span>
        )
        let message = (
            params.message ?
                <p>{params.message.replace(/-/g, ' ')}</p> : <></>
        )
        let description = <></>
        if (keyArr[0] === 'themeactionable') {
            description = <button type="button" onClick={() => handleActionUrl(keyArr[1])} className="gap-1 text-blue-500 flex-ct-y">
                <EyeIcon className='w-4' color="#1954A1" />
                Click to view
            </button>
        }
        else {
            description = (
                Array.isArray(params.description) ? params.description[0] :
                    params.description
            )
        }
        return (
            <div className="text-center flex-col-ct-x">
                {icon}
                {heading}
                {message}
                <div className="mt-3 mb-2">
                    {description}
                </div>
                <Button onClick={close} className="mt-3 text-th-xs min-w-[5rem]">OK</Button>
            </div>
        )
    }
    return (
        <div className="text-center flex-col-ct-x">
            <Button onClick={close}>OK</Button>
        </div>
    )
}
export default App;

serviceWorkerRegistration.register();
