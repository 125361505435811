import { useModalReturnType } from "@refinedev/core"
import { useForm } from "@refinedev/react-hook-form"
import { Button } from "components/buttons"
import { TextField, TextAreaField, SelectField } from "components/forms"
import { Modal } from "components/popups"
import { baseApi } from "providers/customDataProvider"
import { useEffect, useState } from "react"
import { QUOTATION_ITEMS_CONFIG } from "resources-config/quotation/quotation-items-config"
import { handleModalMutation } from "utils/handle-mutation"



const CreateQuotationItemsModal = ({ modal }: { modal: useModalReturnType }) => {
    const [btnDisabled, disableBtn] = useState(false)
    const { control, refineCore: { queryResult, mutationResult, onFinish }, handleSubmit, setError, clearErrors, reset } = useForm({
        refineCoreProps: {
            resource: QUOTATION_ITEMS_CONFIG.name,
            action: 'create',
            onMutationSuccess: () => { modal.close(); reset() }
        }
    })
    const [units, setUnits] = useState([])
    useEffect(() => {
        baseApi.get('/admin/service-units?page=1&pageSize=99').then(res => {
            setUnits(res.data.list)
        })
    }, [])
    useEffect(() => {
        handleModalMutation(
            mutationResult, setError, clearErrors, modal.close,
            disableBtn
        )
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close])

    return <Modal visible={modal.visible} close={modal.close} width="30rem" heading={'Create new Quotation Item'}>
        <form onSubmit={handleSubmit((data) => onFinish({ ...data, pricePerUnit: +data.pricePerUnit }))} className="flex flex-col gap-4">
            <TextField required label={'Name'} control={control} fieldName="name" />
            <TextAreaField label={'Description'} control={control} fieldName="description" />
            <TextField required label={'Price Per Unit ($)'} rows="6" control={control} fieldName="pricePerUnit" isNumeric />
            {units.length > 0 &&
                <SelectField
                    label={'Unit Measurement'}
                    control={control}
                    fieldName={'itemUnit'}
                    options={units.map((unit: any) => ({ value: unit.id, label: unit.name }))}
                />
            }
            <Button disabled={btnDisabled} type="submit">
                Submit
            </Button>
        </form>
    </Modal>
}

export default CreateQuotationItemsModal