import { useLogin } from "@refinedev/core";
import PageTitle from "components/PageTitle";
import { Button } from "components/buttons";
import { useForm } from 'react-hook-form';
import { useState } from "react";
import useFcmToken from "hooks/useFcmToken";
import { Link } from "react-router-dom";
import AuthLayout from "components/layout/AuthLayout";
import { TextField } from "components/forms";

export default function Login() {
    const [hideLoginErr, setHideLoginErr] = useState(true)
    const { fcmToken, oldFcmToken, replaceOldToken } = useFcmToken();
    const { control, getValues, handleSubmit } = useForm<any>();
    const { mutate: login, isLoading } = useLogin()
    const requestLogin = handleSubmit((data) => {
        login({ ...data, deviceToken: fcmToken, oldDeviceToken: oldFcmToken }, {
            onSuccess: (data) => {
                replaceOldToken()
                setHideLoginErr(data.success)
            }
        })
    })

    return (<>
        <PageTitle title="Login" />
        <AuthLayout>
            <form onSubmit={requestLogin} className="flex flex-col gap-10 justify-center">
                <h1 className="font-bold text-th-xl">Welcome to <span className="text-primary">AIRPLE ADMIN</span></h1>
                <div className="space-y-4">
                    <TextField autoFocus control={control} placeholder="Enter your email address" label='Email' required fieldName='email' />
                    <TextField control={control} placeholder="Enter your password" label='Password' required type="password" fieldName='password' />
                </div>
                <div className="text-end">
                    <Link to={'/forgot-password'} className="text-primary">
                        Forgotten your password?
                    </Link>
                </div>
                <Button isLoading={isLoading} type="submit" shape="filled" className="w-full font-bold">
                    Continue
                </Button>
            </form>
        </AuthLayout>
    </>)
}
