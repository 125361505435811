import { Controller } from "react-hook-form"
import { BaseFormProps } from "./types"

type Props = BaseFormProps & {
    options: { value: any, label: string }[]
    showLabel?: boolean
}

const RadioField = (props: Props) => {
    const { control, fieldName, options, required, label, showLabel = true, defaultValue, ...rest } = props

    return <Controller
        control={control}
        name={fieldName}
        defaultValue={defaultValue}
        rules={{ required: required ? `${label || 'Field'} is required.` : undefined }}
        render={({ field: { onChange, value } }) => <>
            {options.map(({ value: _value, label }) =>
                <div key={_value} className="flex items-center gap-2">
                    <input {...rest} onChange={(e) => onChange(e.currentTarget.value)} value={_value} checked={value == _value} type="radio" id={_value} />
                    <label htmlFor={_value}>{label}</label>
                </div>
            )}
        </>}
    />
}

export default RadioField