import { addHours, addMinutes, areIntervalsOverlapping, differenceInMinutes, format, isBefore, isEqual, startOfDay, subHours } from "date-fns"
import { CSSProperties, Dispatch, EventHandler, SetStateAction, useMemo, useRef, useState } from "react"
import { PlusScheduleIcon } from "./Svg"
import { SchedClashedIcon, SchedStatusIcon } from "ScheduleStatus"
import { formatFullAddress } from "utils/address"
import { TextTooltip } from "./tooltips"
import { TechnicianAvatar } from "./avatars"
import Pill from "./Pill"
import { flatten, isArray } from "lodash"
import { changeTz } from "utils/data-formatter"
import { formatFullDate } from "utils/date"
import useScrollPosition from "hooks/useScrollPosition"

interface DailySchedulerType {
    children?: any
    date: string
    startHour: number
    endHour: number
    multiplier: number // minutes
    highlightedTeams?: string[]
    setTooltipObject: Dispatch<SetStateAction<MiscObj | undefined>>
    handleOpenAddSchedule: (date: Date, team: MiscObj) => void
}
export default function DailyScheduler({ date, startHour, endHour, multiplier, children, handleOpenAddSchedule, highlightedTeams, setTooltipObject }: DailySchedulerType) {
    const dailyScheduleRef = useRef(null)
    const _highlightedTeams = !isArray(highlightedTeams) ? highlightedTeams ? [highlightedTeams] : undefined : highlightedTeams
    const dailySchedulerPosition = useScrollPosition('scrollContainer')
    const subjecSchedules = useMemo(() => {
        if (!children) {
            return []
        }
        if (Array.isArray(children)) {
            let childArr = children.flat()
            return childArr.filter(child => child.props && child.props.tagIdentifier === 'Subject')
        }
        else {
            if (children.props && children.props.tagIdentifier === 'Subject') return [children]
            return []
        }
    }, [children])

    return (
        <div className='daily-scheduler relative overflow-x-auto max-w-[calc(100vw_-_380px)] pb-[350px]' id="dailyScheduler" ref={dailyScheduleRef}>
            <HourLabels startHour={startHour} endHour={endHour} multiplier={multiplier} />
            <div className="all-schedules">
                <div className='daily-scheduler skeleton'>
                    {
                        subjecSchedules.map((subject: MiscObj, idx: number) => {
                            const isTeamHighlighted = _highlightedTeams?.includes(subject.props.team.id.toString())
                            return <div key={idx} className={`team-schedule ${!_highlightedTeams ? '' : `${!isTeamHighlighted ? 'opacity-10' : ''}`}`}>
                                <div className="team" style={{ background: subject.props.color, opacity: 0.1 }}></div>
                                <Hours setTooltipObject={setTooltipObject} team={subject.props.team} handleOpenAddSchedule={handleOpenAddSchedule} date={date} startHour={startHour}
                                    endHour={endHour} multiplier={multiplier}
                                    color={subject.props.color} skeletonOnly
                                />
                            </div>
                        })
                    }
                </div>
                <div className="flex gap-0.5 w-full">
                    {
                        subjecSchedules.map((subject: MiscObj, idx: number) => {
                            let highlightedTeamIndex = -1
                            const isTeamHighlighted = _highlightedTeams?.find((teamId, index) => {
                                if (subject.props.team.id.toString() == teamId) {
                                    highlightedTeamIndex = index + 1
                                    return true
                                }
                                return false
                            })
                            return <div key={idx} className="team-schedule static">
                                <div className={`team ${!_highlightedTeams ? '' : `${!isTeamHighlighted ? 'opacity-10' : ''}`}`}>
                                    <button onClick={() => {
                                        if (subject.props.handleClick) {
                                            subject.props.handleClick(date)
                                        }
                                    }} type="button"
                                        className="relative z-[50] rounded-full border-2"
                                        style={{ borderColor: subject.props.color, top: Math.max((dailySchedulerPosition?.scrollYPosition || 0) - 36, 0), background: subject.props.color }}>
                                        <div className="text-th-sm w-full py-2 px-4 rounded-full font-medium flex items-center justify-between bg-white bg-opacity-90">
                                            <div className="flex flex-col text-[13px]">
                                                <span className="font-bold">{subject.props.name}</span>
                                                {highlightedTeamIndex > -1 &&
                                                    <Pill textColor="white" backgroundColor="#FF6666" fontSize={10} padding="1px 10px">
                                                        Priority {highlightedTeamIndex}
                                                    </Pill>
                                                }
                                            </div>
                                            <TextTooltip style={{ transform: `translateX(${Math.min((subject.props.technicians.length -1) * 15, 45)}px)` }} position={idx == subjecSchedules.length - 1 ? 'left' : 'bottom'} className="flex items-center gap-2" text={subject.props.technicians.map((tech: any) => `${tech.role == 1 ? '(Leader) ' : ''}${tech.technician.name}`).join(', ')}>
                                                {subject.props.technicians.slice(0, 3).map((tech: any, index: number) =>
                                                    <TechnicianAvatar className={`w-5 h-5 scale-[1.2] border-white border`} style={{ transform: `translateX(-${index * 15}px)` }} imageSrc={tech.technician.profilePicture} color="#1954A1" />
                                                )}
                                                {subject.props.technicians.length - 3 > 0 &&
                                                    <div className="w-5 h-5 bg-primary-3-surface border-white border rounded-full translate-x-[-45px] text-[8px] text-light grid place-items-center">
                                                        +{subject.props.technicians.length - 3}
                                                    </div>
                                                }
                                            </TextTooltip>
                                        </div>

                                    </button>
                                </div>
                                {/* <ScheduleBlocker */}
                                <Hours setTooltipObject={setTooltipObject} reducePlusVisibility={_highlightedTeams != undefined && !isTeamHighlighted} team={subject.props.team} date={date} handleOpenAddSchedule={handleOpenAddSchedule} startHour={startHour}
                                    endHour={endHour} multiplier={multiplier}
                                    color={'white'} subjectSchedules={flatten(subject.props.children)}
                                />
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

interface SubjectType {
    color: string
    name: string
    handleClick?: (date: string) => void,
    children?: any
    technicians: MiscObj[]
    team: MiscObj
}

export function Subject(props: SubjectType) {
    return <></>
}

Subject.defaultProps = {
    tagIdentifier: 'Subject'
}

interface SubjectScheduleType {
    startDateTime: string
    endDateTime: string
    schedule: any
    children?: any
    isPriority: boolean
    overlapCounts?: number
    jobTypes: string[]
    handleClick?: (date: string) => void
}

export function SubjectSchedule(props: SubjectScheduleType) {
    return <></>
}

SubjectSchedule.defaultProps = {
    tagIdentifier: 'SubjectSchedule'
}

interface ScheduleBlockerType {
    startDate: string,
    endDate: string
    notes?: string
}

export function ScheduleBlocker(props: ScheduleBlockerType) {
    return <></>
}

ScheduleBlocker.defaultProps = {
    tagIdentifier: 'ScheduleBlocker'
}


interface HoursType {
    date: string
    startHour: number
    endHour: number
    multiplier: number // minutes
    color: string
    team: MiscObj
    skeletonOnly?: boolean
    reducePlusVisibility?: boolean
    subjectSchedules?: any
    handleOpenAddSchedule: (date: Date, team: MiscObj) => void
    setTooltipObject: Dispatch<SetStateAction<MiscObj | undefined>>
}

const Hours = ({ date, startHour, endHour, multiplier, color, skeletonOnly, subjectSchedules, reducePlusVisibility, handleOpenAddSchedule, team, setTooltipObject }: HoursType) => {
    const formatSubjectSchedules = useMemo(() => {
        if (!Array.isArray(subjectSchedules)) return subjectSchedules
        const actualSubjectSchedules = subjectSchedules.filter((children: any) => children != undefined && children.props.tagIdentifier != 'ScheduleBlocker')
        const blockSubjectSchedules = subjectSchedules.filter((children: any) => children != null && children.props.tagIdentifier == 'ScheduleBlocker')

        const formattedActualSubjectSchedules = actualSubjectSchedules.map((subSched: any, index: any) => {
            let overlapCount = 0
            const currentStartDate = subSched.props.startDateTime
            const currentEndDate = subSched.props.endDateTime
            const currentScheduleId = subSched.props.schedule.id


            actualSubjectSchedules.forEach(subSched => {
                if (subSched.props.schedule.id != currentScheduleId && subSched.props.schedule.completionDate == null && (
                    areIntervalsOverlapping(
                        {
                            start: currentStartDate,
                            end: currentEndDate
                        },
                        {
                            start: subSched.props.startDateTime,
                            end: subSched.props.endDateTime
                        }
                    )
                )) overlapCount += 1
            })


            return {
                ...subSched,
                props: {
                    ...subSched.props,
                    overlapCounts: overlapCount,
                }
            }
        })


        return { formattedActualSubjectSchedules, blockSubjectSchedules }
    }, [subjectSchedules])

    const hours = useMemo(() => {
        let startDay = date + 'T00:00:00Z'
        let startTime = addHours(startDay, startHour)
        const endTime = addHours(startDay, endHour)
        const hours = []
        while (isBefore(startTime, endTime) || isEqual(startTime, endTime)) {
            hours.push(startTime)
            startTime = addMinutes(startTime, multiplier)
        }
        return hours
    }, [startHour, endHour, multiplier, date])

    return (<>
        {hours.map((hour, idx) => (
            <div key={idx} className="schedule-container" style={skeletonOnly ? { background: color, opacity: 0.1 } : {}}>
                <Schedule
                    setTooltipObject={setTooltipObject}
                    currDateTime={hour} color={color} multiplier={multiplier}
                    subjectSchedules={formatSubjectSchedules} dateTimeIdx={idx} hoursLength={hours.length}
                />
                <button type="button" className={`add-schedule ${reducePlusVisibility ? 'opacity-20' : ''}`} onClick={() => handleOpenAddSchedule(hour, team)}>
                    <PlusScheduleIcon width={16} height={16} />
                </button>
            </div>
        ))}
    </>)
}

interface HourLabelsType {
    startHour: number
    endHour: number
    multiplier: number // minutes
}

const HourLabels = ({ startHour, endHour, multiplier }: HourLabelsType) => {
    const hours = useMemo(() => {
        let startDay = startOfDay(new Date())
        let startTime = addHours(startDay, startHour)
        const endTime = addHours(startDay, endHour)
        const hours = []
        while (isBefore(startTime, endTime) || isEqual(startTime, endTime)) {
            hours.push(startTime)
            startTime = addMinutes(startTime, multiplier)
        }
        return hours
    }, [startHour, endHour, multiplier])

    return (<>
        <div className="hour-labels">
            {hours.map((hour, idx) => (
                idx === 0 ?
                    <div key={idx} className="hour-label mt-[4.5rem]">
                        {format(new Date(hour), 'HH:mm')}
                    </div> :
                    <div key={idx} className="hour-label">
                        {format(new Date(hour), 'HH:mm')}
                    </div>
            ))}
        </div>
    </>)
}

interface ScheduleType {
    currDateTime: Date
    color: string
    multiplier: number
    subjectSchedules?: any,
    handleClick?: (date: string) => void,
    dateTimeIdx: number,
    hoursLength: number,
    setTooltipObject: Dispatch<SetStateAction<MiscObj | undefined>>
}


export function Schedule({ currDateTime, color, multiplier, subjectSchedules, dateTimeIdx, hoursLength, setTooltipObject }: ScheduleType) {
    const [freezeTooltip, setFreezeTooltip] = useState(false)

    const schedules: null | SubjectScheduleType[] = useMemo(() => {
        if (Array.isArray(subjectSchedules?.formattedActualSubjectSchedules)) {
            const subjectSchedulesArr = subjectSchedules.formattedActualSubjectSchedules.flat()
            const schedules = subjectSchedulesArr.filter((item: any) => {

                return (
                    item.props && item.props.tagIdentifier === 'SubjectSchedule'
                    && isEqual(new Date(item.props.startDateTime), currDateTime)
                )
            })
            return schedules.map((schedules: any) => schedules.props)
        }
        else if (
            subjectSchedules?.formattedActualSubjectSchedules?.props?.tagIdentifier === 'SubjectSchedule' &&
            isEqual(new Date(subjectSchedules.props.startDateTime), currDateTime)
        ) {
            return subjectSchedules.props
        }
        return null
    }, [subjectSchedules])

    const tzOffset = Math.abs(new Date().getTimezoneOffset() / 60)

    const blockSchedule = subjectSchedules?.blockSubjectSchedules?.find((block: any) => isEqual(addHours(new Date(changeTz(block.props.startDate, 'Asia/Singapore')), tzOffset), currDateTime))
    let blockScheduleHeight;

    if (blockSchedule) {
        const minutesDiff = differenceInMinutes(blockSchedule.props.endDate, blockSchedule.props.startDate)
        let slotOccupied = 0
        let _slotOccupied = Math.ceil(minutesDiff / multiplier)
        if ((dateTimeIdx + slotOccupied) > hoursLength) {
            slotOccupied = hoursLength - dateTimeIdx
        }
        slotOccupied = _slotOccupied
        blockScheduleHeight = `${(slotOccupied * 70) + (slotOccupied * 2) - 24}px`
    }


    return <div className="rounded-lg text-body relative w-[90%] h-auto">
        {blockSchedule &&
            <div className="absolute bg-[#404040] w-full rounded-lg text-white flex flex-col justify-center" style={{ height: blockScheduleHeight }}>
                <span className="font-bold text-th-lg">Block Schedule</span>
                <span>{formatFullDate(blockSchedule.props.startDate, { showDay: false, showYear: false, showDate: false })} - {formatFullDate(blockSchedule.props.endDate, { showDay: false, showYear: false, showDate: false })}</span>
            </div>
        }
        {schedules && schedules.sort((a, b) => a.schedule.id - b.schedule.id).map((schedule, index) => {
            let slotOccupied = 0

            const order = schedule.schedule.order
            const address = formatFullAddress(order.buildingNumber, order.street, order.postalCode)

            if (schedule) {
                const minutesDiff = differenceInMinutes(new Date(schedule.endDateTime), currDateTime)
                let _slotOccupied = Math.ceil(minutesDiff / multiplier)
                if ((dateTimeIdx + slotOccupied) > hoursLength) {
                    slotOccupied = hoursLength - dateTimeIdx
                }
                slotOccupied = _slotOccupied
            }


            const overlapCounts = schedule ? (schedule.overlapCounts || 0) : 0
            const isOverlapping = overlapCounts > 0 && schedule.schedule.completionDate == null && schedule.schedule.teamOnTheWayDate == null && schedule.schedule.teamStartDate == null && schedule.schedule.teamArrivalDate == null
            const height = `${(slotOccupied * 70) + (slotOccupied * 2) - 24}px`
            const width = 100 / (index + 1)
            const marginLeft = 100 - width
            const zIndex = index
            const cardColor = schedule.isPriority ? 'bg-[#FDC521]' : schedule?.schedule?.order?.invoices?.find((inv: any) => inv.isFirstInvoice === true)?.isPaidOnSite ? 'bg-[#ffb6c1]' : 'bg-white'
            const styles: CSSProperties = {
                height: height,
                width: `${width}%`,
                marginLeft: `${marginLeft}%`,
                zIndex: zIndex
            }

            const handleClickSchedule = (e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                if (e.button == 0 && schedule.handleClick) {
                    setFreezeTooltip(false)
                    schedule.handleClick(schedule.startDateTime)
                }
                if (e.button == 2) {
                    setFreezeTooltip(true)
                }
            }

            if (schedule && isEqual(new Date(schedule.startDateTime), currDateTime)) {
                return (
                    <div id={`JID${schedule.schedule.id}`} onMouseOver={() => setTooltipObject({ schedule, address, isOverlapping })} onMouseLeave={() => freezeTooltip === false && setTooltipObject(undefined)} className={`absolute overflow-hidden ${cardColor} rounded-lg border-2 border-neutral group`} style={styles}>
                        {schedule.children}
                        {
                            schedule && schedule.handleClick ?
                                <>
                                    <button className="absolute top-0 left-0 z-10 w-full h-full" type="button" onClick={handleClickSchedule}></button>
                                    <div className="top-2 right-2 absolute">
                                        {isOverlapping ?
                                            <SchedClashedIcon size={'1.5rem'} shadow={false} /> :
                                            <SchedStatusIcon schedule={schedule.schedule} size="1.5rem" />
                                        }
                                    </div>
                                </>
                                : <></>
                        }
                    </div>
                )
            }
        })}

    </div>
}