import { useState, useCallback, useEffect } from 'react'
import { useModal, useTable, CanAccess } from "@refinedev/core"
import { useForm } from "@refinedev/react-hook-form"
import { ZONES_CONFIG } from 'resources-config/zones-config'

import { MultiSelectField, TextField } from 'components/forms'
import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import PageTitle from 'components/PageTitle'
import { BaseCard } from 'components/cards'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import { Button } from 'components/buttons'
import { FilterIcon } from 'components/Svg'
import useTableQuery from 'utils/useTableQuery'
import { handleModalMutation } from 'utils/handle-mutation'
import { Modal } from 'components/popups'
import Pill from 'components/Pill'
import { Link } from 'react-router-dom'
import { baseApi } from 'providers/customDataProvider'

type FieldType = {
    name: string
    postalSectors: string
}

export default function ListZone() {
    const createZoneModal = useModal();
    const editZoneModal = useModal();
    const [editedZoneId, setEditedZoneId] = useState<number>(0)

    const { tableQueryResult, filters, setFilters, pageSize, current, setCurrent, sorters, setSorters } = useTable({
        queryOptions: { retry: false, },
        // pagination: { current: 1, pageSize: 20 },
        resource: ZONES_CONFIG.name
    })
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const { control, doFilter } = useTableQuery(filters, setFilters)

    const formatFields: { [key: string]: any } = (data: FieldType) => {
        return ({
            ...data, postalSectors: data.postalSectors.split(',').map(code => ({
                code: code
            }))
        })
    }



    return (<>
        <PageTitle />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft>
                    <TextField placeholder="Search by name" className='w-full' control={control}
                        fieldName='search' handleEnter={doFilter}
                    />
                </TabletWidgetLeft>
                <TabletWidgetRight>
                    <CanAccess resource={ZONES_CONFIG.name} action='create'>
                        <Button onClick={() => { createZoneModal.show() }}>Add Zone</Button>
                    </CanAccess>
                </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    {/* <Th sorting={{ column: 'id', sorters: sorters, setSorters: setSorters }}>ID</Th> */}
                    {/* <Th sorting={{ column: 'name', sorters: sorters, setSorters: setSorters }}>Name</Th> */}
                    <Th>ID</Th>
                    <Th>Name</Th>
                    <Th>Assigned to</Th>
                    <Th>Postal Sectors</Th>
                    <Th>Actions</Th>
                </THead>
                {!tableQueryResult.data ? <></> : tableQueryResult.data.data.map((zone, index) => (
                    <TBody key={index}>
                        <Td>{zone.id}</Td><Td>{zone.name}</Td>
                        <Td>
                            <div className='flex flex-wrap gap-1.5'>
                                {zone.techTeamZones.length > 0 ? zone.techTeamZones.map((ttz: any) => {
                                    return <Link to={`/technician-teams/edit/${ttz.team.id}`}>
                                        <Pill backgroundColor={`${ttz.team.colorHex}33`} textColor={ttz.team.colorHex} className='justify-center'>
                                            {ttz.team.name}
                                        </Pill>
                                    </Link>
                                }) : '--'}
                            </div>

                        </Td>
                        <Td>{(zone.postalSectors || []).map((sector: any) => sector.code).join(', ') || '--'}</Td>
                        <TAction id={`edit-zone-${zone.id}`}>
                            <TActionItem access={{ resource: ZONES_CONFIG.name, action: 'edit' }}>
                                <button type="button" onClick={() => {
                                    setEditedZoneId(zone && zone.id ? +zone.id : 0);
                                    editZoneModal.show()
                                }}>
                                    Edit
                                </button>
                            </TActionItem>
                        </TAction>
                    </TBody>
                ))}
            </Table>
            {/* <div className='justify-end mt-4 flex-ct-y'>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                    clickHandler={changePage} range={5}
                />
            </div> */}
            <CanAccess resource={ZONES_CONFIG.name} action='create'>
                <CreateZoneModal modal={createZoneModal} formatFields={formatFields} />
            </CanAccess>
            <CanAccess resource={ZONES_CONFIG.name} action='edit'>
                <EditZoneModal modal={editZoneModal} zoneId={editedZoneId} formatFields={formatFields} />
            </CanAccess>
        </BaseCard>
    </>)
}

const CreateZoneModal = (
    { modal, formatFields }:
        { modal: RefineModal, formatFields: any }
) => {
    const [btnDisabled, disableBtn] = useState(false)
    const [teamOptions, setTeamOptions] = useState([])

    const {
        refineCore: { onFinish, mutationResult }, handleSubmit, reset, control,
        setError, clearErrors, resetField, watch
    } = useForm<FieldType>({
        refineCoreProps: {
            resource: ZONES_CONFIG.name,
            action: 'create', onMutationSuccess: () => {
                reset()
                modal.close()
            }
        },
    });

    useEffect(() => {
        baseApi.get(`/admin/technician-teams`)
            .then((data) => setTeamOptions(data.data.list.map((team: any) => ({ value: +team.id, label: team.name }))))
            .catch(err => setTeamOptions([]))
    }, [])

    const onFinishHandler: any = (data: FieldType) => {
        onFinish(formatFields(data));
    }

    useEffect(() => {
        handleModalMutation(mutationResult, setError, clearErrors, modal.close, disableBtn, resetField)
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close, resetField])

    return (
        <Modal heading={'Create Zone'} visible={modal.visible} close={modal.close} width='30rem'>
            <form onSubmit={handleSubmit(onFinishHandler)} className="flex flex-col gap-4">
                <TextField control={control} fieldName='name' label={'Name'} required />
                <TextField control={control} fieldName='postalSectors' label={'Postal Sectors'}
                    desc='First 2 digits of postal codes, separated by comma e.g. 01,22'
                />
                <MultiSelectField filterOptions={false} label={'Teams'} options={teamOptions} control={control} fieldName='teams' />
                <div className='justify-end mt-1 flex-ct-y'>
                    <Button type='submit' className='text-th-xs' isLoading={btnDisabled}>Submit</Button>
                </div>
            </form>
        </Modal>
    )
}

const EditZoneModal = (
    { modal, zoneId, formatFields }:
        { modal: RefineModal, zoneId: number, formatFields: any }
) => {
    const [btnDisabled, disableBtn] = useState(false)
    const [defaultValues, setDefaultValues] = useState<{ value: any, label: string }[]>()
    const [teamOptions, setTeamOptions] = useState<{ value: any, label: string }[]>()
    const [showForm, setShowForm] = useState(false)
    const {
        refineCore: { onFinish, mutationResult, queryResult }, handleSubmit, setValue,
        control, setError, clearErrors, watch
    } = useForm<FieldType>({
        refineCoreProps: {
            resource: ZONES_CONFIG.name,
            action: !zoneId ? 'create' : 'edit', id: zoneId || 0,
            onMutationSuccess: () => {
                modal.close()
            },
        },
    });

    useEffect(() => {
        baseApi.get(`/admin/technician-teams`)
            .then((data) => setTeamOptions(data.data.list.map((team: any) => ({ value: +team.id, label: team.name }))))
            .catch(err => setTeamOptions([]))
    }, [])

    const onFinishHandler: any = (data: FieldType) => {
        onFinish(formatFields(data));
    }

    useEffect(() => {
        if (!modal.visible) {
            setDefaultValues(undefined)
            setShowForm(false)
        }
    }, [modal.visible])


    useEffect(() => {
        handleModalMutation(mutationResult, setError, clearErrors, modal.close, disableBtn)
    }, [mutationResult, setError, clearErrors, disableBtn])

    useEffect(() => {
        if (queryResult && queryResult.data) {
            const data: MiscObj = queryResult.data.data
            if (data.id != zoneId) return
            const sectors: any = (data.postalSectors || []).map((sector: any) => sector.code).join(',')
            setValue('postalSectors', sectors)
            if (!showForm && modal.visible) {
                setDefaultValues((data.techTeamZones || []).map((ttz: any) => ({ value: ttz.team.id, label: ttz.team.name })))
                setShowForm(true)
            }
        }
    }, [queryResult])

    return (
        <Modal heading={'Edit Zone'} visible={modal.visible} close={modal.close} width='30rem' renderChildOnHidden>
            <form onSubmit={handleSubmit(onFinishHandler)} className="flex flex-col gap-4 text-th-sm">
                <TextField control={control} fieldName='name' label={'Name'} required />
                <TextField control={control} fieldName='postalSectors' label={'Postal Sectors'}
                    desc='First 2 digits of postal codes, separated by comma e.g. 01,22'
                />
                {teamOptions && defaultValues && showForm &&
                    <MultiSelectField filterOptions={false} defaultCfg={{ setValue, value: defaultValues }} label={'Teams'} options={teamOptions} control={control} fieldName='teams' />
                }
                <div className='justify-end mt-1 flex-ct-y'>
                    <Button type='submit' className='text-th-xs' isLoading={btnDisabled}>Submit</Button>
                </div>
            </form>
        </Modal>
    )
}

