import PageTitle from "components/PageTitle";
import { useState, useEffect } from "react";
import { QUOTATION_CONFIG } from "resources-config/quotation/quotation-config";
import { getSuccessNotif, getErrorNotif } from "utils/data-formatter";
import { handleMutation } from "utils/handle-mutation";
import FormFieldQuotation from "./FormFieldQuotation";
import { useForm } from "@refinedev/react-hook-form";
import { useFieldArray } from "react-hook-form";
import { isArray } from "lodash";
import { baseApi } from "providers/customDataProvider";
import { format, isAfter, isBefore } from "date-fns";
import { toast } from "sonner";


export default function EditQuotation() {
    const [btnDisabled, disableBtn] = useState(false)

    const {
        refineCore: { onFinish, mutationResult, queryResult }, formState: { errors }, saveButtonProps: { disabled }, control, clearErrors, setError,
        watch, setValue, handleSubmit, resetField, reset,
    } = useForm<MiscObj>({
        refineCoreProps: {
            resource: QUOTATION_CONFIG.name,
            action: 'edit',
            queryOptions: {
                cacheTime: 0
            },
            successNotification(data) {
                let actionUrl = undefined
                if (data && data.data) {
                    actionUrl = `/quotations/${data.data.id}`
                }
                return getSuccessNotif('update', 'quotations', actionUrl)
            },
            errorNotification(data) {
                return getErrorNotif('update', 'quotations', data)
            },
        },
    });

    const [showForm, setShowForm] = useState<boolean>(false)

    const itemFieldArr = useFieldArray({ control: control, name: 'quotationItemCarts' })
    const discountFieldArr = useFieldArray({ control: control, name: 'discounts' })

    useEffect(() => {
        if (!queryResult || !queryResult.data || showForm) return
        Object.entries(queryResult.data.data).forEach(([key, value]: any) => {
            if (key == 'user') {
                setValue('name', value.name)
                setValue('phoneNumber', value.phoneNumber)
                setValue('email', value.email)
                return
            }

            if (key == 'discount' && parseFloat(value) != 0) {
                setValue('discounts', [{ description: queryResult.data.data.discountDescription, amount: parseFloat(value), type: 1, total: parseFloat(value) }])
                return
            }

            if (key == 'discountDescription' || key == 'id' || key == 'createdAt' || key == 'updatedAt') return

            if (key == 'quotationItemCarts') {
                const formattedItems = value.map((item: any) => ({
                    quotationItemId: +item.quotationItem.id,
                    name: item.quotationItem.name,
                    description: item.description,
                    quantity: item.quantity,
                    pricePerUnit: item.pricePerUnit
                }))
                setValue(key, formattedItems)
                return
            }

            if (key == 'validUntil' || key == 'quotationDate') {
                setValue(key, format(value, 'yyyy-MM-dd'))
                return
            }

            setValue(key, value)
        })
        setShowForm(true)
    }, [queryResult])

    const onFinishHandler: any = handleSubmit(async (data: any) => {
        try {
            const discountObj = data.discounts[0]
            const discount = (discountObj && discountObj.total) ? +discountObj.total : 0
            const discountDescription = (discountObj && discountObj.description) ? discountObj.description : ''
            const formattedItems: any[] = []

            const { quotationDate, validUntil } = data
            if (isAfter(quotationDate, validUntil)) {
                setError('quotationDate', { message: 'Quotation date must be before Valid Until date' })
                setError('validUntil', { message: 'Valid Until date must be after Quotation date' })
                return
            }
            if (isBefore(validUntil, new Date())) {
                setError('validUntil', { message: 'Valid Until date must be after today' })
                return
            }

            if (!data.quotationItemCarts || !isArray(data.quotationItemCarts)) return

            await Promise.all(data.quotationItemCarts.map(async (item: any) => {
                if (item.quotationItemId == -1) {
                    const createdItemRes = await baseApi.post(`/admin/quotations/items`, {
                        ...item, pricePerUnit: +item.pricePerUnit, quantity: +item.quantity
                    })
                    const createdItem = createdItemRes.data
                    formattedItems.push({
                        quotationItemId: +createdItem.id,
                        description: createdItem.description,
                        pricePerUnit: +createdItem.pricePerUnit,
                        quantity: +item.quantity
                    })
                } else {
                    formattedItems.push({
                        quotationItemId: +item.quotationItemId,
                        description: item.description,
                        pricePerUnit: +item.pricePerUnit,
                        quantity: +item.quantity
                    })
                }
            }))

            onFinish({
                ...data,
                discount,
                discountDescription,
                quotationItemCarts: formattedItems,
                latitude: parseFloat(data.latitude),
                longitude: parseFloat(data.longitude)
            })
        } catch (err: any) {
            if (err.message && err.message[0]) {
                toast.error(err.message[0])
                return
            }
            toast.error('Something wrong happened')

        }

    })




    useEffect(() => {
        handleMutation(mutationResult, setError, clearErrors, disableBtn)
    }, [mutationResult, setError, clearErrors, disableBtn])

    return (<>
        <PageTitle title="Edit Quotation" />
        {showForm &&
            <FormFieldQuotation
                control={control}
                watch={watch}
                resetField={resetField}
                setValue={setValue}
                formId="edit-quotation"
                onFinishHandler={onFinishHandler}
                isFetching={btnDisabled}
                cardHeading="Edit Quotation"
                discountFieldArr={discountFieldArr}
                itemFieldArr={itemFieldArr}
                isEdit
            />
        }
    </>)
}

