import { baseApi } from "providers/customDataProvider"


export const getItemRequests = async () => {
    const res = await baseApi.get(`/admin/service-orders/item-requests`)
    const itemRequests = res.data
    return itemRequests
}

export const acceptItemRequest = async (itemId: string) => {
    const res = await baseApi.post(`/admin/service-orders/item-requests/${itemId}/accept`)
    return res.data.message == 'Success'
}

export const rejectItemRequest = async (itemId: string) => {
    const res = await baseApi.post(`/admin/service-orders/item-requests/${itemId}/reject`)
    return res.data.message == 'Success'
}

export const postItemRequest = async (body: MiscObj) => {
    const res = await baseApi.post('/admin/service-orders/item-requests', body)
    return res.data.message == 'Success'
}

export const cancelItemRequest = async (itemId: string) => {
    const res = await baseApi.post(`/admin/service-orders/item-requests/${itemId}/cancel`)
    return res.data.message == 'Success'
}
