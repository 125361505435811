import { baseApi } from "providers/customDataProvider"


export const postReschedule = async (body: any) => {
    const res = await baseApi.post(`/admin/service-schedules/reschedule`, body)
    const message = res.data.message
    return (message == 'Success')
}

export const postAddSchedule = async (body: any) => {
    const res = await baseApi.post(`/admin/service-schedules`, body)
    const message = res.data.message
    return (message == 'Success')
}

export const getLateTechSchedules = async () => {
    const res = await baseApi.get('/admin/service-schedules/tech-late')
    return res.data || []
}

export const getServiceReportDocument = async (reportId: number): Promise<any> => {
    try {
        const res = await baseApi.get(`/admin/documents/service-reports/${reportId}`, { responseType: 'blob' })
        const data = res.data
        return data
    }
    catch (err) {
        console.log(err)
        throw err
    }
}

export const postResetScheduleTask = async (scheduleId: number, scheduleTaskId: number): Promise<any> => {
    try {
        const res = await baseApi.post(`/admin/service-schedules/${scheduleId}/schedule-tasks/${scheduleTaskId}/reset`)
        return (res.data.message === 'Success')
    } catch (err) {
        console.log(err)
    }
}

export const getIncompleteTasks = async () => {
    try{
        const res = await baseApi.get(`/admin/service-schedules/incomplete-tasks`)
        return res.data
    }
    catch (err){
        console.log(err)
    }
}