import { CustomerIcon, UsersIcon } from "components/Svg"
import CreateUser from "components/pages/users/CreateUser"
import EditUser from "components/pages/users/EditUser"
import ListUser from "components/pages/users/ListUser"
import ShowUser from "components/pages/users/ShowUser"
import { ResourceType } from "resources-config"

export const USERS_CONFIG: ResourceType = {
    name: 'users', meta: { icon: <CustomerIcon />, label: 'Customers', topLabelDivider: 'Main' },
    actions: {
        list: {
            path: 'users', page: <ListUser />,
            allowedRoles: ['super admin']
        },
        create: {
            path: 'users/create', page: <CreateUser />,
            allowedRoles: ['super admin']
        },
        edit: {
            path: 'users/edit/:id', page: <EditUser />,
            allowedRoles: ['super admin']
        },
        show: {
            path: 'users/:id', page: <ShowUser />,
            allowedRoles: ['super admin']
        }
    }
}

