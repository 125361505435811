import { useModalReturnType } from "@refinedev/core"
import { Button } from "components/buttons"
import { AsyncMultiSelectField } from "components/forms"
import { Modal } from "components/popups"
import { baseApi } from "providers/customDataProvider"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "sonner"


type Props = {
    modal: useModalReturnType
    targetItem: MiscObj
    refetchOrder: () => void
}

const UpsellTechModal = (props: Props) => {
    const { control, setValue, getValues, handleSubmit, watch, } = useForm()
    const { modal, targetItem, refetchOrder } = props
    const [defaultValueLeaders, setDefaultValueLeaders] = useState<SelectMultiVal | undefined | null>(undefined)
    const [defaultValueAssistants, setDefaultValueAssistants] = useState<SelectMultiVal | undefined | null>(undefined)

    const technicians = watch()

    const selectedTechnicians = useMemo(() => {
        const leaderTechnicians = technicians.leaderTechnicians || []
        const assistantTechnicians = technicians.assistantTechnicians || []
        return [...leaderTechnicians, ...assistantTechnicians]
    }, [technicians])

    const getTechnicianOptions = async (search: string) => {
        const { data } = await baseApi.get(`/admin/technicians?search=${search}`)
        return data.list.map((tech: MiscObj) => ({
            value: +tech.id, label: `${tech.name}${tech.vrn ? ` - ${tech.vrn}` : ''}`
        }))
    }

    const promiseOptions = (inputValue: string) => new Promise<any[]>((resolve) => {
        resolve(getTechnicianOptions(inputValue))
    })

    useEffect(() => {
        if (!targetItem) return
        const upsellTechnicians: MiscObj[] = targetItem.itemUpsellTechnicians
        if (!upsellTechnicians || upsellTechnicians.length == 0) {
            setDefaultValueAssistants([])
            setDefaultValueLeaders([])
            return
        }
        else {
            setDefaultValueAssistants(
                upsellTechnicians.filter((at: any) => at.role != 1 && at.technician != null).map((val: MiscObj) => ({ value: +val.technician.id, label: `${val.technician.name}${val.technician.vrn ? ` - ${val.technician.vrn}` : ''}` }))
            )
            setDefaultValueLeaders(
                upsellTechnicians.filter((at: any) => at.role == 1 && at.technician != null).map((val: MiscObj) => ({ value: +val.technician.id, label: `${val.technician.name}${val.technician.vrn ? ` - ${val.technician.vrn}` : ''}` }))
            )
        }
    }, [targetItem])

    const saveUpsellTechnicians = handleSubmit(async (data) => {
        const { leaderTechnicians, assistantTechnicians } = data
        if (!targetItem) return
        try {
            const res = await baseApi.patch(`/admin/service-orders/${targetItem.order.id}/items/${targetItem.id}/upsell-technicians`, {
                upsellTechnicians: [...leaderTechnicians.map((techId: any) => ({ id: +techId, role: 1 })), ...assistantTechnicians.map((techId: any) => ({ id: +techId, role: null }))]
            })
            if(res.data.message === 'Success'){
                toast.success('Successfully updated upsell technicians')
                modal.close()
            }
        } catch (err: any) {
            toast.error(err.message)
            console.log(err)
        } finally{
            refetchOrder()
        }
    })

    return <Modal heading={'Upselling Technicians'} visible={modal.visible} close={modal.close} width="30rem">
        <div className="flex flex-col gap-6">
            <div>
                Edit the technician who Upselling the service
            </div>
            {
                (defaultValueAssistants && defaultValueLeaders) ?
                    <>
                        <AsyncMultiSelectField
                            control={control}
                            fieldName="leaderTechnicians" loadOptions={promiseOptions}
                            label={'Team Leaders'}
                            defaultCfg={{
                                value: defaultValueLeaders,
                                setValue: setValue
                            }}
                            filterOption={({ value }) => !selectedTechnicians.includes(value)}
                        />
                        <AsyncMultiSelectField
                            control={control}
                            fieldName="assistantTechnicians" loadOptions={promiseOptions}
                            label={'Assistants'}
                            defaultCfg={{
                                value: defaultValueAssistants,
                                setValue: setValue
                            }}
                            filterOption={({ value }) => !selectedTechnicians.includes(value)}
                        />
                    </>
                    : <></>
            }
            <Button confirm onClick={saveUpsellTechnicians}>Save</Button>
        </div>
    </Modal>
}

export default UpsellTechModal