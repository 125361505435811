import { format } from "date-fns"
import { stringify } from "query-string"
import { getFilterValue } from "./data-formatter"
import { CrudFilters } from "@refinedev/core"

export const buildDailyScheduleQuery = (date: any = new Date(), scheduleId?: string, orderId?: string, moveSchedule?: string, teams?: string[]) => {
    const query = stringify({
        'filters[0][field]': 'tzoffset',
        'filters[0][operator]': 'eq',
        'filters[0][value]': 8,
        'filters[1][field]': 'startdate',
        'filters[1][operator]': 'eq',
        'filters[1][value]': format(date, 'yyyy-MM-dd'),
        ...buildCalendarInteractionQuery(scheduleId, orderId, moveSchedule, teams)
    })
    return query
}

export const buildCalendarInteractionQuery = (scheduleId?: string, orderId?: string, moveSchedule?: string, teams?: string[]) => {
    return {
        'filters[3][field]': scheduleId && 'schedule',
        'filters[3][operator]': scheduleId && 'eq',
        'filters[3][value]': scheduleId,
        'filters[4][field]': orderId && 'order',
        'filters[4][operator]': orderId && 'eq',
        'filters[4][value]': orderId,
        'filters[5][field]': moveSchedule && 'moveSchedule',
        'filters[5][operator]': moveSchedule && 'eq',
        'filters[5][value]': moveSchedule,
        'filters[6][field]': teams && 'teams',
        'filters[6][operator]': teams && 'eq',
        'filters[6][value]': teams,
    }
}

export const scrollToTargetAdjusted = (id: string, offset = 45, scrollElementId?: string) => {
    var element = document.getElementById(id);
    const scrollElement = scrollElementId ? document.getElementById(scrollElementId) : undefined
    if (!element) return false
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + (scrollElement ? scrollElement.scrollTop : window.scrollY) - offset;
    element.classList.add('animate-pulse')
    element.classList.add('border-primary')
    element.classList.add('border-2')

    setTimeout(() => {
        if (element) {
            element.classList.remove('animate-pulse')
            element.classList.remove('border-primary')
            element.classList.remove('border-2')
        }
    }, 5000);


    (scrollElement || window).scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
    return true
}

export const getInteractionFilters = (filters: CrudFilters) => {
    const scheduleId = getFilterValue(filters, 'schedule')
    const orderId = getFilterValue(filters, 'order')
    const moveSchedule = getFilterValue(filters, 'moveSchedule')
    const teams = getFilterValue(filters, 'teams')
    return { scheduleId, orderId, moveSchedule, teams }
}

export const getJobTypeColor = (type: string) => {
    let backgroundColor = ''
    let textColor = ''

    switch (type) {
        case 'Service':
            backgroundColor = 'rgba(219, 123, 0, 0.15)'
            textColor = '#DB7B00'
            break
        case 'Contract':
            backgroundColor = 'rgba(255, 226, 39, 0.15)'
            textColor = '#A28C00'
            break
        case '':
            backgroundColor = 'rgba(52, 152, 219, 0.15)'
            textColor = '#3498DB'
            break
        case '':
            backgroundColor = 'rgba(100, 42, 182, 0.15)'
            textColor = '#642AB6'
            break
        default:
            break
    }



    return { backgroundColor, textColor }
}