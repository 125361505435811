import { isBefore } from "date-fns";


export const getFirstInvoice = (order?: MiscObj) => {
    if (!order) {
        return null;
    }
    const firstInvoice = order.invoices.find((invoice: MiscObj) => invoice.isFirstInvoice)
    if (firstInvoice) {
        return firstInvoice
    }
    return null
}

export const getAmountDue = (invoices: MiscObj[]) => {
    return invoices.map((invoice: any) => invoice.amountDue).reduce((acc: any, current: any) => acc + current, 0)
}

export const getClosestDueDate = (invoices: MiscObj[]) => {
    if (!invoices || invoices.length == 0) return null
    let dueDate: string | null = null;
    invoices.forEach((invoice: any) => {
        if (!dueDate) {
            if (invoice.paidAt != null) return
            dueDate = invoice.expiredAt
            return
        }
        if (isBefore(invoice.expiredAt, dueDate) && invoice.paidAt == null) dueDate = invoice.expiredAt
    })

    return dueDate
}

export const getScheduleStatusColor = (item: MiscObj, schedule?: MiscObj) => {
    let status = '';
    let badgeBackgroundColor = '';
    let backgroundColor = '';
    let badgeTextColor = '';
    let badgeBorderColor = 'transparent'

    try {
        if (item.rejectedAt && !item.approvedAt) {
            status = 'Rejected'
            badgeBackgroundColor = 'transparent'
            badgeTextColor = '#CB3A31'
            backgroundColor = '#FFF4F2'
            badgeBorderColor = '#CB3A31'
        }
        else if (item.rejectedAt && item.approvedAt) {
            status = 'Cancelled'
            badgeBackgroundColor = 'transparent'
            badgeTextColor = '#CB3A31'
            backgroundColor = '#FFF4F2'
            badgeBorderColor = '#CB3A31'
        }
        else if (!schedule) {
            status = 'Unscheduled'
            badgeBackgroundColor = 'transparent'
            badgeTextColor = '#5B4407'
            backgroundColor = '#FFF9F2'
            badgeBorderColor = '#EECEB0'
        }
        else if (schedule.completionDate) {
            status = 'Done'
            badgeBackgroundColor = '#00A19A'
            badgeTextColor = '#FFFFFF'
            backgroundColor = 'transparent'
        }
        else if (schedule.startDate) {
            status = 'On Schedule'
            badgeBackgroundColor = '#EBF1F9'
            badgeTextColor = '#1954A1'
            backgroundColor = 'transparent'
        }
    }
    catch (err) {
        console.log(err)
    } finally {
        return { status, badgeBackgroundColor, badgeTextColor, backgroundColor, badgeBorderColor }
    }


}

export const calculateItemFinalVbPoints = (item: MiscObj, price: number) => {
    const lsp = item.lowestSellingPrice
    const pp = item.pushPoint || 0
    const commission = item.technicianCommission || 0
    let priceLspDiv = (price / 1.09) - lsp
    priceLspDiv = Math.max(priceLspDiv, 0)
    const finalVbPoints = IDIOT_ROUNDING(priceLspDiv * (commission / 100) + pp)

    return finalVbPoints
}


const IDIOT_ROUNDING = (number: number): number => {
    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = number.toString().split('.');

    // Check if the decimal part has at least one digit
    if (decimalPart && decimalPart.length > 2) {
        // Extract the first digit of the decimal part
        const lastDecimal = parseInt(decimalPart[decimalPart.length - 1]);

        // Check if the first decimal digit is 6 or more
        if (lastDecimal >= 6) {
            // Round up
            return Number(number.toFixed(2));
        } else {
            // Round down
            return Number(`${integerPart}.${decimalPart.slice(0, 2)}`);
        }
    } else {
        // If no decimal part, return the number itself (which is already an integer)
        return number;
    }
}