import { useCallback } from "react"
import { useController } from "react-hook-form"

interface CheckboxProps {
    fieldName: string,
    control: FormHookType['control'],
    fieldArr: FormHookType['fieldArr']
    cbVal: any,
    label?: JSX.Element | string
    className?: string,
    [attrName: string]: any
    shape?: 'square' | 'switch'
}

export default function Checkbox({
    fieldName, control, fieldArr, cbVal, label, className, shape = 'square', ...rest
}: CheckboxProps) {
    let classes = 'multi-choice checkbox' + (className ? ' ' + className : '')
    const { field: { value, ref } } = useController({ name: fieldName, control });

    const toggleValue = useCallback((oldVal: any[]) => {
        if (oldVal && oldVal.includes(cbVal)) {
            fieldArr.remove(oldVal.indexOf(cbVal))
        }
        else {
            fieldArr.append(cbVal)
        }
    }, [cbVal, fieldArr])

    return (
        shape == 'square' ?
            <label className={classes}>

                <span className='choice-name'>{label || cbVal}</span>
                <input
                    {...rest} type='checkbox' value={cbVal}
                    onChange={() => toggleValue(value)}
                    checked={value && value.includes(cbVal) ? true : false}
                    ref={ref}
                />
                <div>
                    <span className="checkmark">
                        <span></span>
                    </span>
                </div>
            </label> :
            <div>
                <label className="inline-flex items-center cursor-pointer gap-2">
                    <input type="checkbox" className="sr-only peer"
                        {...rest} value={cbVal}
                        onChange={() => toggleValue(value)}
                        checked={value && value.includes(cbVal) ? true : false}
                        ref={ref}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full dark:bg-gray-300 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
                    <span className="text-light">{label}</span>
                </label>
            </div>
    )
}
