import { useModalReturnType } from "@refinedev/core"
import { getServiceReportDocument } from "api/service-schedules"
import Modal from "components/popups/Modal"
import { Dispatch, SetStateAction } from "react"
import { formatFullDate } from "utils/date"


type Props = {
    modal: useModalReturnType
    schedules: MiscObj[]
    user: MiscObj
    setActiveReportUrl: Dispatch<SetStateAction<string | undefined>>
    openActiveReportModal: () => void
}

const ServiceReportModal = (props: Props) => {
    const { modal, schedules, user } = props

    const handleDownloadServiceReport = async (report: MiscObj) => {
        if (!report?.id) return;
        const pdfUrl = `${process.env.REACT_APP_API_BASE_URL}/admin/documents/service-reports/${report.id}`;
        window.open(pdfUrl, '_blank');
    };
    

    return <Modal visible={modal.visible} close={modal.close} heading={'Service Reports'} width="30rem" drawerView bodyClassName="flex flex-col gap-3 text-theme-sm mb-4">
        <div className="space-y-4">
            {schedules.sort((a, b) => a.period - b.period).map((sched, index) =>
                <>
                    {sched.report ?
                        <button type="button" onClick={() => handleDownloadServiceReport(sched.report)} className="w-full p-4 border border-primary-2-surface rounded-lg flex flex-col gap-1.5 hover:border-accent">
                            <span className="font-medium">Appointment {sched.period + 1} ∙ <span className="text-body">JID {sched.id}</span></span>
                            {sched.report ?
                                <span className="text-light text-theme-xs">{formatFullDate(sched.report.createdAt)}</span> :
                                <span className="text-light text-theme-xs">N/A</span>
                            }
                        </button> :
                        <button disabled className="w-full p-4 border border-primary-2-surface rounded-lg flex flex-col gap-1.5 hover:border-accent">
                            <span className="font-medium">Appointment {sched.period + 1} ∙ <span className="text-body">JID {sched.id}</span></span>
                            {sched.report ?
                                <span className="text-light text-theme-xs">{formatFullDate(sched.report.createdAt)}</span> :
                                <span className="text-light text-theme-xs">N/A</span>
                            }
                        </button>
                    }
                </>
            )}
        </div>
    </Modal>
}

export default ServiceReportModal