import { ReactNode } from "react"
import { formatFullDate } from "utils/date"
import TextTooltip from "./TextTooltip"
import { DisplayDbLog } from "components/texts"

const StatusTextTooltip = ({ children, statusText, values }: { children: ReactNode, statusText: string, values: MiscObj }) => {
    switch (statusText) {
        case 'Rejected':
            return <TextTooltip position="left" text={`Rejected by ${values.rejectingAdmin ? values.rejectingAdmin.name : 'System'} at ${values.rejectedAt ? formatFullDate(values.rejectedAt) : 'not found'}`}>
                {children}
            </TextTooltip>
        case 'On Schedule':
            return <>
                <TextTooltip position="left" text={`Scheduled at ${values.scheduleTask && values.scheduleTask.schedule ? formatFullDate(values.scheduleTask.schedule.createdAt) : 'not found'}`}>
                    {children}
                </TextTooltip>
            </>
        case 'Done':
            return <TextTooltip position="left" text={`Completed ${values.scheduleFinisher} at ${values.scheduleTask && values.scheduleTask.schedule ? formatFullDate(values.scheduleTask.schedule.completionDate) : 'not found'}`}>
                {children}
            </TextTooltip>
        case 'Cancelled':
            return <TextTooltip position="left" text={`Cancelled by ${values.rejectingAdmin ? values.rejectingAdmin.name : 'System'} at ${values.rejectedAt ? formatFullDate(values.rejectedAt) : 'not found'}`}>
                {children}
            </TextTooltip>
        case 'Unscheduled':
            return <TextTooltip position="left" text={<DisplayDbLog dbLog={values?.itemDbLog} />}>
                {children}
            </TextTooltip>
        default:
            return <>
                {children}
            </>
    }
}

export default StatusTextTooltip