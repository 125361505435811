import { useController } from "react-hook-form"
import { BaseFormProps } from "./types"
import { ChangeEvent, useState } from "react"
import { CloseIcon, UploadIcon } from "components/Svg"
import { Button } from "components/buttons"

type PreviewType = {
    id?: string,
    url?: string,
    name: string
    isDefault: boolean
}

type Props = BaseFormProps & {
    defaultSrc?: { src: string, id: string }[]
    handleDeleteServer?: (id: string) => Promise<boolean>
    multiple?: boolean
}

const mapDefaultSrc = (formattedDefaultSrc?: { src: string, id?: string }[]) => {
    return formattedDefaultSrc && formattedDefaultSrc.map(src => ({
        url: src.src,
        name: '',
        isDefault: true,
        id: src.id
    })) || []
}

const UploadField = (props: Props) => {
    const { control, fieldName, label, defaultSrc = [], multiple = false, handleDeleteServer, ...rest } = props
    const formattedDefaultSrc: { src: string, id: string }[] = defaultSrc && defaultSrc.filter(def => def && def.src).map(def => ({ ...def, src: def.src.replace('https:/a', 'https://a') }))
    const [previewUris, setPreviewUris] = useState<PreviewType[]>(mapDefaultSrc(formattedDefaultSrc))

    const { field: { onChange, value }, } = useController({
        control: control,
        name: fieldName
    })

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (!files) return
        if (multiple == true) {
            const newFiles: PreviewType[] = []
            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                if (file)
                    newFiles.push({
                        url: URL.createObjectURL(file),
                        name: file.name,
                        isDefault: false
                    })

            }
            setPreviewUris(state =>
                [...state.filter(preview => preview.isDefault == true), ...newFiles]
            )
        }
        else {
            if (files[0])
                setPreviewUris([{
                    url: URL.createObjectURL(files[0]),
                    name: files[0].name,
                    isDefault: false
                }])
        }
        onChange(e.target.files)
    }

    const handleDelete = async (index: number, id?: string) => {
        let serverResult = true
        if (handleDeleteServer && id) {
            serverResult = await handleDeleteServer(id)
        }
        if (serverResult)
            setPreviewUris(state => state.filter((_, _index) => _index !== index))
    }

    return <div className="form-group">
        <label htmlFor="form-label">{label}</label>
        <div className="border-2 border-dashed rounded-lg relative min-h-[150px] py-6 grid place-items-center cursor-pointer">
            <input multiple={multiple} {...rest} type="file" onChange={handleChange} className="absolute w-full h-full opacity-0 cursor-pointer" {...rest} />
            {(previewUris && previewUris.length > 0) ?
                <div className="overflow-x-auto flex max-w-full items-center gap-8">
                    {previewUris.map((preview, index) =>
                        <div key={index} className="flex flex-col gap-1.5 items-center relative">
                            <div className="relative">
                                {(!preview.isDefault || (handleDeleteServer && preview.id)) &&
                                    <Button shape="raw" confirm={preview.isDefault} onClick={() => handleDelete(index, preview.id)} type="button" className="bg-white rounded-full p-1 absolute shadow-lg -top-2 -right-2 hover:bg-neutral-50 transition duration-300">
                                        <CloseIcon color="#000000" width={16} height={16} />
                                    </Button>
                                }
                                <img src={preview.url} alt="Preview" className="w-24 object-contain" />
                            </div>
                            <span className="max-w-[150px] overflow-hidden text-ellipsis">{preview.name}</span>
                        </div>
                    )}
                </div>
                :
                <div className="text-primary flex flex-col gap-2 items-center">
                    <div className="bg-primary-2-surface rounded-full w-10 h-10 flex items-center justify-center">
                        <UploadIcon />
                    </div>
                    Drop or Click here to upload
                </div>
            }
        </div>
    </div>
}

export default UploadField