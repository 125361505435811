import { Button } from "components/buttons"
import { TextField } from "components/forms"
import AuthLayout from "components/layout/AuthLayout"
import PageTitle from "components/PageTitle"
import { ForgotPasswordSuccessIcon, LoadIcon } from "components/Svg"
import { baseApi } from "providers/customDataProvider"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useNavigation, useSearchParams } from "react-router-dom"
import { toast } from "sonner"


const ResetPassword = () => {
    const [searchParams] = useSearchParams()
    const { control, handleSubmit, setError } = useForm()
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const navigation = useNavigate()

    const onSubmit = handleSubmit(async (data) => {
        const { confirmPassword, newPassword } = data
        const token = searchParams.get('token')
        if (confirmPassword != newPassword) { setError('confirmPassword', { message: 'Password confirmation does not match' }); return }
        if (!token) { toast.error('Token is invalid'); return }
        try {
            setIsLoading(true)
            await baseApi.post('/auth/admin/reset-password', {
                ...data,
                token: token
            })
            setIsSuccess(true)
            setTimeout(() => {
                navigation('/login')
            }, 3000);
        } catch (err: any) {
            console.log(err)
            toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    })

    return <>
        <PageTitle title="Reset Password" />
        <AuthLayout>
            {isSuccess ?
                <div className="flex flex-col gap-10 justify-center items-center">
                    <h1 className="text-th-xl font-bold text-center">Your new password is set!</h1>
                    <ForgotPasswordSuccessIcon />
                    <LoadIcon width={24} className='loading-icon' />
                </div> :
                <form onSubmit={onSubmit} className="flex flex-col justify-center gap-10">
                    <h1 className="text-th-xl font-bold">Set Your New Password</h1>
                    <TextField type="password" control={control} fieldName="newPassword" label={'New Password'} placeholder="Enter your new password" />
                    <TextField type="password" control={control} fieldName="confirmPassword" label={'Confirm Password'} placeholder="Repeat your new password" />
                    <Button isLoading={isLoading} type="submit" shape="filled">Continue</Button>
                </form>

            }
        </AuthLayout>
    </>
}

export default ResetPassword