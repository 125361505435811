import { Button } from "components/buttons"
import Modal from "../popups/Modal"

interface FilterModalProps{
    visible: boolean
    close: () => void
    resourceName: string,
    doFilter: () => void,
    children: any,
    [key: string]: any
}

export default function FilterModal({resourceName, doFilter, children, ...rest}: FilterModalProps){
    return (
        <Modal {...rest} heading={`Filter ${resourceName}`} width="28rem">
            {children}
            <div className="justify-end mt-4 flex-ct-y text-th-xs">
                <Button type='button' onClick={() => {doFilter(); rest.close()}}>Filter</Button>
            </div>
        </Modal>
    )
}