import { CanAccess, CrudSort, CrudSorting } from "@refinedev/core"
import { useCallback, useEffect, useMemo, useState } from "react"
import { PlayIcon } from "./Svg"
import { Dropdown } from "./dropdowns"
import { DDItem } from "./dropdowns/Dropdown"
interface TableProps {
    children: any,
    className?: string,
    [attrName: string]: any // HTML attributes  
}
export function Table({ children, className, ...rest }: TableProps) {
    const classes = 'table-container' + (className ? ' ' + className : '')
    const headings = useMemo(() => {
        if (!children) {
            return <></>
        }
        if (Array.isArray(children)) {
            let childArr = children.flat()
            return childArr.filter(child => child.props && child.props.identifier === 'thead')
        }
        else {
            if (children.props && children.props.identifier === 'thead') return children
            return <></>
        }
    }, [children])

    const body = useMemo(() => {
        if (!children) {
            return <></>
        }
        if (Array.isArray(children)) {
            let childArr = children.flat()
            return childArr.filter(child => child.props && child.props.identifier === 'tbody')
        }
        else {
            if (children.props && children.props.identifier === 'tbody') return children
            return <></>
        }
    }, [children])

    return (
        <div className={classes} {...rest}>
            <table>
                <thead>{headings}</thead>
                <tbody>{body}</tbody>
            </table>
        </div>
    )
}

interface TbodyProps {
    rows: JSX.Element[][] | string[][],
    className?: string,
    [attrName: string]: any // HTML attributes  
}
export function Tbody({ rows, className, ...rest }: TbodyProps) {
    const classes = (className ? className : '')
    return (
        <tbody className={classes} {...rest}>
            {rows.map((columns, index) => (
                <tr key={index}>
                    {columns.map((column, idx) => (
                        <td key={idx}>{column}</td>
                    ))}
                </tr>
            ))}
        </tbody>
    )
}

export function THead({ children }: { children: any }) {
    return (
        <tr>{children}</tr>
    )
}

THead.defaultProps = {
    identifier: 'thead'
}

export function TBody({ children }: { children: any }) {
    return (
        <tr>{children}</tr>
    )
}

TBody.defaultProps = {
    identifier: 'tbody'
}

interface ThType extends CellType {
    sorting?: {
        column: string
        sorters: CrudSorting,
        setSorters: (sorter: CrudSorting) => void
    }
}

export function Th({ children, className, sorting, ...rest }: ThType) {
    const [currentSort, setCurrentSort] = useState<CrudSort | undefined>(undefined)

    const doSort = useCallback(() => {
        if (sorting) {
            let { column, sorters, setSorters } = sorting
            let newSorters = [...sorters]
            let targetSorter: any = null
            let targetSorterIndex: any = null
            newSorters.forEach((sorter, index) => {
                if (!targetSorter && sorter.field === column) {
                    targetSorter = sorter
                    targetSorterIndex = index
                }
            })
            if (!targetSorter) {
                newSorters.push({ field: column, order: 'desc' })
            }
            else {
                if (targetSorter.order === 'desc') {
                    targetSorter.order = 'asc'
                    newSorters[targetSorterIndex] = targetSorter
                }
                else if (targetSorter.order === 'asc') {
                    newSorters.splice(targetSorterIndex, 1)
                }
            }
            setSorters(newSorters)
        }
    }, [sorting])

    useEffect(() => {
        if (sorting) {
            const { column, sorters } = sorting
            const columnSort = sorters.find(sorter => (
                sorter.field === column
            ))
            setCurrentSort(columnSort || undefined)
        }
    }, [sorting])
    let classes = '' + (className ? ` ${className}` : '')
    if (currentSort) {
        if (currentSort.order === 'desc') classes += ' desc'
        else classes += ' asc'
    }
    if (sorting) {
        return (
            <th className={classes} {...rest}>
                <button type="button" className="sort-btn" onClick={doSort}>
                    {children || <></>}
                    {
                        currentSort ?
                            <PlayIcon className='sort-icon' color="#1954A1" /> :
                            <PlayIcon className='sort-icon' color="#BFBFC3" />
                    }
                </button>
            </th>
        )
    }
    return <th className={classes} {...rest}>{children || <></>}</th>
}


export function Td({ children, className, ...rest }: CellType) {
    const classes = '' + (className ? ` ${className}` : '')
    return <td className={classes} {...rest}>{children || <></>}</td>
}

export function TAction({ id, children }: { id: string, children: any }) {
    return (
        <Td>
            <span className='gap-2 flex-ct-y'>
                <Dropdown id={id} toggleText='Action' color='gray' shape='outline' className='text-th-xs group'>
                    {children}
                </Dropdown>
            </span>
        </Td>
    )
}

export function TActionItem({ children, access }: { children: any, access?: { resource: string, action: string } }) {
    if (!access) {
        return (
            <DDItem className='text-th-sm'>
                {children}
            </DDItem>
        )
    }
    return (
        <CanAccess resource={access.resource} action={access.action}>
            <DDItem className='text-th-sm'>
                {children}
            </DDItem>
        </CanAccess>
    )
}

interface CellType {
    children?: any
    className?: string
    [attrName: string]: any // HTML attributes  
}