import { useEffect, useState } from 'react';

interface ScrollPosition {
  scrollXPosition: number;
  scrollYPosition: number;
}

const useScrollPosition = (elementId?: string): ScrollPosition => {
  const [scrollYPosition, setScrollYPosition] = useState(0);
  const [scrollXPosition, setScrollXPosition] = useState(0);

  useEffect(() => {
    const targetElement = elementId ? document.getElementById(elementId) : window;

    if (!targetElement) {
      console.warn(`Element with id "${elementId}" not found.`);
      return;
    }

    const handleScroll = () => {
      if (targetElement instanceof Window) {
        setScrollYPosition(window.scrollY);
        setScrollXPosition(window.scrollX);
      } else {
        setScrollYPosition(targetElement.scrollTop);
        setScrollXPosition(targetElement.scrollLeft);
      }
    };

    targetElement.addEventListener('scroll', handleScroll);

    return () => {
      targetElement.removeEventListener('scroll', handleScroll);
    };
  }, [elementId]);

  return { scrollXPosition, scrollYPosition };
};

export default useScrollPosition;
