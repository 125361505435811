import { useTable, CanAccess } from "@refinedev/core"
import { Button } from "components/buttons"
import { BaseCard } from "components/cards"
import PageTitle from "components/PageTitle"
import Pagination from "components/Pagination"
import { Table, TAction, TActionItem, TBody, Td, Th, THead } from "components/Table"
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from "components/TableWidgets"
import { useCallback } from "react"
import { NavLink } from "react-router-dom"

import { TECHNICIAN_TEAMS_CONFIG } from "resources-config/technician-teams-config"

export default function ListTechnicianTeam(){
    const {tableQueryResult, pageSize, current, setCurrent, sorters, setSorters} = useTable({
        queryOptions: {retry: false},
        resource: TECHNICIAN_TEAMS_CONFIG.name,
    })
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])    

    return (<>
        <PageTitle title="Technician Team"/>
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft></TabletWidgetLeft>
                <TabletWidgetRight>
                    <CanAccess resource={TECHNICIAN_TEAMS_CONFIG.name} action='create'>
                        <Button href='/technician-teams/create'>Add technician team</Button>
                    </CanAccess>   
                </TabletWidgetRight>
            </TabletWidgets>                  
            <Table>
                <THead>
                    <Th sorting={{column: 'id', sorters: sorters, setSorters: setSorters}}>ID</Th>
                    <Th sorting={{column: 'name', sorters: sorters, setSorters: setSorters}}>Name</Th>
                    <Th>Zones</Th>
                    <Th>Color</Th>
                    <Th>Actions</Th>
                </THead>
                {!tableQueryResult.data ? <></> : tableQueryResult.data.data.map((team, index) => (
                    <TBody key={index}>
                        <Td>{team.id}</Td>
                        <Td>{team.name}</Td>
                        <Td>{team.techTeamZones.length ? `${team.techTeamZones.length} zones` : '--'}</Td>
                        <Td>
                            <span className='inline-block w-6 h-6 rounded-full' style={{background: team.colorHex || 'transparent'}}>
                            </span>
                        </Td>                        
                        <TAction id={`edit-team-${team.id}`}>
                            <TActionItem access={{resource: TECHNICIAN_TEAMS_CONFIG.name, action: 'edit'}}>
                                <NavLink type='button' className='text-sm' to={`/technician-teams/edit/${team.id}`}>
                                    Edit                                        
                                </NavLink>                                     
                            </TActionItem>
                        </TAction>
                    </TBody>
                ))}
            </Table>    
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination 
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0} 
                    clickHandler={changePage} range={5}
                />           
            </div>               
        </BaseCard>     
    </>)
}

