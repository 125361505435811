import { useModal, useTable } from "@refinedev/core"
import { Button } from "components/buttons"
import { BaseCard } from "components/cards"
import { TextField } from "components/forms"
import PageTitle from "components/PageTitle"
import Pagination from "components/Pagination"
import { Table, THead, Th, TBody, Td, TAction, TActionItem } from "components/Table"
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from "components/TableWidgets"
import { useCallback, useState } from "react"
import { QUOTATION_ITEMS_CONFIG } from "resources-config/quotation/quotation-items-config"
import { formatFullDate } from "utils/date"
import useTableQuery from "utils/useTableQuery"
import CreateQuotationItemsModal from "./CreateQuotationItemsModal"
import EditQuotationItemsModal from "./EditQuotationItemsModal"
import { toast } from "sonner"
import { baseApi } from "providers/customDataProvider"


const ListQuotationItems = () => {
    const {
        tableQueryResult, pageSize, current, setCurrent, sorters, setSorters,
        filters, setFilters
    } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 20 },
        resource: QUOTATION_ITEMS_CONFIG.name,
        filters: {
            initial: [{ field: 'status', operator: 'eq', value: 'unconfirmed' }]
        }
    })
    const { control, doFilter } = useTableQuery(filters, setFilters)
    const createQuotationModal = useModal()
    const editQuotationModal = useModal()
    const [selectedItemId, setSelectedItemId] = useState<string>()

    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const handleImportItems = async () => {
        try {
            const importRes = await baseApi.post('/admin/quotations/import-services-to-quotation-items')
            const { itemCount } = importRes.data
            toast.success(`Succesfully imported ${itemCount} items`)
        } catch (err: any) {
            toast.error('Failed to import items. ' + err.message)
        } finally {
            tableQueryResult.refetch()
        }
    }

    return <>
        <PageTitle />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft>
                    <TextField
                        control={control} fieldName='search' placeholder="Search by ID or customer's name"
                        className='w-full' handleEnter={doFilter}
                    />
                </TabletWidgetLeft>
                <TabletWidgetRight>
                    <Button confirm onClick={handleImportItems} shape="outline">Import Items from Services</Button>
                    <Button onClick={createQuotationModal.show}>+ New Item</Button>
                </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    <Th>ID</Th>
                    <Th>Name</Th>
                    <Th>Created At</Th>
                    <Th>Created By</Th>
                    <Th>Description</Th>
                    <Th>Unit Measurement</Th>
                    <Th>Initial Price Per Unit</Th>
                    <Th>Action</Th>
                </THead>
                {/* @ts-ignore */}
                {tableQueryResult && tableQueryResult.data ? (tableQueryResult.data.data.data).map((item: any, index: number) =>
                    <TBody key={item.id}>
                        <Td>
                            {item.id}
                        </Td>
                        <Td>{item.name}</Td>
                        <Td>{formatFullDate(item.createdAt, { showDay: false, showTime: false })}</Td>
                        <Td>{item.creator ? item.creator.name : '--'}</Td>
                        <Td className="max-w-[250px]">{item.description}</Td>
                        <Td>{item.itemUnit ? item.itemUnit.name : '--'}</Td>
                        <Td>$ {parseFloat(item.pricePerUnit).toFixed(2)}</Td>
                        <TAction id={`item-${item.id}`}>
                            <TActionItem>
                                <button onClick={() => { setSelectedItemId(item.id); editQuotationModal.show() }}>Edit</button>
                            </TActionItem>
                        </TAction>
                    </TBody>
                )
                    : <></>
                }
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination
                    // @ts-ignore
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.data.totalItems || 0}
                    clickHandler={changePage} range={5}
                />
            </div>
        </BaseCard>
        <CreateQuotationItemsModal modal={createQuotationModal} />
        {selectedItemId &&
            <EditQuotationItemsModal modal={editQuotationModal} itemId={selectedItemId} />
        }
    </>
}

export default ListQuotationItems