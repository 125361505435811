import { useCallback, useEffect, useState } from 'react'
import { FieldValues, UseFormSetValue, useFieldArray } from 'react-hook-form'
import { Button } from "components/buttons"
import { CountryPhoneInput, FormsGrid, SelectField, TextField } from 'components/forms'
import { CloseCircleIcon } from 'components/Svg'
import { capitalizeStr } from 'utils/data-formatter'
import Accordion from 'components/Accordion'
import AddressLocation from 'components/location-picker/AddressLocation'
import FindAddressPopup from 'components/location-picker/FindAddressPopup'
import PinpointPopup from 'components/location-picker/PinpointPopup'
import { useModal } from '@refinedev/core'
import { PlaceInterface } from 'utils/map'
import { formatBuildingAddress, formatCountry } from 'utils/address'
import { flatten } from 'lodash'

interface FormFieldUserType {
    control: FormFieldType['control']
    handleSubmit: any
    onFinishHandler: any
    formId: string
    queryResult: any
    watch: FormHookType['watch']
    setValue: UseFormSetValue<FieldValues>
    defaultUnitType?: string[]
    isEdit: boolean
    showForm?: boolean
}

const UNIT_TYPES = [
    {
        category: 'Residential',
        types: ['HDB', 'Condominium', 'Landed']
    },
    {
        category: 'Commercial',
        types: ['Office', 'Shop', 'Factory', 'Warehouse', 'Business Park']
    },
]

export default function FormFieldUser(props: FormFieldUserType) {
    const { showForm = true, defaultUnitType = [] } = props
    const { fields, append, remove } = useFieldArray({
        control: props.control,
        name: "addresses"
    });

    const addNewAddress = useCallback(() => {
        append({
            street: '', buildingNumber: '', unitType: '', unitNumber: '',
            postalCode: '', latitude: 0, longitude: 0
        })
    }, [append])

    return (
        <form onSubmit={props.handleSubmit((data: any) => props.onFinishHandler({ ...data, phoneNumber: `${data.phoneNumber[0] == '+' ?  data.phoneNumber:  `+${data.phoneNumber}`}` }))} className="flex flex-col gap-4" id={props.formId}>
            <FormsGrid>
                <TextField control={props.control} fieldName='name' required label={'Name'} />
                <TextField control={props.control} fieldName='email' regex='email' label={'Email'} />
                <CountryPhoneInput control={props.control} fieldName="phoneNumber" label={'Contact Number'} required />
                <GenderField
                    control={props.control} queryResult={props.queryResult}
                    setValue={props.setValue}
                />
                <TextField control={props.control} fieldName='birthDate' label={'Birth Date'} type='date' />
            </FormsGrid>
            {
                fields.map((address, idx) => (
                    <>
                        <AddressForm
                            setValue={props.setValue}
                            key={idx} idx={idx} remove={remove}
                            control={props.control} queryResult={props.queryResult}
                            fields={fields}
                            isEdit={props.isEdit}
                            watch={props.watch}
                            showForm={showForm}
                            defaultUnitType={defaultUnitType[idx]}
                        />
                    </>
                ))
            }
            <Button type='button' className="mx-auto text-center w-fit" onClick={addNewAddress}>+ Add address</Button>
        </form>
    )
}

const GenderField = (
    { control, queryResult, setValue }: {
        control: FormFieldType['control'],
        setValue: FormFieldType['setValue'],
        queryResult: FormFieldType['queryResult'],
    }
) => {
    const [defaultValue, setDefaultValue] = useState<SelectSingleVal | undefined | null>(undefined)

    useEffect(() => {
        if (defaultValue === undefined) {
            const defaultVal = queryResult.data ? (queryResult.data.data.gender || null) : null
            if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === true) {
                setDefaultValue(
                    !defaultVal ? null :
                        { value: defaultVal, label: capitalizeStr(defaultVal) }
                )
            }
            else if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === false) {
                setDefaultValue(null)
            }
        }
    }, [queryResult, defaultValue])

    return (
        defaultValue === undefined ? <></> :
            <SelectField
                label='Gender'
                control={control} fieldName='gender'
                defaultCfg={{ value: defaultValue, setValue: setValue }}
                options={[
                    { value: 'male', label: 'Male' },
                    { value: 'female', label: 'Female' },
                ]}
            />
    )
}

const AddressForm = (
    { control, setValue, queryResult, remove, idx, fields, watch, defaultUnitType, isEdit, showForm = true }: {
        control: FormFieldType['control'],
        setValue: UseFormSetValue<FieldValues>,
        watch: FormHookType['watch']
        queryResult: FormFieldType['queryResult'],
        remove: any,
        idx: number,
        fields: any,
        defaultUnitType?: string,
        isEdit: boolean,
        showForm?: boolean
    }
) => {
    const [selectedPlace, setSelectedPlace] = useState<PlaceInterface | null | undefined>()
    const pinpointModal = useModal()
    const findAddressModal = useModal()
    const handleSetAddress = (place: PlaceInterface) => {
        setValue(`addresses.${idx}.latitude`, place.lat && +place.lat)
        setValue(`addresses.${idx}.longitude`, place.lng && +place.lng)
        setValue(`addresses.${idx}.street`, place.street)
        setValue(`addresses.${idx}.postalCode`, place.postalCode)
        setValue(`addresses.${idx}.buildingNumber`, place.buildingNumber)
        pinpointModal.close()
        findAddressModal.close()
        setSelectedPlace(place)
    }
    const watchField = watch()

    return (
        <>
            <Accordion heading={<>
                <span className='gap-1 flex-ct-y'>
                    <span>Resident {idx + 1}</span>
                    <button type='button' onClick={() => { remove(idx) }}>
                        <CloseCircleIcon color='#757575' className='w-4' />
                        <span className='sr-only'>Remove</span>
                    </button>
                </span>
            </>}>
                <div className='flex flex-col gap-4'>
                    <AddressLocation
                        className="w-full"
                        onClick={findAddressModal.show}
                        name={selectedPlace ? formatBuildingAddress(watchField.addresses[idx].buildingNumber, watchField.addresses[idx].street) : 'Not Selected'}
                        address={selectedPlace ? formatCountry(watchField.addresses[idx].country || 'Singapore', watchField.addresses[idx].postalCode) : 'Not Selected'}
                    />
                    <FormsGrid>
                        <TextField control={control} required label={'Latitude'}
                            isNumeric fieldName={`addresses.${idx}.latitude`}
                        />
                        <TextField control={control} required label={'Longitude'}
                            isNumeric fieldName={`addresses.${idx}.longitude`}
                        />
                    </FormsGrid>
                    <FormsGrid col={3}>
                        <TextField required label={'Block Number'} control={control}
                            fieldName={`addresses.${idx}.buildingNumber`}
                        />
                        <TextField required label={'Street'} control={control}
                            fieldName={`addresses.${idx}.street`}
                        />
                        <TextField label={'Building Name'} control={control}
                            fieldName={`addresses.${idx}.buildingName`}
                        />
                    </FormsGrid>
                    <FormsGrid col={3}>
                        <TextField label={'Floor Number'} control={control}
                            fieldName={`addresses.${idx}.floorNumber`}
                        />
                        <TextField label={'Unit Number'} control={control}
                            fieldName={`addresses.${idx}.unitNumber`}
                        />
                        {showForm &&
                            <>
                                <SelectField
                                    options={flatten(UNIT_TYPES.map(categories => categories.types.map(type => ({ value: type, label: `${categories.category} - ${type}` }))))}
                                    control={control} label={'Address Type'} required fieldName={`addresses.${idx}.unitType`}
                                    defaultCfg={{
                                        setValue: setValue,
                                        value: { value: defaultUnitType, label: defaultUnitType ?? "" }
                                    }}
                                />
                            </>
                        }
                    </FormsGrid>
                    <FormsGrid>
                        <TextField required label={'Postal Code'} control={control}
                            fieldName={`addresses.${idx}.postalCode`}
                        />
                    </FormsGrid>
                </div>
            </Accordion>
            <FindAddressPopup visible={findAddressModal.visible} openPinPoint={pinpointModal.show} handleSetAddress={handleSetAddress} close={findAddressModal.close} />
            <PinpointPopup visible={pinpointModal.visible} close={pinpointModal.close} openFindAddress={findAddressModal.show} handleSetAddress={handleSetAddress} />
        </>

    )
}
