import { Button } from "components/buttons"
import { TextField } from "components/forms"
import AuthLayout from "components/layout/AuthLayout"
import PageTitle from "components/PageTitle"
import { baseApi } from "providers/customDataProvider"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "sonner"


const ForgotPassword = () => {
    const [isEmailSent, setIsEmailSent] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { control, handleSubmit, getValues } = useForm()
    const onSubmit = handleSubmit(async (data) => {
        try {
            setIsLoading(true)
            await baseApi.post('/auth/admin/forgot-password', data)
            setIsEmailSent(true)
        } catch (err: any) {
            console.log(err)
            toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    })
    return <>
        <PageTitle title="Forgot Password" />
        <AuthLayout>
            <form onSubmit={onSubmit} className="flex flex-col justify-center gap-10">
                <h1 className="text-th-xl font-bold">Forgot Password</h1>
                {isEmailSent ?
                    <>
                        <p className="text-[#757575]">
                            Email with reset password link has been sent to your email <span className="font-bold">{getValues('email')}</span>. Please check inbox or spam folder.
                        </p>
                        <div className="flex items-center justify-between">
                            <span className="text-[#757575]">Didn't receive any email ?</span>
                            <button type="button" className="text-primary" onClick={() => setIsEmailSent(false)}>Resend the link</button>
                        </div>
                    </>
                    :
                    <>
                        <TextField required regex={'email'} control={control} fieldName="email" placeholder="Enter your email address" label={'Registered Email'} />
                        <Button isLoading={isLoading} type="submit" shape="filled" className="font-bold">Send Reset Link</Button>
                    </>
                }
            </form>
        </AuthLayout>
    </>
}

export default ForgotPassword