import { Button } from "components/buttons"
import { AsyncSelectField, TextAreaField } from "components/forms"
import { EditNotesIcon } from "components/Svg"
import { baseApi } from "providers/customDataProvider"
import { ReactNode, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "sonner"


type Props = {
    Icon: ReactNode
    header: string
    children: ReactNode
    editable?: {
        name: string,
        endpoint: string,
        defaultValue: {
            value: string,
            label: string
        },
        refetch: () => Promise<void>
        sidePosition?: boolean,
        handleLoadOptions?: (inputValue: string) => Promise<any[]>
    }
}

const OrderDetailCardEditable = (props: Props) => {
    const { Icon, header, children, editable } = props
    const { control, handleSubmit, watch, setValue } = useForm()
    const [isEditMode, setIsEditMode] = useState(false)
    const value = editable && watch(editable.name)

    useEffect(() => {
        if (editable) {
            setValue(editable.name, editable.defaultValue.value)
        }
    }, [editable])

    const onEdit = handleSubmit(async (data: any) => {
        if (!editable) return
        try {
            const res = await baseApi.patch(editable.endpoint, data)
            toast.success('Edit success')
            setIsEditMode(false)
            await editable.refetch()
            return res.data
        } catch (err: any) {
            console.log(err)
            toast.error(err.message)
        }
    })

    return <div className="bg-white relative z-10" id={`edit-${editable?.name}`}>
        <div className="border rounded-lg p-4 flex items-start gap-4">
            <div className="mt-1">
                {Icon}
            </div>
            <div className="flex flex-col gap-1 w-full">
                <div className="flex items-center justify-between gap-3 ">
                    <h3 className="text-th-lg text-light">{header}</h3>
                    {editable &&
                        <>
                            {isEditMode ?
                                <div className="flex items-center gap-4">
                                    <Button type="button" onClick={() => { setIsEditMode(false); setValue(editable.name, editable.defaultValue.value); }} confirm shape="raw" className="text-danger font-bold">
                                        Cancel
                                    </Button>
                                    <Button shape="raw" confirm onClick={onEdit} form={`edit-${editable?.name}`}>
                                        <div className="text-primary flex items-center gap-2 font-bold">
                                            Save <EditNotesIcon />
                                        </div>
                                    </Button>
                                </div> :
                                <button type="button" className="text-primary flex items-center gap-2 font-bold" onClick={() => setIsEditMode(true)}>Edit <EditNotesIcon /></button>
                            }
                        </>
                    }
                </div>
                {editable &&
                    <>
                        {isEditMode ?
                            <>
                                {editable.handleLoadOptions ?
                                    <AsyncSelectField
                                        defaultCfg={editable.defaultValue && editable.defaultValue.label && editable.defaultValue.value ? {
                                            setValue: setValue, value: { value: +editable.defaultValue.value, label: editable.defaultValue.label }
                                        } : undefined}
                                        placeholder={`Select ${header}`}
                                        control={control} fieldName={editable.name} loadOptions={editable.handleLoadOptions}
                                    /> :
                                    <TextAreaField cols="50" rows="2" placeholder={`Enter ${header}`} control={control} fieldName={editable.name} />
                                }
                            </>
                            :
                            editable.defaultValue?.label || '-'
                        }
                    </>
                }
                <div className="text-light">
                    {children}
                </div>
            </div>
        </div>
    </div>
}

export default OrderDetailCardEditable