import { useEffect, useState } from "react"
import { baseApi } from "providers/customDataProvider"
import { AsyncMultiSelectField, AsyncSelectField, ColorPicker, FormsGrid, TextField } from "components/forms"

export type TechnicianTeamFieldType = {
    name: string
    zones: number[]
    technicians: number[]
}

interface FormFieldTechnicianTeamProps extends FormFieldTypeNew {
    setValue: FormHookType['setValue'],
}

export default function FormFieldTechnicianTeam(props: FormFieldTechnicianTeamProps) {
    return (
        <form onSubmit={props.handleSubmit(props.onFinishHandler)} className="flex flex-col gap-4" id={props.formId}>
            <FormsGrid col={2}>
                <TextField required label={'Team Name'} control={props.control} fieldName="name" />
                <ColorPicker label={'Color'} control={props.control} fieldName="colorHex" />
            </FormsGrid>
            {/* <ZonesField control={props.control} queryResult={props.queryResult} setValue={props.setValue} /> */}
        </form>
    )
}

const ZonesField = ({ control, queryResult, setValue }: {
    control: FormFieldType['control'],
    setValue: FormHookType['setValue'],
    queryResult: FormFieldType['queryResult']
}) => {
    const [defaultValue, setDefaultValue] = useState<SelectMultiVal | undefined | []>(undefined)

    useEffect(() => {
        if (defaultValue === undefined) {
            const defaultVal = queryResult.data ? queryResult.data.data.zones : []
            if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === true) {
                setDefaultValue(defaultVal.map((val: MiscObj) => ({ value: val.id, label: val.name })))
            }
            else if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === false) {
                setDefaultValue([])
            }
        }
    }, [queryResult, defaultValue])

    const getZones = async (name: string) => {
        const { data } = await baseApi.get(`/admin/zones?name=${name}`)
        return data.list.map((zone: MiscObj) => ({
            value: zone.id, label: zone.name
        }))
    }
    const promiseOptions = (inputValue: string) => new Promise<any[]>((resolve) => {
        resolve(getZones(inputValue))
    })
    return (
        defaultValue === undefined ? <></> :
            <AsyncMultiSelectField
                label={'Zones'} control={control}
                fieldName="zones" loadOptions={promiseOptions}
                defaultCfg={{
                    value: defaultValue,
                    setValue: setValue
                }}
            />
    )
}