
import ListQuotationItem from "components/pages/quotation-items/ListQuotationItems"
import { ResourceType } from "resources-config"

export const QUOTATION_ITEMS_CONFIG: ResourceType = {
    name: 'quotation-items', meta: {
        parent: 'quotations', label: "Quotation Items",
        apiEndpoints: {
            getList: 'quotations/items',
            getOne: 'quotations/items',
            create: 'quotations/items',
            update: 'quotations/items',
        }
    },
    actions: {
        list: {
            path: 'quotation-items', page: <ListQuotationItem />,
            allowedRoles: ['super admin'],
        },
    }
}