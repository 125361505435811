import { useModal } from "@refinedev/core"
import { cancelItemRequest } from "api/item-requests"
import { postResetScheduleTask } from "api/service-schedules"
import Badge from "components/Badge"
import { Button } from "components/buttons"
import ConfirmationModal from "components/custom-modals/ConfirmationModal"
import UpsellTechModal from "components/custom-modals/UpsellTechModal"
import { SelectField, TextAreaField, TextField } from "components/forms"
import { ChevronDownIcon, EditIcon, EditNotesIcon, InfoCircleIcon, TrashIcon, VisibleIcon } from "components/Svg"
import { DisplayDbLog } from "components/texts"
import { StatusTextTooltip } from "components/tooltips"
import NodeTooltip from "components/tooltips/NodeTooltip"
import { baseApi } from "providers/customDataProvider"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { FaPlusCircle } from "react-icons/fa"
import { IoAddCircle } from "react-icons/io5"
import { Link } from "react-router-dom"
import { toast } from "sonner"
import { formatAddressAny } from "utils/address"
import { numOrderSuffix } from "utils/data-formatter"
import { formatFullDate } from "utils/date"
import { calculateItemFinalVbPoints, getScheduleStatusColor } from "utils/order"
import { buildDailyScheduleQuery } from "utils/scheduler"


type Props = {
    item: MiscObj
    refetchOrder: () => void
    order: MiscObj
    showAllNotes: boolean
    itemDbLog?: MiscObj
}

const OrderItemCard = (props: Props) => {
    const { item, refetchOrder, order, showAllNotes, itemDbLog } = props
    const upsellTechnicianModal = useModal()
    const { control, watch, handleSubmit } = useForm({ defaultValues: { price: item.price, savedAddressId: null } })
    const [isEditPriceMode, setIsEditPriceMode] = useState(false)
    const [resetScheduleObject, setResetScheduleObject] = useState<MiscObj>()
    const watchPrice = watch('price')
    const resetConfirmModal = useModal()
    const deleteConfirmModal = useModal()

    const handleResetScheduleTask = async (scheduleId: number, scheduleTaskId: number) => {
        if (!scheduleId || !scheduleTaskId) {
            toast.error('Cannot find the schedule task.')
            return
        }
        if (await postResetScheduleTask(scheduleId, scheduleTaskId) === true) {
            toast.success('Successfully reset the service.')
            refetchOrder()
        } else {
            toast.error('Failed to reset the service.')
        }
    }

    const handleCancelItem = async () => {
        try {
            if (await cancelItemRequest(item.id)) {
                toast.success('Additional item successfully cancelled')
            }
        }
        catch (err: any) {
            toast.error(err.message)
        } finally {
            refetchOrder()
        }
    }

    const handleEditItem = async (data: any) => {
        try {
            const res = await baseApi.patch(`/admin/service-orders/${item.order.id}/items/${item.id}`, {
                ...data,
                price: data.price ? +data.price : undefined,
                savedAddressId: data.savedAddressId ? +data.savedAddressId : undefined,
            })
            if (res.data.message == 'Success') {
                toast.success('Item successfully edited')
                setIsEditPriceMode(false)
            }
        } catch (err: any) {
            toast.error(err.message)
            console.log(err)
        } finally {
            refetchOrder()
        }
    }

    return <>
        {Array(item.frequency).fill(0).map((_, index) => {
            const scheduleTask = item.scheduleTasks.filter((st: any) => st.incompleteReason == null)[index]
            const statusData = getScheduleStatusColor(item, scheduleTask && scheduleTask.schedule)
            const upsellingTechnicians = item.itemUpsellTechnicians.map((itemUpsellTech: any) => itemUpsellTech.role == 1 ? `${itemUpsellTech.technician.name} (Leader)` : itemUpsellTech.technician.name).join(', ')
            const taskAddress = item.addresses && item.addresses.find((addr: any) => addr.period == index)
            const postalCode = taskAddress?.postalCode || order.postalCode
            const teams: string[] = order.availableTeams.find((at: any) => at.postalCode == postalCode)?.teams || []
            const scheduleHref = `/daily-schedules?${buildDailyScheduleQuery(scheduleTask && scheduleTask.schedule.startDate, scheduleTask && scheduleTask.schedule.id, order.id, undefined, teams)}`
            let scheduleFinisher = scheduleTask?.schedule?.finishingTech?.name
            scheduleFinisher = !scheduleFinisher ? scheduleTask?.schedule?.finishingAdmin?.name : scheduleFinisher
            scheduleFinisher = scheduleFinisher ? `by ${scheduleFinisher} ` : ''
            let technicians = (scheduleTask && scheduleTask?.schedule?.completionDate == null) ? order.teamAssignments?.find((assign: any) => +assign.scheduleId == scheduleTask?.schedule.id)?.teamAssignment?.assignedTechnicians : scheduleTask?.schedule?.completingTechnicians
            technicians = technicians?.map((assignedTech: any) => assignedTech.role == 1 ? `${assignedTech.technician.name} (Leader)` : assignedTech.technician.name).join(', ')
            return <div className="rounded-lg border p-4" style={{ background: statusData.backgroundColor }}>
                {/* Header */}
                <div className="flex flex-col gap-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-stretch gap-4">
                            <div className="w-12 h-12 bg-primary-3-surface rounded-lg relative overflow-hidden">
                                {item.frequency > 1 && <div className="absolute top-0 left-0 bg-contract z-10 text-white w-full text-center font-medium text-[0.58rem]">
                                    Contract
                                </div>
                                }
                                <Link to={`/services/edit/${item.service.id}`} target="_blank">
                                    <img alt="Service Icon" src={item.service.icon ? item.service.icon.replace('https:/a', 'https://a') : '/images/airple-icon.png'} className="w-12 h-12 object-contain relative rounded-lg" />
                                </Link>
                            </div>
                            <div className="flex flex-col justify-center gap-1">
                                <span>{item.frequency > 1 && numOrderSuffix(index + 1)} {item.service.name}</span>
                                <span className="text-light">{item.serviceCategory?.name} <b>{item.quantity}</b> {item.service.serviceUnit?.name || 'unit'}(s)</span>
                            </div>
                        </div>
                        <div className="flex items-start gap-6">
                            {/* Price */}
                            {index == 0 && !isEditPriceMode &&
                                <>
                                    <div className="flex flex-col items-end">
                                        <span className="font-bold text-th-lg">$ {item.price.toFixed(2)}</span>
                                        <div className="flex items-center gap-2">
                                            <span className="text-light text-th-sm">$ {(item.price / item.frequency / item.quantity).toFixed(2)} / unit / freq</span>
                                            <NodeTooltip Icon={<EditNotesIcon />} tooltipClassName="w-64">
                                                <div className="grid grid-cols-2 text-primary">
                                                    <span>Commision</span>
                                                    <span>: {item.technicianCommission.toFixed(2)}</span>
                                                </div>
                                                <div className="grid grid-cols-2 text-primary">
                                                    <span>VB Point</span>
                                                    <span>: {item.finalVbPoints?.toFixed(2) || 'Failed to Calculate'}</span>
                                                </div>
                                                <div className="grid grid-cols-2 text-primary">
                                                    <span>LSP</span>
                                                    <span>: {item.lowestSellingPrice.toFixed(2)}</span>
                                                </div>
                                            </NodeTooltip>
                                        </div>
                                    </div>
                                    {statusData.status != 'Cancelled' && statusData.status != 'Rejected' &&
                                        <>
                                            <button type="button" onClick={() => setIsEditPriceMode(true)}>
                                                <EditIcon />
                                            </button>
                                            <Button shape="raw" type="button" onClick={deleteConfirmModal.show}>
                                                <TrashIcon color={'#BB032A'} />
                                            </Button>
                                        </>
                                    }
                                </>
                            }
                            <>
                                {/* Statuses */}
                                <StatusTextTooltip values={{
                                    addingTech: item.addingTech,
                                    rejectingAdmin: item.rejectingAdmin,
                                    approvingAdmin: item.approvingAdmin,
                                    scheduleTask: scheduleTask,
                                    rejectedAt: item.rejectedAt,
                                    scheduleFinisher: scheduleFinisher,
                                    itemDbLog: itemDbLog,
                                }} statusText={statusData.status}>
                                    <Badge className="text-th-xs" fullOpacityBackground={true} color={statusData.badgeBackgroundColor} textColor={statusData.badgeTextColor} style={{ border: `1px solid ${statusData.badgeBorderColor}` }}>
                                        {statusData.status}
                                    </Badge>
                                </StatusTextTooltip>
                            </>
                            {index == 0 && isEditPriceMode &&
                                <div className="flex items-start gap-6" id={`edit-item-price-form-${item.id}`}>
                                    <span className="text-light text-th-lg">$ {item.price.toFixed(2)}</span>
                                    <div>
                                        <TextField className=" font-bold" value={watchPrice} type="number" isNumeric fieldName="price" control={control} defaultValue={item.price} />
                                        <div className="flex items-center gap-2">
                                            <span className="text-light text-th-sm">$ {(+watchPrice / item.frequency / item.quantity).toFixed(2)} / unit / freq</span>
                                            <NodeTooltip Icon={<EditNotesIcon />} tooltipClassName="w-64">
                                                <div className="grid grid-cols-2 text-primary">
                                                    <span>Commision</span>
                                                    <span>: {item.technicianCommission.toFixed(2)}</span>
                                                </div>
                                                <div className="grid grid-cols-2 text-primary">
                                                    <span>VB Point</span>
                                                    <span>: {calculateItemFinalVbPoints(item, watchPrice)}</span>
                                                </div>
                                                <div className="grid grid-cols-2 text-primary">
                                                    <span>LSP</span>
                                                    <span>: {item.lowestSellingPrice.toFixed(2)}</span>
                                                </div>
                                            </NodeTooltip>
                                        </div>
                                    </div>
                                    <Button shape="raw" type="button" onClick={handleSubmit(handleEditItem)} confirm form={`edit-item-price-form-${item.id}`} className="text-primary font-bold">
                                        Save Changes
                                    </Button>
                                    <Button shape="raw" type="button" confirm onClick={() => setIsEditPriceMode(false)} className="text-danger font-bold">
                                        Cancel
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                    {/* Below Item Info Row */}
                    <div className="flex flex-col border-b pb-4 gap-2">
                        {/* Upselling By */}
                        {index == 0 &&
                            <>
                                {item.itemUpsellTechnicians.length > 0 ?
                                    <div className="flex items-center justify-between bg-primary-2-surface rounded-full py-1.5 px-3 text-primary text-th-xs">
                                        <div className="flex items-center gap-2">
                                            <InfoCircleIcon width={16} />
                                            <span>Upsold by Technician {upsellingTechnicians || '-'}</span>
                                        </div>
                                        {statusData.status != 'Cancelled' && statusData.status != 'Rejected' &&
                                            <button type="button" className="font-bold" onClick={upsellTechnicianModal.show}>
                                                Edit
                                            </button>
                                        }

                                    </div> :
                                    <>
                                        {statusData.status != 'Cancelled' && statusData.status != 'Rejected' &&
                                            <button type="button" className="font-bold text-primary mr-auto" onClick={upsellTechnicianModal.show}>
                                                Add upselling technicians
                                            </button>
                                        }
                                    </>
                                }
                            </>
                        }

                        {/* Service Address */}
                        <ServiceAddressSection order={order} taskAddress={taskAddress} item={item} handleEditItem={handleEditItem} period={index} statusData={statusData} />
                        {/* Service Schedule */}
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col gap-1">
                                <div className="flex items-center gap-2">
                                    Service Schedule: <span className="text-primary">{scheduleTask?.schedule ? formatFullDate(scheduleTask.schedule.startDate) : '-'}</span>
                                    {scheduleTask &&
                                        <div className="flex items-center text-black gap-2">
                                            <span className="font-bold">∙ {scheduleTask?.schedule?.technicianTeam?.name}</span> <span className="text-light"> ∙ <span className="text-body">JID {scheduleTask?.schedule?.id}</span> |</span>
                                            <Button shape="raw" type="button" onClick={() => { resetConfirmModal.show(); setResetScheduleObject({ scheduleId: scheduleTask?.schedule?.id, taskId: scheduleTask?.id }) }} className="text-danger font-bold">Reset Service</Button>
                                        </div>
                                    }
                                </div>
                                <DisplayDbLog dbLog={order.serviceSchedulesDbLogs.find((log: any) => log.rowId === scheduleTask?.schedule?.id)} />
                            </div>
                            {statusData.status != 'Cancelled' && statusData.status != 'Rejected' &&
                                <Link to={scheduleHref} type="button" className="text-primary font-bold">
                                    Edit Service Schedule
                                </Link>
                            }
                        </div>
                        {/* Technician */}
                        <div className="flex items-center justify-between">
                            Technician : {technicians || '-'}
                        </div>
                    </div>
                </div>
                {/* Notes Accordion */}
                <NotesAccordionSection order={order} handleEditItem={handleEditItem} showAllNotes={showAllNotes} addressNotes={taskAddress?.notes || order?.notes} item={item} scheduleTask={scheduleTask} period={index} />
            </div>

        })}
        <UpsellTechModal modal={upsellTechnicianModal} targetItem={item} refetchOrder={refetchOrder} />
        <ConfirmationModal onSuccess={handleCancelItem} modal={deleteConfirmModal} title="Cancelling this service will remove:">
            <p className="text-light">Schedule, Technician Upsell By, VB Points, All Notes, Item on Invoice, Service Report</p>
            <p className="text-light font-bold">and this won't be able to be reinserted into the service: Customer Review, KPI Factor, Tip</p>
        </ConfirmationModal>
        <ConfirmationModal onSuccess={() => handleResetScheduleTask(resetScheduleObject?.scheduleId, resetScheduleObject?.taskId)} modal={resetConfirmModal} title="Resetting this service will remove:">
            <p className="text-light">Schedule, Technician Upsell By, VB Points, All Notes, Item on Invoice, Service Report </p>
            <p className="text-light font-bold">and this will be unrecoverable even if you create a new service: Customer Review, KPI Factor, Tips</p>
        </ConfirmationModal>
    </>

}

export default OrderItemCard

type ServiceAddressSelectProps = {
    handleEditItem: (data: any) => Promise<void>,
    order: MiscObj
    item: MiscObj
    period: number
    taskAddress: MiscObj
    statusData: MiscObj
}

const ServiceAddressSection = ({ order, handleEditItem, period, taskAddress, statusData }: ServiceAddressSelectProps) => {
    const { control, handleSubmit, reset } = useForm()
    const [isEditServiceAddress, setIsEditServiceAddress] = useState(false)
    const serviceAddress = formatAddressAny(taskAddress) || formatAddressAny(order)
    const confirmationModal = useModal()

    const onSubmit = handleSubmit(async (data) => {
        await handleEditItem({ ...data, period })
        setIsEditServiceAddress(false)
        reset()
    })

    return <div className="flex items-center justify-between">
        <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
                Service Address: <span className="text-primary">{serviceAddress}</span>
            </div>
            <DisplayDbLog dbLog={order.itemAddressesDbLogs.find((log: any) => log.rowId == taskAddress?.id && log.newData?.period == period)} />
        </div>
        {isEditServiceAddress ?
            <div className="flex items-center gap-6">
                <SelectField control={control} className="w-[300px] line-clamp-1" fieldName='savedAddressId' options={order.user.addresses.map((addr: any) => ({ value: addr.id, label: formatAddressAny(addr) }))} />
                <Button shape="raw" type="button" onClick={confirmationModal.show} className="text-primary font-bold">
                    Save Changes
                </Button>
                <Button shape="raw" type="button" onClick={() => setIsEditServiceAddress(false)} className="text-danger font-bold">
                    Cancel
                </Button>
                <ConfirmationModal onSuccess={onSubmit} modal={confirmationModal} title="Changing service address will remove:">
                    <p className="text-light"><b>Schedule Task</b> and <b>Item on Service Report</b> if there are other items in the schedule with different address.</p>
                </ConfirmationModal>
            </div> :
            statusData.status != 'Cancelled' && statusData.status != 'Rejected' &&
            <button type="button" className="text-primary font-bold" onClick={() => setIsEditServiceAddress(true)}>
                Edit Service Address
            </button>
        }
    </div>
}

type NotesAccordionSectionProps = {
    showAllNotes: boolean
    addressNotes: string
    item: MiscObj
    scheduleTask: MiscObj
    period: number
    order: MiscObj
    handleEditItem: (data: any) => Promise<void>
}

const NotesAccordionSection = ({ showAllNotes, addressNotes, item, scheduleTask, handleEditItem, period, order }: NotesAccordionSectionProps) => {
    const [showNotes, setShowNotes] = useState(false)
    const customerRating = scheduleTask?.schedule?.customerRating
    const serviceReport = scheduleTask?.schedule?.report
    const incompleteScheduleTasks = item?.scheduleTasks?.filter((st: any) => st.itemPeriod == period && st.incompleteReason != null)

    useEffect(() => {
        setShowNotes(showAllNotes)
    }, [showAllNotes])

    return <div className="mt-4">
        <div className={`${showNotes ? 'max-h-full' : 'max-h-0'} overflow-hidden flex flex-col gap-4`}>
            <div className="bg-white p-4 rounded-lg border mb-4">
                <div className="flex items-center justify-between border-b pb-2">
                    <h5 className="font-bold">Customer's Address Notes:</h5>
                    <div className="flex items-center gap-1 text-light">visible to Customer, Technician and Admin <VisibleIcon /></div>
                </div>

                <p>{addressNotes ? addressNotes : '-'}</p>
            </div>
            <div className="bg-white p-4 rounded-lg border mb-4">
                <div className="flex items-center justify-between border-b pb-2">
                    <h5 className="font-bold">Customer's Item Notes:</h5>
                    <div className="flex items-center gap-1 text-light">visible to Customer, Technician and Admin <VisibleIcon /></div>
                </div>
                <p>{item?.notes ? item.notes : '-'}</p>
            </div>
            <div className="bg-white p-4 rounded-lg border mb-4">
                <div className="flex items-center justify-between border-b pb-2">
                    <h5 className="font-bold">Admin's Item Notes:</h5>
                    <div className="flex items-center gap-1 text-light">visible to Technician and Admin <VisibleIcon /></div>
                </div>
                <ItemNotesSection order={order} handleEditItem={handleEditItem} item={item} period={period} />
            </div>
            {scheduleTask?.schedule?.completionDate != null &&
                <>
                    <div className="bg-white p-4 rounded-lg border mb-4">
                        <div className="flex items-center justify-between border-b pb-2">
                            <h5 className="font-bold">Technician Customer Review:</h5>
                            <div className="flex items-center gap-1 text-light">visible to Technician and Admin <VisibleIcon /></div>
                        </div>
                        <p>
                            {customerRating?.otherComments || '-'}
                        </p>
                        {customerRating &&
                            <span className="text-light">Reviewed by Technician {customerRating.ratedBy?.name} at {formatFullDate(customerRating.createdAt)}</span>
                        }
                    </div>
                    <div className="bg-white p-4 rounded-lg border mb-4">
                        <div className="flex items-center justify-between border-b pb-2">
                            <h5 className="font-bold">Technician Recommendation:</h5>
                            <div className="flex items-center gap-1 text-light">visible to Customer, Technician and Admin <VisibleIcon /></div>
                        </div>
                        <p>
                            {serviceReport?.recommendation || '-'}
                        </p>
                        {serviceReport &&
                            <span className="text-light">Created by Technician {serviceReport?.reportingTechnician?.name} at {formatFullDate(serviceReport?.createdAt)}</span>
                        }
                    </div>
                </>
            }
            {incompleteScheduleTasks && incompleteScheduleTasks.length > 0 &&
                <div className="bg-white p-4 rounded-lg border mb-4">
                    <div className="flex items-center justify-between border-b pb-2">
                        <h5 className="font-bold">Reason for Incomplete Service</h5>
                        <div className="flex items-center gap-1 text-light">visible to Customer, Technician and Admin <VisibleIcon /></div>
                    </div>
                    <div className="flex flex-col gap-2">
                        {incompleteScheduleTasks.map((scheduleTask: any) =>
                            <div id={`incomplete-task-${scheduleTask.id}`}>
                                <p>{scheduleTask.incompleteReason}</p>
                                <span className="text-light">Created by Technician {scheduleTask?.schedule?.finishingTech?.name} at {formatFullDate(scheduleTask?.schedule?.completionDate)}</span>
                            </div>
                        )}
                    </div>
                </div>
            }
        </div>
        <button className="text-primary flex items-center font-bold gap-2 w-full justify-center" type="button" onClick={() => setShowNotes(state => !state)}>
            {showNotes ?
                <>Hide Notes <ChevronDownIcon width={16} className="rotate-180" color="#1954A1" /></> :
                <>Show Notes <ChevronDownIcon width={16} color="#1954A1" /></>
            }
        </button>
    </div >
}

type ItemNotesSectionProps = {
    item: MiscObj
    handleEditItem: (data: any) => Promise<void>,
    period: number
    order: MiscObj
}

const ItemNotesSection = (props: ItemNotesSectionProps) => {
    const { item, handleEditItem, period, order } = props
    const [showAddNotes, setShowAddNotes] = useState(false)
    const { control, getValues, reset, } = useForm()
    const periodItemNotes = item.adminItemNotes.filter((itemNote: any) => itemNote.period == period)

    const handleAddNewNote = async () => {
        try {
            await handleEditItem({
                period,
                adminNotes: getValues('adminNotes')
            })
            toast.success('Success adding notes')
            setShowAddNotes(false)
            reset()
        }
        catch (err: any) {
            console.log(err)
            toast.error(err.message)
        }
    }


    return <div className="pt-2">
        {periodItemNotes?.length > 0 ?
            <div className="space-y-4">
                {periodItemNotes.map((itemNote: any, index: number) => <ItemNotesField order={order} isLast={index == periodItemNotes.length - 1} setShowAddNotes={setShowAddNotes} itemNote={itemNote} item={item} handleEditItem={handleEditItem} period={period} itemNoteId={itemNote.id} />)}
            </div> :
            <div className="flex items-center justify-between">
                <span>-</span>
                {showAddNotes === false &&
                    <button onClick={() => setShowAddNotes(true)} type="button" className="flex items-center gap-2 text-primary font-bold">
                        <IoAddCircle color="#1945A1" /> Admin Notes
                    </button>
                }
            </div>
        }
        {showAddNotes &&
            <div className="flex flex-col gap-2 items-end mt-4">
                <TextAreaField className="w-full" control={control} fieldName="adminNotes" />
                <div className="flex items-center self-end gap-4">
                    <Button type="button" shape="raw" confirm onClick={handleAddNewNote} className="text-primary font-bold">
                        Add Notes
                    </Button>
                    <Button type="button" shape="raw" onClick={() => setShowAddNotes(false)} className="text-danger font-bold">
                        Cancel
                    </Button>
                </div>
            </div>
        }
    </div>
}

type ItemNotesProps = {
    item: MiscObj
    itemNote?: MiscObj
    handleEditItem: (data: any) => Promise<void>,
    period: number
    itemNoteId: number
    setShowAddNotes: Dispatch<SetStateAction<boolean>>
    isLast: boolean
    order: MiscObj
}

const ItemNotesField = (props: ItemNotesProps) => {
    const { item, handleEditItem, period, itemNoteId, itemNote, setShowAddNotes, isLast, order } = props
    const { control, getValues, reset, setValue } = useForm()
    const [isEditMode, setIsEditMode] = useState(false)
    const dbLog = order.itemAdminNotesDbLog.find((dbLog: any) => dbLog.rowId == itemNoteId)

    const handleEditNote = async () => {
        try {
            await handleEditItem({
                period,
                adminNotes: getValues('adminNotes'),
                adminNotesId: itemNoteId
            })
            toast.success('Success adding notes')
            setIsEditMode(false)
            reset()
        }
        catch (err: any) {
            console.log(err)
            toast.error(err.message)
        }
    }
    return <>
        {isEditMode ?
            <div className="flex flex-col gap-1 items-end">
                <TextAreaField control={control} fieldName="adminNotes" className="w-full" />
                <div className="flex items-center justify-between w-full">
                    <DisplayDbLog dbLog={dbLog} />
                    <div className="flex items-center self-end gap-2">
                        <Button type="button" shape="raw" confirm onClick={handleEditNote} className="text-primary font-bold">
                            Save Notes
                        </Button>
                        <Button type="button" shape="raw" confirm onClick={() => setIsEditMode(false)} className="text-danger font-bold">
                            Cancel
                        </Button>
                    </div>
                </div>

            </div> :
            <div className="flex flex-col gap-1 items-end">
                <p className="w-full">{itemNote?.notes}</p>
                <div className="w-full flex items-center justify-between">
                    <DisplayDbLog dbLog={dbLog} />
                    <div className="flex items-center self-end gap-2">
                        {isLast &&
                            <button onClick={() => setShowAddNotes(true)} type="button" className="flex items-center gap-2 text-primary font-bold">
                                <IoAddCircle color="#1945A1" /> Admin Notes
                            </button>
                        }
                        <button className="text-primary font-bold" type="button" onClick={() => { setIsEditMode(true); setValue('adminNotes', itemNote?.notes) }}>
                            Edit Notes
                        </button>
                    </div>
                </div>
            </div>
        }
    </>
}

