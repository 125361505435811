import { PropsWithChildren, useLayoutEffect, useState } from "react";
import { Menu } from "./Menu";
import { Header } from "./Header";
import { useLocation } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/navigation';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
    const [menuShown, setMenuShown] = useState(true)
    const location = useLocation();
    // useEffect(() => {
    //     setMenuShown(false)
    // }, [location])

    useLayoutEffect(() => {
        const dailySchedulerElement = document.querySelector('.daily-scheduler')
        if (!dailySchedulerElement) return

        if (menuShown) {
            dailySchedulerElement.classList.add('max-w-[calc(100vw_-_380px)]')
            dailySchedulerElement.classList.remove('max-w-[calc(100vw_-_220px)]')
        } else {
            dailySchedulerElement.classList.remove('max-w-[calc(100vw_-_380px)]')
            dailySchedulerElement.classList.add('max-w-[calc(100vw_-_220px)]')
        }
    }, [menuShown])
    return (
        <div className="layout">
            <Menu setMenuShown={setMenuShown} menuShown={menuShown} />
            <div className="content">
                <Header menuShown={menuShown} setMenuShown={setMenuShown} />
                <main>
                    {children}
                </main>
            </div>
        </div>
    );
};

export default Layout
