import { Button } from "components/buttons"
import { CreateEditCard } from "components/cards"
import FormFieldPromo from "./FormFieldPromo"
import { getPromoDetail, patchEditPromo } from "api/promo"
import { toast } from "sonner"
import { useEffect, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { camelCase, isArray, startCase } from "lodash"
import { getMonthByNumber } from "utils/date"
import { useNavigate, useParams } from "react-router-dom"


const ShowServicePromo = () => {
    const params = useParams()
    const { id } = params
    const { control, watch, handleSubmit, setValue } = useForm()
    const [promoDetail, setPromoDetail] = useState<MiscObj>()
    const criteriaFieldArr = useFieldArray({ control: control, name: 'criteria' })
    const [defaultSelectValues, setDefaultSelectValues] = useState<MiscObj[]>([])
    const [showForm, setShowForm] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (!promoDetail) {
            setDefaultSelectValues([])
            return
        }

        Object.entries(promoDetail as any).forEach(([key, value]) => {
            const defaultValue = value as any
            if (isArray(value)) {
                if (value.length > 0) criteriaFieldArr.append([key])
            } else {
                if (value != null && value != false) criteriaFieldArr.append([key])
            }
            try {
                if (key == 'excludedServices') {
                    setValue(key, defaultValue.map((exc: any) => `${exc.service.id}-${exc.isContract ? 'contract' : 'non'}-${exc.serviceCategory.id}`))
                } else if (key == 'excludedUsers' || key == 'includedUsers') {
                    setValue(key, defaultValue.map((user: any) => `${user.id}`))
                }
                else {
                    setValue(key, defaultValue)
                }
            } catch (err) {
                console.log(err)
            }

            if (isArray(defaultValue)) {
                setDefaultSelectValues(state => [...state, {
                    name: key,
                    defaultValues: defaultValue.map(val => {
                        try {
                            if (key == 'excludedServices') {
                                return {
                                    value: `${val.service.id}-${val.isContract ? 'contract' : 'non'}-${val.serviceCategory.id}`,
                                    label: `${val.service.name} - ${val.serviceCategory.name} ${val.isContract ? '(Contract)' : ''}`
                                }
                            }
                            else if (key == 'excludedUsers' || key == 'includedUsers') {
                                return { value: +val.id, label: val.name }
                            } else if (key == 'birthdayMonths') {
                                return { value: val, label: getMonthByNumber(val) }
                            } else if (key == 'genders') {
                                return { value: val, label: startCase(camelCase(val)) }
                            } else if (key == 'platforms') {
                                return {
                                    value: val,
                                    label: val == 'mobile' ? 'Mobile App' : 'Website'
                                }
                            } else {
                                return { value: val, label: val }
                            }
                        } catch (err) {
                            console.log(err)
                        }
                    })
                }])
            }
        })

        setShowForm(true)

    }, [promoDetail])

    useEffect(() => {
        if (!id) return
        getPromoDetail(id).then(promoDetail => setPromoDetail(promoDetail)).catch(err => {
            toast.error('Promo is not found')
            console.log(err)
            navigate('/promos')
        })
    }, [id])

    const editPromo = handleSubmit(async (data) => {
        if (!promoDetail) return
        try {
            const formatData = {
                ...data,
                type: parseInt(data.type),
                quota: parseInt(data.quota),
                forHaventBookedUser: data.criteria && data.criteria.find((c: string) => c == 'forHaventBookedUser') != undefined,
                forHaventBookedUserInMonths: data.criteria && data.criteria.find((c: string) => c == 'forHaventBookedUserInMonths') != undefined,
                excludedServices: data.excludedServices && data.excludedServices.map((exc: any) => ({ id: parseInt(exc.split('-')[0]), isContract: exc.split('-')[1] == 'contract', categoryId: parseInt(exc.split('-')[2]) })) || [],
                excludedUsers: data.excludedUsers.map((user: string) => parseInt(user)) || [],
                includedUsers: data.includedUsers.map((user: string) => parseInt(user)) || [],
                quotaApplied: data.criteria && data.criteria.find((c: string) => c == 'quotaApplied') != undefined,
                genders: data.genders || [],
                propertyTypes: data.propertyTypes || [],
                birthdayMonths: data.birthdayMonths || [],
            }
            await patchEditPromo(promoDetail.id, formatData)
            toast.success('Promo updated successfully !')
            navigate('/promos')
        } catch (err: any) {
            console.log(err)
            toast.error('Failed to edit promo. ' + err.message)
        }
    })

    return <CreateEditCard cardHeading="View Promo Code">
        {showForm === true ?
            <FormFieldPromo
                formId="edit-promo-form"
                control={control}
                watch={watch}
                onSubmit={editPromo}
                criteriaFieldArr={criteriaFieldArr}
                defaultSelectValues={defaultSelectValues}
                setValue={setValue}
                isEdit={true}
            /> : <></>
        }
    </CreateEditCard>
}

export default ShowServicePromo