import { useEffect, useMemo, useState } from 'react'
import { TextField, AsyncSelectField, FormsGrid, SelectField, TextAreaField } from 'components/forms'
import { baseApi } from 'providers/customDataProvider'
import FormFieldFrequencies from './FormFieldFrequencies'
import UploadField from 'components/forms/UploadField'
import { deleteServiceGallery } from 'api/service'
import { useParams } from 'react-router-dom'

type Props = FormFieldType & {
    defaultValues?: MiscObj
}

export default function FormFieldService(props: Props) {
    const { defaultValues = [], showForm = true } = props

    const { id: serviceId } = useParams()

    const defaults = useMemo(() => {
        let thumbnailDefault = defaultValues.find((def: any) => def.name == 'thumbnail')
        thumbnailDefault = thumbnailDefault ? thumbnailDefault.defaultValues : null

        let iconDefault = defaultValues.find((def: any) => def.name == 'icon')
        iconDefault = iconDefault ? iconDefault.defaultValues : null

        let galleryDefaults = defaultValues.find((def: any) => def.name == 'gallery')
        galleryDefaults = galleryDefaults ? galleryDefaults.defaultValues : null

        return { iconDefault, thumbnailDefault, galleryDefaults }
    }, [defaultValues])

    return (
        <form onSubmit={props.handleSubmit(props.onFinishHandler)} className="flex flex-col gap-4" id={props.formId}>
            <FormsGrid col={3}>
                <TextField control={props.control} fieldName='name' label='Name' required />
                <TextField control={props.control} fieldName='displayName' label='Display name' required />
                <ServiceUnitField control={props.control} queryResult={props.queryResult} setValue={props.setValue} />
            </FormsGrid>
            <FormsGrid col={2}>
                <FormsGrid col={3}>
                    <TextField control={props.control} fieldName='slug' label='Slug' required />
                    <TextField control={props.control} fieldName='metaTitle' label='Meta Title' />
                    <TextField control={props.control} fieldName='metaKeywords' label='Meta Keywords' desc="Ex: keywordX, keywordY" />
                </FormsGrid>
                <TextAreaField control={props.control} label={'Meta Description'} fieldName='metaDescription' />
            </FormsGrid>
            <FormsGrid col={2}>
                <ServiceJobTypeField
                    control={props.control} queryResult={props.queryResult}
                    setValue={props.setValue}
                />
                <DiscountedVersion
                    control={props.control}
                    queryResult={props.queryResult}
                    setValue={props.setValue}
                />
            </FormsGrid>
            <TextAreaField control={props.control} rows='8' fieldName='description' label={'Description'} />
            <FormsGrid col={2}>
                {defaults && showForm &&
                    <>
                        <UploadField accept={'image/*'} defaultSrc={[defaults.thumbnailDefault]} label={'Thumbnail'} control={props.control} fieldName='thumbnail' />
                        <UploadField accept={'image/*'} defaultSrc={[defaults.iconDefault]} label={'Icon'} control={props.control} fieldName='icon' />
                    </>
                }
            </FormsGrid>
            {defaults && showForm &&
                <UploadField handleDeleteServer={async (id) => await deleteServiceGallery(serviceId || '-1', id)} multiple accept={'image/*'} defaultSrc={defaults.galleryDefaults} label={'Gallery'} control={props.control} fieldName='gallery' />
            }
            <FormFieldFrequencies
                queryResult={props.queryResult}
                watch={props.watch}
                register={props.register}
                control={props.control}
                setValue={props.setValue}
            />
        </form>
    )
}

const ServiceUnitField = ({ control, queryResult, setValue }: {
    control: FormFieldType['control']
    setValue: FormHookType['setValue']
    queryResult: FormFieldType['queryResult']
}) => {
    const [defaultValue, setDefaultValue] = useState<any>(undefined)

    useEffect(() => {
        if (defaultValue === undefined) {
            const defaultVal = queryResult.data ? queryResult.data.data.serviceUnit : null
            if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === true) {
                setDefaultValue(
                    defaultVal ?
                        { value: defaultVal.id, label: defaultVal.name } : null
                )
            }
            else if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === false) {
                setDefaultValue(null)
            }
        }

    }, [queryResult, defaultValue])

    const getServiceUnits = async (search: string) => {
        const { data } = await baseApi.get(`/admin/service-units?search=${search}`)
        return data.list.map((serviceUnit: MiscObj) => ({
            value: serviceUnit.id, label: serviceUnit.name
        }))
    }
    const promiseOptions = (inputValue: string) => new Promise<any[]>((resolve) => {
        resolve(getServiceUnits(inputValue))
    })

    return (
        defaultValue === undefined ? <></> :
            <AsyncSelectField control={control} fieldName='serviceUnit'
                label='Metrics' required loadOptions={promiseOptions}
                defaultCfg={{ value: defaultValue, setValue: setValue }}
            />
    )
}

const ServiceJobTypeField = ({ control, queryResult, setValue }: {
    control: FormFieldType['control']
    setValue: FormHookType['setValue']
    queryResult: FormFieldType['queryResult']
}) => {
    const [defaultValue, setDefaultValue] = useState<SelectSingleVal | undefined | null>(undefined)

    useEffect(() => {
        if (defaultValue === undefined) {
            const defaultVal = queryResult.data ? queryResult.data.data.jobType : null
            if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === true) {
                setDefaultValue(
                    defaultVal ?
                        { value: defaultVal.id, label: defaultVal.name } : null
                )
            }
            else if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === false) {
                setDefaultValue(null)
            }
        }

    }, [queryResult, defaultValue])

    const getJobType = async (search: string) => {
        const { data } = await baseApi.get(`/admin/service-job-types?search=${search}`)
        return data.list.map((jobType: MiscObj) => ({
            value: jobType.id, label: jobType.name
        }))
    }
    const promiseOptions = (inputValue: string) => new Promise<any[]>((resolve) => {
        resolve(getJobType(inputValue))
    })
    return (
        defaultValue === undefined ? <></> :
            <AsyncSelectField control={control} fieldName='jobType'
                label='Job Type' required loadOptions={promiseOptions}
                defaultCfg={{ value: defaultValue, setValue: setValue }}
            />
    )
}

const AddOnAbleField = ({ control, queryResult, setValue }: {
    control: FormFieldType['control']
    setValue: FormHookType['setValue']
    queryResult: FormFieldType['queryResult']
}) => {
    const [defaultValue, setDefaultValue] = useState<SelectSingleVal | undefined | null>(undefined)

    useEffect(() => {
        if (defaultValue === undefined) {
            const defaultVal = (
                queryResult.data ?
                    (
                        queryResult.data.data.addonAble === true || queryResult.data.data.addonAble === false ?
                            {
                                value: queryResult.data.data.addonAble || false,
                                label: (
                                    queryResult.data.data.addonAble ?
                                        'True' : 'False'
                                ),
                            } : null
                    ) :
                    null
            )
            if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === true) {
                setDefaultValue(defaultVal)
            }
            else if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === false) {
                setDefaultValue(null)
            }
        }
    }, [queryResult, defaultValue])

    return (
        defaultValue === undefined ? <></> :
            <SelectField control={control}
                fieldName={`addonAble`} required label='Can be turned into add-on'
                defaultCfg={{ value: defaultValue, setValue: setValue }}
                options={[
                    { value: true, label: 'True' },
                    { value: false, label: 'False' },
                ]}
            />
    )
}

const DiscountedVersion = ({ control, queryResult, setValue }: {
    control: FormFieldType['control']
    setValue: FormHookType['setValue']
    queryResult: FormFieldType['queryResult']
}) => {
    const [defaultValue, setDefaultValue] = useState<SelectSingleVal | undefined | null>(undefined)

    useEffect(() => {
        if (defaultValue === undefined) {
            const defaultVal = queryResult.data ? queryResult.data.data.discountedVersion : null
            if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === true) {
                setDefaultValue(
                    defaultVal ?
                        { value: defaultVal.id, label: defaultVal.name } : null
                )
            }
            else if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === false) {
                setDefaultValue(null)
            }
        }

    }, [queryResult, defaultValue])

    const getJobType = async (search: string) => {
        const { data } = await baseApi.get(`/admin/services`)
        return data.list.map((jobType: MiscObj) => ({
            value: jobType.id, label: jobType.name
        }))
    }

    const promiseOptions = (inputValue: string) => new Promise<any[]>((resolve) => {
        resolve(getJobType(inputValue))
    })

    return (
        defaultValue === undefined ? <></> :
            <AsyncSelectField control={control} fieldName='discountedVersion'
                label='Discounted Version' loadOptions={promiseOptions}
                defaultCfg={{ value: defaultValue, setValue: setValue }}
            />
    )
} 