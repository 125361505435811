import { baseApi } from "providers/customDataProvider"


export const getAllInvoices = async (disablePagination = false, page = 1, pageSize = 10) => {
    const res = await baseApi.get(`/admin/service-invoices?disablePagination=${disablePagination}&page=${page}&pageSize=${pageSize}`)
    return res.data
}

export const postInvoiceHistory = async (invoiceId: string, body: MiscObj) => {
    const res = await baseApi.post(`/admin/service-invoices/${invoiceId}/invoice-histories`, body)
    return res.data
}

export const deleteInvoiceHistory = async (invoiceId: string, invoiceHistoryId: string) => {
    const res = await baseApi.delete(`/admin/service-invoices/${invoiceId}/invoice-histories/${invoiceHistoryId}`)
    return res.data.message == 'Success'
}

export const claimInvoiceHistory = async (invoiceId: string, invoiceHistoryId: string) => {
    const res = await baseApi.post(`/admin/service-invoices/${invoiceId}/invoice-histories/${invoiceHistoryId}/claim-cash`)
    return res.data.message == 'Success'
}

export const getAllInvoiceHistory = async (paymentMethod = '', isManual?: boolean, isClaimed?: boolean) => {
    const res = await baseApi.get(`/admin/service-invoices/invoice-histories?paymentMethod=${paymentMethod}&isManual=${isManual}&isClaimed=${isClaimed}`)
    return res.data
}