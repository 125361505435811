import { useModalReturnType } from "@refinedev/core"
import { postAddSchedule, postReschedule } from "api/service-schedules"
import { Button } from "components/buttons"
import { AsyncSelectField, SelectField, Checkbox } from "components/forms"
import Pill from "components/Pill"
import { Modal } from "components/popups"
import { flatten, max, replace } from "lodash"
import { nanoid } from "nanoid"
import { baseApi } from "providers/customDataProvider"
import { useState, useMemo, useEffect } from "react"
import { useForm, useFieldArray } from "react-hook-form"
import Select from "react-select/dist/declarations/src/Select"
import { toast } from "sonner"
import { formatAddressAny } from "utils/address"
import { numOrderSuffix } from "utils/data-formatter"
import localToUTC from "utils/data-formatter/local-to-utc"
import { formatFullDate } from "utils/date"

type AddScheduleModalProps = useModalReturnType & {
    refetchDailySched: () => void
    viewedTeam: { team: MiscObj, date: string, viewTs: number } | null
    initialOrderId?: string
    initialScheduleId?: string
}
function AddScheduleModal(props: AddScheduleModalProps) {
    const { refetchDailySched, viewedTeam, initialOrderId, initialScheduleId, ...rest } = props
    const { control, handleSubmit, watch, setValue, setError, reset } = useForm()
    const [orderSchedules, setOrderSchedules] = useState<any>()
    const [orderDefaultValue, setOrderDefaultValue] = useState<MiscObj>()
    const [addressOptions, setAddressOptions] = useState<{ value: any, label: string, isDisabled?: boolean }[]>([])
    const [selectedAddress, setSelectedAddress] = useState<MiscObj>()
    const [selectedSchedule, setSelectedSchedule] = useState<MiscObj>()
    const [scheduleDefaultValue, setScheduleDefaultValue] = useState<MiscObj>()

    const fieldArr = useFieldArray({ control: control, name: 'items' })
    const orderId = watch('order')
    const scheduleId = watch('period')
    const address = watch('address')

    const scheduleOptions = useMemo(() => {
        if (!orderSchedules || !orderSchedules.items || orderSchedules.items.length == 0) return []
        const availableScheduleTasks = flatten(orderSchedules.items.map((item: any) => item.scheduleTasks))
        const options: any = []
        const maximumPeriod = orderSchedules.items.map((item: any) => item.frequency).reduce((acc: number, current: number) => acc + current, 0)
        Array(maximumPeriod).fill(0).forEach((_, index) => {
            const scheduleTask: any = availableScheduleTasks.find((scheduleTask: any) => scheduleTask.schedule.period == index)
            options.push({
                label: `${numOrderSuffix(index + 1)} Job ${scheduleTask ? `ID ${scheduleTask.schedule.id} (${formatFullDate(scheduleTask.schedule.startDate)})` : '(Unscheduled)'}`,
                value: scheduleTask ? +scheduleTask.schedule.id : (index + 1) * -1,
                // isDisabled: scheduleTask && scheduleTask.schedule && scheduleTask.schedule.teamStartDate ? true : false
            })
        })

        return options

    }, [orderSchedules])

    useEffect(() => {
        if (!address) return
        setSelectedAddress(addressOptions.find((addr: any) => addr.value == address))
    }, [address])

    useEffect(() => {
        const selectedSchedule = orderSchedules?.schedules?.find((schedule: any) => +schedule.id === scheduleId)
        let jobAddress = selectedSchedule?.jobAddress
        if (selectedSchedule) {
            setSelectedSchedule(selectedSchedule)
        } else setSelectedSchedule(undefined)
        if (!jobAddress) {
            setAddressOptions((state) => [...state.map(state => ({ ...state, isDisabled: false }))])
            return
        }

        jobAddress = addressOptions.find((addrOpt: any) => addrOpt.label == formatAddressAny(jobAddress))

        if (jobAddress) {
            setSelectedAddress(jobAddress)
            setValue('address', jobAddress.value)
            setAddressOptions((state) => [...state.map(state => ({ ...state, isDisabled: state.value != jobAddress.value }))])
        }

    }, [scheduleId])

    useEffect(() => {
        reset()

        if (rest.visible) {
            setValue('technicianTeam', viewedTeam && viewedTeam.team.id)
            setValue('startDate', viewedTeam && localToUTC(new Date(viewedTeam.date)))
            if (initialOrderId)
                setValue('order', initialOrderId)
        } else {
            reset()
            setOrderSchedules(undefined)
            setOrderDefaultValue(undefined)
            setScheduleDefaultValue(undefined)
        }
    }, [rest.visible])

    useEffect(() => {
        if (+scheduleId < 1 || !orderSchedules) {
            fieldArr.replace([])
            return
        }
        const selectedItems: MiscObj[] = orderSchedules.items
            .filter((item: any) => item.scheduleTasks.find((st: any) => st.schedule?.id == scheduleId))
            .map((item: any) => item)

        const replaceValue: string[] = []
        selectedItems.forEach((item: any) => {
            item.scheduleTasks.forEach((task: any) => {
                if (task.schedule.id == scheduleId) replaceValue.push(`${item.id}_${task.itemPeriod}`)
            })
        })
        fieldArr.replace(replaceValue)
    }, [scheduleId])

    useEffect(() => {
        if (!orderId) return
        setValue('period', '')
        baseApi.get(`/admin/service-schedules/get-order-schedule-by-order/${orderId}`)
            .then(async schedules => {
                setOrderSchedules(schedules.data)
                if (initialOrderId && !orderDefaultValue) {
                    const orders = await baseApi.get(`/admin/service-orders?search=${initialOrderId}`)
                    const selectedOrder = orders.data.list ? orders.data.list.find((order: any) => order.id == initialOrderId) : null
                    setOrderDefaultValue({ label: `${selectedOrder ? `${selectedOrder.user.name} - ` : ''}OID ${initialOrderId}`, value: initialOrderId })
                }
                if (initialScheduleId && !scheduleDefaultValue) {
                    setScheduleDefaultValue({
                        label: `Job ID ${initialScheduleId}`,
                        value: +initialScheduleId
                    })
                }
                if (schedules.data) {
                    const formattedOrderAvailableAddresses = Array.from(new Set(schedules.data.availableAddresses.map((addr: any) => formatAddressAny(addr))))
                    setAddressOptions(formattedOrderAvailableAddresses.map((addr: any) => ({ value: nanoid(), label: addr })))
                }
            }).catch(err => {
                console.log(err)
                setOrderSchedules(undefined)
            })

    }, [orderId])

    const getOrderOptions = async (search: string): Promise<any[]> => {
        const orders = await baseApi.get(`/admin/service-orders?search=${search}&status=ongoing`)
        return orders.data.list.map((order: any) => ({ value: order.id, label: `${order.user.name} - OID${order.id}` }))
    }

    const addSchedule = handleSubmit(async (data: any) => {
        if (data.items.length == 0) {
            toast.error('Please select an item !')
            return
        }
        try {
            if (data.period < 0) {
                if (await postAddSchedule({
                    ...data,
                    items: Array.from(new Set(data.items.map((item: string) => +item.split('_')[0]))),
                    period: (data.period * -1) - 1,
                    user: parseInt(orderSchedules.user.id),
                    order: parseInt(data.order),
                })) toast.success('Schedule successfully created')
            } else {
                const schedules = flatten(orderSchedules.items.map((item: any) => item.scheduleTasks.map((st: any) => st.schedule))) || []
                const schedule: any = schedules ? schedules.find((schedule: any) => schedule.id == data.period) : undefined
                if (!schedule) {
                    toast.error('Schedule not found')
                    return
                }
                if (await postReschedule({
                    ...data,
                    items: Array.from(new Set(data.items.map((item: string) => +item.split('_')[0]))),
                    period: parseInt(schedule?.period),
                    schedule: parseInt(data.period),
                    user: parseInt(orderSchedules.user.id),
                    order: parseInt(data.order),
                })) toast.success('Reschedule success')
            }
        } catch (err: any) {
            toast.error(err.message)
            console.log(err)
        } finally {
            refetchDailySched()
            rest.close()
        }
    })

    return <Modal heading={'Add Schedule'} {...rest} width="30rem">
        {viewedTeam ?
            <form onSubmit={addSchedule} className="space-y-4" id='add-schedule-form'>
                <p>
                    Place a Job on <span className="font-medium">{viewedTeam?.team.name}</span> for <span className="font-medium">{formatFullDate(localToUTC(new Date(viewedTeam.date!)))}</span>
                </p>
                {(orderDefaultValue || !initialOrderId) &&
                    <AsyncSelectField
                        control={control}
                        fieldName="order"
                        loadOptions={getOrderOptions}
                        label={'Order'}
                        required
                        defaultCfg={{
                            setValue: setValue,
                            value: orderDefaultValue ? { label: orderDefaultValue.label, value: orderDefaultValue.value } : null
                        }}
                    />
                }
                {orderSchedules &&
                    <SelectField
                        control={control}
                        fieldName="address"
                        placeholder="Select the address"
                        label={'Customer Address'}
                        defaultCfg={selectedAddress && { setValue, value: selectedAddress.id }}
                        options={addressOptions}
                    />
                }

                {(scheduleDefaultValue || !initialScheduleId) && scheduleOptions && scheduleOptions.length > 0 &&
                    <SelectField
                        disabled={!orderSchedules}
                        label={'Job'}
                        control={control}
                        fieldName='period'
                        options={scheduleOptions}
                        required
                        defaultCfg={{
                            setValue: setValue,
                            value: scheduleDefaultValue ? { label: scheduleDefaultValue.label, value: scheduleDefaultValue.value } : null
                        }}
                    />
                }

                {
                    orderSchedules && selectedAddress && orderSchedules.items.map((item: MiscObj, index: number) => {
                        const maxPeriod = item.scheduleTasks.length
                        return <>
                            {Array(item.frequency).fill(0).map((_, index) => {
                                const scheduleTask = item.scheduleTasks[index]
                                const itemAddress = formatAddressAny(item.addresses.find((addr: any) => addr.period == index) || orderSchedules)
                                if (itemAddress != selectedAddress.label) return null
                                if (!selectedSchedule && index != maxPeriod) return null
                                if (selectedSchedule && !scheduleTask && index != maxPeriod) return null
                                if (scheduleTask && selectedSchedule && scheduleTask.schedule.id != selectedSchedule.id) return null
                                // if (scheduleTask &&)
                                return <div className="justify-between flex-ct-y" key={index}>
                                    <Checkbox
                                        key={index}
                                        fieldArr={fieldArr}
                                        control={control}
                                        fieldName="items"
                                        cbVal={`${+item.id}_${index}`}
                                        disabled={scheduleTask?.schedule?.completionDate != null}
                                        label={`${numOrderSuffix(index + 1)} ${item.service?.name}${item.serviceCategory ? ` (${item.serviceCategory.name})` : ''}`}
                                    />
                                    <div className="flex items-center gap-2">
                                        <span className="font-medium text-th-xs text-disable min-w-[100px] text-end">
                                            {item.quantity || 0} unit(s)
                                        </span>
                                        {scheduleTask?.schedule?.completionDate ?
                                            <Pill padding="2px 8px" className="min-w-[80px] justify-center" fontSize={'10px'} backgroundColor="#00A19A" textColor="white">
                                                Done
                                            </Pill> :
                                            scheduleTask ?
                                                <Pill padding="2px 8px" className="min-w-[80px] justify-center" fontSize={'10px'} backgroundColor="#1954A1" textColor="white">
                                                    On Schedule
                                                </Pill> :
                                                <Pill padding="2px 8px" className="min-w-[80px] justify-center" fontSize={'10px'} backgroundColor="#BB032A" textColor="white">
                                                    Not Done
                                                </Pill>
                                        }
                                    </div>

                                </div>
                            })}
                        </>
                    })
                }
                <Button confirm shape="filled" disabled={!orderId || !scheduleId} type='button' form={'add-schedule-form'} className="w-full mt-8">Submit</Button>
            </form> : <></>
        }

    </Modal>
}

export default AddScheduleModal