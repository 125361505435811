import { CrudFilters } from "@refinedev/core";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export default function useTableQuery(filters: CrudFilters, setFilters: any): UseTableQueryReturn {
    const { control, register, getValues, setValue, watch } = useForm()
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const regQuery = useCallback((query: string) => {
        return register(query)
    }, [register])

    const doFilter = useCallback(() => {
        const fields = getValues()
        let newFilters = []
        for (const field in fields) {
            const value = fields[field]
            if (value) {
                // When the value is array
                // if(Array.isArray(value)){
                //     value.forEach(val => {
                //         newFilters.push({
                //             field: field, operator: 'eq', 
                //             value: val
                //         })                        
                //     })
                // }
                // else{
                newFilters.push({
                    field: field, operator: 'eq',
                    value: value
                })
                // }
            }
            setValue(field, value)
        }
        setFilters(newFilters, 'replace')
    }, [setFilters, getValues])

    // Update the query fields on the first load
    useEffect(() => {
        if (isFirstLoad) {
            filters.forEach((filter: MiscObj) => {
                setValue(filter.field, filter.value)
            })
            setIsFirstLoad(false)
        }
    }, [filters, isFirstLoad])
    return { regQuery, doFilter, control }
}

interface UseTableQueryReturn {
    regQuery: (query: string) => MiscObj,
    doFilter: () => void,
    control: FormFieldType['control']
}