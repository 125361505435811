import { useLogout, useModal } from "@refinedev/core"
import { getPriorityBookings } from "api/priority"
import { getRescheduleRequests } from "api/reschedule-requests"
import { BaseCard, KanbanCard } from "components/cards"
import { DashboardHeader } from "components/headers"
import PageTitle from "components/PageTitle"
import RequestModal, { RequestObject } from "components/custom-modals/RequestModal"
import { NoticeIcon } from "components/Svg"
import { addHours, isBefore } from "date-fns"
import { useEffect, useMemo, useState } from "react"
import useSWR from "swr"
import { getLocalStorage } from "utils/storageHelper"
import { getItemRequests } from "api/item-requests"
import { KanbanDataProps } from "components/cards/KanbanCard"
import { getAllInvoiceHistory, getAllInvoices } from "api/invoice"
import { useNavigate } from "react-router-dom"
import { getIncompleteTasks, getLateTechSchedules } from "api/service-schedules"
import { changeTz } from "utils/data-formatter"


export default function Dashboard() {
    const { mutate: logout } = useLogout()
    // const [filter, setFilter] = useState<'pending' | 'accepted' | 'rejected' | undefined>()
    const { data: priorityBookings } = useSWR({ name: 'priority-bookings' }, () => getPriorityBookings())
    const { data: rescheduleRequests } = useSWR({ name: 'reschedule-requests' }, () => getRescheduleRequests())
    const { data: techLate } = useSWR({ name: 'tech-late' }, () => getLateTechSchedules())
    const { data: itemRequests } = useSWR({ name: 'item-requests' }, () => getItemRequests())
    const { data: incompleteTasks } = useSWR({ name: 'incomplete-tasks' }, () => getIncompleteTasks())
    const { data: invoices = [] } = useSWR({ item: 'invoices' }, () => getAllInvoices(true))
    const { data: technicianCarryCash = [] } = useSWR({ name: 'invoice-history', method: 'onsite', claimed: false, manual: true }, () => getAllInvoiceHistory('onsite', true, false))
    const navigate = useNavigate()
    const requestModal = useModal()
    const [viewedObject, setViewedObject] = useState<RequestObject>()

    const formattedHighPriority = useMemo(() => {
        let result: (KanbanDataProps & RequestObject)[] = []
        let total = 0
        if (priorityBookings) {
            const pbFormatted: KanbanDataProps[] = priorityBookings.list.map((request: any): KanbanDataProps & RequestObject => {
                total += 1
                return {
                    id: request.id,
                    user: request.user,
                    date: new Date(request.pbScheduleInvoices[(request.pbScheduleInvoices.length - 1)].updatedAt),
                    orderId: request.order.id,
                    scheduleId: request.id,
                    subTitle: '',
                    timerMode: 'decrement',
                    topic: 'priority',
                    submitId: request.id,
                    showAction: true
                }
            })
            result = [...result, ...pbFormatted]
        }
        if (rescheduleRequests) {
            const rescheduleFormatted: KanbanDataProps[] = rescheduleRequests.list.map((request: any): KanbanDataProps & RequestObject => {
                total += 1
                return {
                    id: request.id,
                    showAction: true,
                    user: request.user,
                    team: request.technicianTeam,
                    techName: request.reschedulingTech && request.reschedulingTech.name,
                    date: new Date(request.updatedAt),
                    orderId: request.order.id,
                    scheduleId: request.id,
                    subTitle: request.rescheduleReason,
                    timerMode: "increment",
                    jobTypes: Array.from(new Set(request.tasks?.map((task: any) => JSON.stringify(({ name: task.item.service.jobType.name, colorHex: task.item.service.jobType.colorHex }))))),
                    topic: 'reschedule',
                    submitId: request.id,
                    reason: request.rescheduleReason,
                    requestBy: request.assignedTeam && request.assignedTeam.assignedTechnicians.map((at: any) => `${at.technician.name} ${at.role == 1 ? '(Leader)' : ''}`).join(', ')
                }
            })
            result = [...result, ...rescheduleFormatted]
        }

        if (itemRequests) {
            const itemsRequestFormatted: KanbanDataProps[] = itemRequests.filter((request: any) => request.scheduleTasks[0] != undefined).map((request: any): KanbanDataProps & RequestObject => {
                total += 1
                return {
                    id: request.id,
                    showAction: true,
                    user: request.order.user,
                    team: request.scheduleTasks[0].schedule && request.scheduleTasks[0].schedule.technicianTeam,
                    techName: request.addingTech && request.addingTech.name,
                    date: new Date(request.updatedAt),
                    orderId: request.order.id,
                    scheduleId: request.scheduleTasks[0].schedule && request.scheduleTasks[0].schedule.id,
                    subTitle: `Additional ${request.quantity} unit ${request.service.name} (${request.serviceCategory.name}) request`,
                    timerMode: 'increment',
                    jobTypes: [JSON.stringify({ name: request.service.jobType.name, colorHex: request.service.jobType.colorHex })],
                    topic: 'item-request',
                    submitId: request.id,
                    requestBy: request.assignedTeam && request.assignedTeam.assignedTechnicians.map((at: any) => `${at.technician.name} ${at.role == 1 ? '(Leader)' : ''}`).join(', ')
                }
            })
            result = [...result, ...itemsRequestFormatted]
        }

        if (techLate) {
            const techLateFormatted: KanbanDataProps[] = techLate.map((request: any) => {
                const technicians = request.assignedTeam && request.assignedTeam.assignedTechnicians.map((at: any) => `${at.technician.name} ${at.role == 1 ? '(Leader)' : ''}`).join(', ')

                total += 1
                return {
                    id: request.id,
                    user: request.user,
                    team: request.technicianTeam,
                    date: isBefore(new Date(), addHours(request.startDate, 1)) ? new Date(request.startDate) : addHours(request.startDate, 1),
                    orderId: request.order.id,
                    scheduleId: request.id,
                    subTitle: `Job is scheduled for ${changeTz(request.startDate, 'Asia/Singapore', 'HH:mm')}.\n ${technicians ? `Technicians are ${technicians}` : 'There are no technicians assigned.'}`,
                    timerMode: isBefore(new Date(), addHours(request.startDate, 1)) ? 'decrement' : 'increment',
                    topic: 'tech-late',
                    jobTypes: Array.from(new Set(request.tasks?.map((task: any) => JSON.stringify(({ name: task.item.service.jobType.name, colorHex: task.item.service.jobType.colorHex }))))),
                    showAction: false,
                    requestBy: technicians
                }
            })
            result = [...result, ...techLateFormatted]
        }
        return { result: result.sort((a, b) => isBefore(a.date, b.date) ? 1 : -1), total }
    }, [priorityBookings, rescheduleRequests, itemRequests, techLate])

    const formattedLowPrioritry = useMemo(() => {
        let result: KanbanDataProps[] = []
        let total = 0

        if (technicianCarryCash && technicianCarryCash.list) {
            technicianCarryCash.list.map((req: any) => {
                result.push({
                    id: "",
                    user: { name: req.createdBy, phone: req.createdBy.phoneNumber },
                    team: undefined,
                    date: req.createdAt,
                    orderId: req.invoice && req.invoice.order && req.invoice.order.id,
                    scheduleId: "",
                    subTitle: `$ ${req.amount.toFixed(2)}`,
                    jobTypes: Array.from(new Set(req.invoice.items.map((item: any) => JSON.stringify(({ name: item.service.jobType.name, colorHex: item.service.jobType.colorHex }))))),
                    topic: "technician-carry-cash",
                    submitId: "",
                    onClick: () => navigate(`/service-orders/${req.invoice.order.id}?scroll=invoice-${req.invoice.id}`)
                })
                total += 1
            })
        }

        if (incompleteTasks) {
            const incompleteTaskFormatted: KanbanDataProps[] = incompleteTasks.map((request: any) => {
                total += 1
                return {
                    id: request.id,
                    user: request.schedule?.user,
                    team: {
                        name: request.schedule.finishingTech?.name,
                        profilePhoto: request.schedule.finishingTech?.profilePicture
                    },
                    date: request.createdAt,
                    orderId: request.schedule.order?.id,
                    scheduleId: request.schedule.id,
                    subTitle: `${request.incompleteReason} \n\n ${request.item.quantity} ${request.item.service.serviceUnit.name}(s) ${request.item.service.name} (${request.item.serviceCategory.name})`,
                    topic: 'incomplete-task',
                    jobTypes: [JSON.stringify(({ name: request.item.service.jobType.name, colorHex: request.item.service.jobType.colorHex }))],
                    showAction: false,
                    onClick: () => navigate(`/service-orders/${request.schedule.order?.id}?scroll=incomplete-task-${request.id}`)
                }
            })
            result = [...incompleteTaskFormatted, ...result]
        }

        return { result, total }
    }, [technicianCarryCash])

    const handleOpenRequest = (reqObject: RequestObject) => {
        setViewedObject(reqObject)
        requestModal.show()
    }

    useEffect(() => {
        const user = getLocalStorage('user')
        if (!user) { logout() }
    }, [])

    return <>
        <PageTitle title='Dashboard' />
        <DashboardHeader />
        <BaseCard className="mt-4">
            <div className="mt-4 grid grid-cols-3 gap-4 bg-transparent">
                <section className="p-2 bg-[#FFF4F2] rounded-lg">
                    <div className="flex-ct-y gap-2 font-medium">
                        <div className="flex-ct-y gap-1.5 rounded-full py-1.5 px-4 border bg-[#FF6666] text-white">
                            <NoticeIcon className="fill-white" />
                            High Priority
                        </div>
                        <span className="text-danger">{formattedHighPriority.total}</span>
                    </div>
                    <div className='space-y-2 mt-2'>
                        {formattedHighPriority.result.map(request =>
                            <KanbanCard
                                {...request}
                                key={request.id}
                                accentColor={"#BB032A"}
                                className="hover:border-[#BB032A] hover:bg-danger-light/30"
                                onClick={() => handleOpenRequest({
                                    scheduleId: request.scheduleId,
                                    submitId: request.submitId,
                                    orderId: request.orderId,
                                    topic: request.topic,
                                    displayDate: request.date,
                                    showAction: request.showAction,
                                    reason: request.reason,
                                    requestBy: request.requestBy
                                })}
                            />
                        )}
                    </div>
                </section>
                <section className="p-2 bg-[#FC880033] rounded-lg">
                    <div className="flex-ct-y gap-2 font-medium">
                        <div className="flex-ct-y gap-1.5 rounded-full py-1.5 px-4 bg-[#FC8800] text-white">
                            <NoticeIcon className="fill-white" />
                            Medium Priority
                        </div>
                        <span className="text-[#FC8800]">{invoices && invoices.total ? invoices.total : 0}</span>
                    </div>
                    <div className='space-y-2 mt-2'>
                        {invoices && invoices.list && invoices.list.map((invoice: any) => {
                            let reason = '';
                            if (invoice.customerDoesntWantToPay) {
                                reason = ". Customer doesn't want to pay."
                            } else if (invoice.customerWantsToPayLater) {
                                reason = ". Customer wants to pay later."
                            }
                            return <KanbanCard
                                id={invoice.id}
                                user={invoice.user}
                                team={{}}
                                date={new Date(invoice.createdAt)}
                                orderId={invoice.order.id}
                                scheduleId={""}
                                subTitle={`Amount Due $ ${(invoice.amountDue || invoice.amount || 0).toFixed(2)}${reason}`}
                                topic={"pending-payment"}
                                submitId={""}
                                jobTypes={Array.from(new Set(invoice.items.map((item: any) => JSON.stringify(({ name: item.service.jobType.name, colorHex: item.service.jobType.colorHex })))))}
                                accentColor={"#FC8800"}
                                className="hover:border-[#FC8800] hover:bg-[#FC8800]/10"
                                isCompleted={invoice.customerDoesntWantToPay || invoice.customerWantsToPayLater || invoice.order.completedAt != null}
                                onClick={() => navigate(`/service-orders/${invoice.order.id}?scroll=invoice-${invoice.id}`)}
                            />
                        })}
                    </div>
                </section>
                <section className="p-2 bg-warning-light/30 rounded-lg">
                    <div className="flex-ct-y gap-2 font-medium">
                        <div className="flex-ct-y gap-1.5 bg-[#EECEB0] rounded-full py-1.5 px-4 text-black">
                            <NoticeIcon className="fill-white" />
                            Low Priority
                        </div>
                        <span className="text-[#BF6919]">{formattedLowPrioritry.total}</span>
                    </div>
                    <div className='space-y-2 mt-2'>
                        {formattedLowPrioritry.result.map(request =>
                            <KanbanCard
                                accentColor={"#247881"}
                                className="hover:border-[#247881] hover:bg-[#247881]/10"
                                {...request}
                            />
                        )}
                    </div>
                </section>
            </div>
            <RequestModal visible={requestModal.visible} close={requestModal.close} viewedObject={viewedObject} />
        </BaseCard>
    </>
}