import { ReactNode } from "react"
import { Link } from "react-router-dom"

type Props = {
    children: ReactNode
}

const AuthLayout = (props: Props) => {
    return <div className="bg-[#ECF0F8] bg-left-bottom bg-contain bg-no-repeat bg-login">
        <div className="min-h-screen min-w-screen bg-[#ECF0F8] bg-opacity-90 grid grid-cols-5 items-stretch ">
            <div className="col-span-2 grid place-items-center ">
                <Link to={'/'} className="w-1/3 min-w-[250px]">
                    <img src="/images/airple_logo.png" />
                </Link>
            </div>
            <div className="self-stretch bg-white shadow-lg rounded-l-[32px] col-span-3 px-8 grid place-items-center">
                <div className="max-w-[550px] grid items-stretch h-full w-full">
                    {props.children}
                </div>
            </div>
        </div>
    </div>
}

export default AuthLayout